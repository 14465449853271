import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { AuthContext } from '../../../providers/authContext';

const LogOutmodal = ({ isOpen, onClose }) => {
  const authContext = useContext(AuthContext);

  const initialFocusRef = React.useRef();
  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Seguro desea cerrar sesión ?</ModalHeader>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            ref={initialFocusRef}
            colorScheme="red"
            mr={3}
            onClick={authContext.logout}>
            Cerrar sesion
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LogOutmodal;
