import { colors } from '../../utils/colors';

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

export const FloatinLabelInputVariant = {
  components: {
    Input: {
      sizes: {
        md: { field: { padding: 6 } },
      },
      variants: {
        outline: {
          field: {
            borderColor: '#c1bfb9',
            borderRadius: 'lg',
            _focusVisible: {
              borderColor: colors.accent.main,
              boxShadow: `none`,
            },
          },
        },
      },
    },
    Select: {
      sizes: {
        md: { field: { height: '49px' } },
      },
      variants: {
        outline: {
          field: {
            borderColor: '#c1bfb9',
            borderRadius: 'lg',
            _focusVisible: {
              borderColor: colors.accent.main,
              boxShadow: `none`,
            },
          },
        },
      },
    },
    NumberInput: {
      sizes: {
        md: { field: { padding: 6 } },
      },
      variants: {
        outline: {
          field: {
            borderColor: '#c1bfb9',
            borderRadius: 'lg',
            _focusVisible: {
              borderColor: colors.accent.main,
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Textarea: {
      sizes: {
        md: { field: { padding: 6 } },
      },
      variants: {
        outline: {
          borderColor: '#c1bfb9',
          borderRadius: 'lg',
          _focusVisible: {
            borderColor: colors.accent.main,
            boxShadow: 'none',
          },
        },
      },
    },
    Form: {
      variants: {
        floating: props => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-react-select--has-value + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: '#313149',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 3,
              transformOrigin: 'left top',
            },
          },
        }),
        floatingGray: props => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-react-select--has-value + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: props.colorScheme
                ? props.colorScheme + '.main'
                : colors.black.main,
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        }),
      },
    },
  },
};
