import React from 'react';
import {
  Box,
  Flex,
  chakra,
  HStack,
  Text,
  Icon,
  Center,
  Stack,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { POI } from '../../services/bikletaPoints/dto';
import { BikletaIcon } from '../Icons/Icons';

interface CommerceListCardProps {
  itemData: POI;
  url: string | null;
  setActiveItem: () => void;
  isDesktop: boolean;
}

const CommerceListCard: React.FC<CommerceListCardProps> = ({
  itemData,
  url,
  setActiveItem,
  isDesktop,
}) => {
  return (
    <Flex
      my="auto"
      textAlign={'start'}
      w="full"
      rounded={{ sm: 'lg', md: 'none' }}
      borderTopWidth={{ sm: 0, md: 0.7 }}
      borderBottomWidth={{ sm: 0, md: 0.7 }}
      shadow={{ sm: 'lg', md: 'none' }}
      borderColor={'gray.300'}
      bg="white"
      mx={{ sm: '1%', md: 0 }}
      _hover={{
        cursor: 'pointer',
      }}
      onClick={setActiveItem}
      overflow="hidden"
      fontSize={'sm'}
      sx={{
        '-webkit-touch-callout': 'none' /* iOS Safari */,
        '-webkit-user-select': 'none' /* Safari */,
        '-khtml-user-select': 'none' /* Konqueror HTML */,
        '-moz-user-select': 'none' /* Old versions of Firefox */,
        '-ms-user-select': 'none' /* Internet Explorer/Edge */,
        'user-select': 'none' /* Non-prefixed version, currently
                                 supported by Chrome, Edge, Opera and Firefox */,
      }}>
      <Center
        w={1 / 3}
        bgRepeat={'no-repeat'}
        bgPos="center"
        bgColor={'#313149'}>
        {itemData?.images.length > 0 ? (
          <Stack
            direction={'column'}
            w="100%"
            h="100%"
            bgImage={itemData.images[0]}
            bgRepeat={'no-repeat'}
            bgPosition={{ sm: '55%' }}
            bgSize="cover"
            px={3}
            spacing={4}></Stack>
        ) : (
          <Icon
            as={BikletaIcon}
            h="70%"
            mt="auto"
            ml="15%"
            w="60%"
            color={'white'}
            alignSelf={'center'}
          />
        )}
      </Center>

      <Box
        w={2 / 3}
        p={{
          base: 4,
          md: 4,
        }}>
        <chakra.h1
          fontSize="lg"
          color="gray.800"
          _dark={{
            color: 'white',
          }}
          noOfLines={1}>
          {itemData.name}
        </chakra.h1>

        <chakra.p fontSize="smaller" color="gray.600" noOfLines={1} isTruncated>
          {itemData.type}
        </chakra.p>

        <HStack spacing={1} display="flex" alignItems="center" mt={1.5} hidden>
          <StarIcon
            color="gray.700"
            _dark={{
              color: 'gray.300',
            }}
          />
          <StarIcon
            color="gray.700"
            _dark={{
              color: 'gray.300',
            }}
          />
          <StarIcon
            color="gray.700"
            _dark={{
              color: 'gray.300',
            }}
          />
          <StarIcon color="gray.500" />
          <StarIcon color="gray.500" />
        </HStack>

        <Flex alignItems="center" mt={1} justifyContent="space-between">
          <Text fontSize="small" alignItems={'center'} size={'sm'}>
            {itemData.address.addressLine}
          </Text>
          <chakra.button
            px={2}
            py={1}
            bg="white"
            fontSize="xs"
            color="gray.900"
            rounded="lg"
            textTransform="uppercase"
            _hover={{
              bg: 'yellow.100',
            }}
            _focus={{
              bg: 'yellow.400',
            }}
            display={{ sm: 'none', md: 'block' }}>
            ver más
          </chakra.button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default CommerceListCard;
