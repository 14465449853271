import { useState, useEffect, useCallback } from 'react';

// fuente: https://codesandbox.io/s/github/ishan-marikar/use-marker-smooth-move?file=/src/App.js:1641-1793

function useInterval(callback, delay) {
  const savedCallback = useCallback(() => callback(), [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function useMarkerMove(initialValue, { delta = 100, delay = 20 } = {}) {
  let [currentPosition, setCurrentPosition] = useState(initialValue);
  let [currentDeltaPosition, setCurrentDeltaPosition] = useState([0, 0]);
  let [currentDelta, setCurrentDelta] = useState(null);

  useInterval(
    () => {
      setCurrentDelta(++currentDelta);
      setCurrentPosition([
        currentPosition[0] + currentDeltaPosition[0],
        currentPosition[1] + currentDeltaPosition[1],
      ]);
    },
    currentDelta !== null && currentDelta !== delta ? delay : null
  );

  function setPosition(coordinates) {
    //console.log('coordinates', coordinates);
    const [latitude, longitude] = coordinates;
    setCurrentDelta(0);
    setCurrentDeltaPosition([
      (latitude - currentPosition[0]) / delta,
      (longitude - currentPosition[1]) / delta,
    ]);
  }

  return [currentPosition, setPosition];
}

export default useMarkerMove;
