import { Box, Center, Text, useStyleConfig } from '@chakra-ui/react';
import { HTMLChakraProps } from '@chakra-ui/system';
interface CardProps extends HTMLChakraProps<'div'> {
  variant?: any;
  style?: any;
  width?: any;
  height?: any;
  isDisabled?: boolean;
}

const Card: React.FC<CardProps> = ({
  variant,
  children,
  style,
  width,
  height,
  isDisabled = false,
  ...rest
}) => {
  const styles: any = useStyleConfig('CardCustom', { variant });

  // Pass the computed styles into the `__css` prop
  return (
    <Box
      __css={styles}
      style={style}
      {...rest}
      width={width}
      height={height}
      transition="all 0.15s ease"
      pos="relative">
      {isDisabled && (
        <Center
          pos={'absolute'}
          h="100%"
          w="100%"
          bgColor={'transparent'}
          top={0}
          left={0}
          backdropFilter="blur(7px)"
          zIndex={999}>
          <Center
            bgColor={'backgroundColor.50'}
            m="auto"
            p={3}
            borderRadius={12}>
            <Text fontSize={'0.92rem'}> Aún no disponible</Text>
          </Center>
        </Center>
      )}
      {children}
    </Box>
  );
};

export default Card;
