import create from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { BenefitHistoryItem } from './benefit.dto';

export interface BenefitHistoryStore {
  benefitList: BenefitHistoryItem[];
  pushCoupon: (ticket: BenefitHistoryItem) => void;
  reset: () => void;
}

export const useBenefitHistoryStore = create<BenefitHistoryStore>()(
  persist(
    set => ({
      benefitList: [],
      pushCoupon: (benefit: BenefitHistoryItem) =>
        set(state => ({ benefitList: [benefit, ...state.benefitList] })),
      reset: () =>
        set({
          benefitList: [],
        }),
    }),
    { name: 'benefit-history', storage: createJSONStorage(() => localStorage) }
  )
);
