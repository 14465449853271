import React, { useEffect, useState, useRef } from 'react';
import { OverlayView } from '@react-google-maps/api';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  Grid,
  GridItem,
  Box,
  Button,
  Text,
  Flex,
  Image,
  useDisclosure,
  DrawerFooter,
  VStack,
  Stack,
  Tag,
  TagLabel,
  Avatar,
  Link,
  DrawerCloseButton,
  Tooltip,
} from '@chakra-ui/react';
import MapComponent from '../../extra/MapComponent';
import {
  getSharedPoint,
  getSharedRouteData,
} from '../../services/getSharedRoute';
import LoadingPage from '../../extra/Loading/LoadingScreen';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ErrorView from '../../extra/Error/ErrorView';
import useMoveMarker from '../../utils/useMoveMarker';
import { bikes } from '../../assets';
import { AiOutlineUser } from 'react-icons/ai';
import TimelineRow from '../../components/TimeLineRow/index';
import { FaCircle, FaMapMarkerAlt, FaRoute } from 'react-icons/fa';

interface SharedTripProps {
  drawerSize?: string;
}

function TravellingMarker({ position, userImageUrl, ...rest }) {
  let [coordinates, setDestination] = useMoveMarker([
    position.lat,
    position.lng,
    6000,
  ]);

  useEffect(() => {
    setDestination([position.lat, position.lng]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  return (
    <div style={{ transition: 'all ease .5s' }}>
      <OverlayView
        position={{
          lat: coordinates[0],
          lng: coordinates[1],
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}>
        <Avatar
          size="md"
          bgColor={'gray.200'}
          icon={<AiOutlineUser fontSize="1.7rem" />}
          src={userImageUrl}
          showBorder
        />
      </OverlayView>
    </div>
  );
}

const SharedTrip: React.FC<SharedTripProps> = ({ drawerSize }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id }: any = useParams();
  const [routeData, setRouteData] = useState(null);
  const [currentPos, setCurrentPos] = useState(null);
  const sectionRef = useRef(null);
  const [tripState, setTripState] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(true);

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        getSharedRouteData(id)
          .then(res => {
            setRouteData(res);
          })
          .catch(err => {
            console.log(err);
          });

        getSharedPoint(id)
          .then(res => setCurrentPos(res.data))
          .catch(err => console.log(err));
      }, 2000);

      setInterval(() => {
        setTooltipOpen(prev => !prev);
      }, 10000);

      onOpen();
    }
  }, [id]);

  useEffect(() => {
    if (routeData) {
      setTripState(routeData.status);
    }
  }, [routeData]);

  useEffect(() => {
    if (tripState !== null) {
      const liveDataInterval = setInterval(() => {
        getSharedPoint(id)
          .then(res => {
            setCurrentPos(res.data);
            setTripState(res.data.status);
            //console.log('Current state: ', res.data.status);
          })
          .catch(err => console.log(err));
      }, 10000);
      if (tripState !== 'progress') {
        clearInterval(liveDataInterval);
      }
    }
  }, [tripState]);

  if (!routeData) {
    return <LoadingPage />;
  }

  return Array.isArray(routeData) ? (
    <ErrorView
      errorTitle="Ups! El recorrido no existe"
      errorMessage="No se encontró ningún recorrido con el ID que buscas"
      returnToHome={true}
    />
  ) : (
    <Box as="section" ref={sectionRef}>
      <Drawer
        size={drawerSize || isMobile ? 'xs' : 'xs'}
        variant={'alwaysOpen'}
        placement={isMobile ? 'bottom' : 'left'}
        onClose={onClose}
        isOpen={isOpen}
        closeOnEsc
        closeOnOverlayClick={false}
        autoFocus={false}>
        <DrawerContent
          bgColor={'#FAF8F8'}
          shadow={'xl'}
          borderRadius={isMobile ? 16 : 35}
          maxW="29vw">
          <DrawerCloseButton hidden={isMobile} />
          <DrawerHeader
            bgColor={'whiteAlpha.200'}
            borderBottomWidth="0.5vh"
            borderBottomColor={'brand.50'}>
            <Flex
              alignItems="center"
              pt={!isMobile ? '10%' : 0}
              overflow="hidden">
              <Avatar
                mr={4}
                size="md"
                loading={'eager'}
                bgColor={'gray.200'}
                icon={<AiOutlineUser fontSize="1.7rem" />}
                src={routeData.user.profileImage}
              />
              <Stack spacing={-0.5}>
                <Stack
                  spacing={2}
                  direction="row"
                  style={{ justifyContent: 'space-between' }}>
                  <Text
                    color={'gray.700'}
                    fontWeight="600"
                    fontSize={16}
                    noOfLines={1}>
                    Recorrido de {routeData.user.firstname}
                  </Text>
                  <Tag
                    colorScheme={tripState !== 'stop' ? 'green' : 'red'}
                    borderRadius="full"
                    size={'sm'}>
                    <TagLabel fontWeight={'bold'} fontSize="smaller">
                      {tripState !== 'stop' ? 'Activo' : 'Finalizado'}
                    </TagLabel>
                  </Tag>
                </Stack>
                <Text color={'gray.500'} fontWeight="200" fontSize={12}>
                  @{routeData.user.alias}
                </Text>
                <Text
                  color={'gray.500'}
                  fontWeight="200"
                  fontSize={12}
                  hidden={tripState === 'stop'}>
                  {currentPos?.datetimeString &&
                    `Última actualización: ${currentPos.datetimeString}`}
                </Text>
              </Stack>
            </Flex>
          </DrawerHeader>
          <DrawerBody
            borderRadius={15}
            px={0}
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
                borderRadius: '10px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.1)`,
                borderRadius: '10px',
              },
            }}>
            <VStack spacing={isMobile ? 0 : 2} w="100%">
              <Box w={'100%'} px="6%">
                <VStack alignItems={'left'} pb="2%">
                  <Flex alignItems="center" hidden>
                    <Image
                      mr={4}
                      objectFit="contain"
                      borderRadius={50}
                      loading={'eager'}
                      bgColor={'gray.200'}
                      width={50}
                      height={50}
                      src={bikes[routeData.bike?.bikeType]?.image}
                    />
                    <Text color={'gray.700'} fontWeight="500">
                      Realizando en {routeData.bike.name ?? 'una Bikleta'}
                    </Text>
                  </Flex>
                </VStack>
                <Grid
                  templateColumns={{ sm: '1fr', md: '1fr', lg: '1fr' }}
                  ps="20px"
                  h="400px"
                  height={'20%'}>
                  <Flex direction="column" position="relative">
                    <TimelineRow
                      logo={FaCircle}
                      title={routeData.initPointString}
                      color={'brand.50'}
                      isLast={false}
                    />
                    <TimelineRow
                      logo={FaMapMarkerAlt}
                      title={
                        routeData?.endPoint
                          ? routeData.endPointString
                          : 'Sin destino programado'
                      }
                      color={'gray.400'}
                      isLast={true}
                      mb={5}
                    />
                    <TimelineRow
                      logo={FaRoute}
                      title={
                        ('Distancia: ' + currentPos?.meta &&
                          (currentPos.meta
                            ? currentPos.meta.m > 1000
                              ? currentPos.meta.km + ' kmts'
                              : currentPos.meta.m + ' mts'
                            : routeData.meta.m > 1000
                            ? routeData.meta.km + ' kmts'
                            : routeData.meta.m + ' mts')) ??
                        ''
                      }
                      color={'black'}
                      isLast={true}
                      boldLabel={true}
                    />
                  </Flex>
                </Grid>
              </Box>
            </VStack>
          </DrawerBody>
          <DrawerFooter
            hidden={isMobile}
            p={0}
            borderTopWidth={'0.5vh'}
            borderTopColor="brand.50">
            {isMobile ? null : (
              <Box
                style={{ position: 'relative', bottom: 0 }}
                bgColor="white"
                borderRadius={35}>
                <Grid
                  flexDirection={'row'}
                  w="100%"
                  px="3%"
                  templateColumns="repeat(2, 1fr)">
                  <GridItem alignSelf={'end'}>
                    <Image
                      alt="adApp"
                      w={'95%'}
                      h={'95%'}
                      src={require('../../assets/appPreviewComplete.webp')}
                    />
                  </GridItem>
                  <GridItem alignSelf={'center'}>
                    <VStack pt={'5%'}>
                      <Image
                        borderRadius="full"
                        boxSize="4vw"
                        src={require('../../assets/Logos/logo512.png')}
                        alt="App Logo"
                      />
                      <Text fontSize={12} textAlign="center" noOfLines={3}>
                        Compartí tu ubicación en tiempo real con tus amigos,
                        familiares y la comunidad Bikleta
                      </Text>
                      <Link
                        color="blue.500"
                        fontSize={11}
                        textAlign="center"
                        href={
                          'https://play.google.com/store/apps/details?id=com.bikletaapp'
                        }
                        isExternal
                        noOfLines={1}>
                        DESCARGAR LA APLICACIÓN
                      </Link>
                    </VStack>
                  </GridItem>
                </Grid>
              </Box>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <MapComponent
        height={isOpen && isMobile ? '75vh' : '100vh'}
        center={
          !(routeData.initPoint && routeData.endPoint) ? currentPos : null
        }
        zoom={15}
        style={{
          marginLeft: isOpen && !isMobile ? '27vw' : '0%',
          transition: 'margin-left 250ms ease-in-out',
        }}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: !isMobile,
          gestureHandling: 'greedy',
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.business',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
        places={
          routeData.initPoint &&
          routeData.endPoint && [
            {
              latitude: routeData.initPoint.lat,
              longitude: routeData.initPoint.lng,
            },
            {
              latitude: routeData.endPoint.lat,
              longitude: routeData.endPoint.lng,
            },
          ]
        }>
        <Box height="100%">
          {isMobile ? (
            <Tooltip
              label="Descargar app"
              placement="right"
              hasArrow
              isOpen={tooltipOpen}>
              <Image
                borderRadius="full"
                shadow={'xl'}
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.bikletaapp'
                  )
                }
                style={{ position: 'fixed', top: '1.5%', left: '3%' }}
                boxSize="6.5vh"
                src={require('../../assets/Logos/logo512.png')}
                alt="App Logo"
              />
            </Tooltip>
          ) : (
            <Button
              onClick={onOpen}
              ml={{ md: '2%' }}
              mt={{ sm: '10vh', md: 8 }}
              colorScheme="yellow"
              bgColor="brand.50"
              display={isOpen ? 'none' : { sm: 'none', md: 'block' }}>
              Ver detalles del recorrido
            </Button>
          )}
        </Box>
        {currentPos && (
          <TravellingMarker
            position={currentPos}
            userImageUrl={routeData.user.profileImage}
          />
        )}
      </MapComponent>
    </Box>
  );
};

export default SharedTrip;
