import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { BsSearch } from 'react-icons/bs';
import { RiAddFill } from 'react-icons/ri';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';

export interface TableActionProps {
  filter: any;
  setFilter: (value?: any) => void;
  openAdd?: () => void | null;
  openEdit?: Function | null;
  tableInstance: TableInstance;
  deleteAction?: {
    warningLabel: string;
    method: Function;
    isDeleting: boolean;
  };
}

export const TableActions: React.FC<TableActionProps> = ({
  filter,
  setFilter,
  openAdd,
  openEdit,
  tableInstance,
  deleteAction,
  ...props
}) => {
  const [value, setValue] = useState(filter);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined);
  }, 500);

  return (
    <>
      <Stack
        spacing="4"
        direction={{
          base: 'column',
          md: 'row',
        }}
        justify={openAdd || openEdit !== null ? 'space-between' : 'flex-end'}>
        <ButtonGroup size="sm" variant="outline">
          {openAdd && (
            <Button
              iconSpacing="0.5"
              leftIcon={<RiAddFill fontSize="1.25em" />}
              borderColor="brand.50"
              onClick={openAdd}>
              Añadir
            </Button>
          )}
          {openEdit && (
            <IconButton
              color="white"
              aria-label="Edit"
              icon={<MdModeEdit />}
              onClick={() => openEdit(tableInstance.selectedFlatRows[0].values)}
              disabled={tableInstance.selectedFlatRows.length !== 1}
            />
          )}
          {deleteAction && (
            <IconButton
              color={'white'}
              aria-label="Delete"
              icon={<FaTrash />}
              disabled={!(tableInstance.selectedFlatRows.length > 0)}
              onClick={onOpen}
            />
          )}
        </ButtonGroup>
        <HStack>
          <FormControl
            minW={{
              md: '300px',
            }}
            id="search">
            <InputGroup size="sm">
              <FormLabel srOnly>Buscar...</FormLabel>
              <InputLeftElement pointerEvents="none" color="gray.400">
                <BsSearch />
              </InputLeftElement>
              <Input
                rounded="base"
                type="search"
                placeholder="Buscar..."
                value={value || ''}
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </InputGroup>
          </FormControl>
        </HStack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Advertencia</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Seguro que desea eliminar. Esta acción es irreversible.</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose} variant="ghost">
              Cancelar
            </Button>
            <Button
              colorScheme={'red'}
              onClick={async () => {
                await deleteAction.method(tableInstance.selectedFlatRows)
                onClose();
              }}>
              Eliminar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
