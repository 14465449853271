export const bikes = {
  bmx: {
    image: require('./bike-types/bmx.png'),
    label: 'BMX',
  },
  ciclocross: {
    image: require('./bike-types/ciclocross.png'),
    label: 'Ciclocross',
  },
  mountain: {
    image: require('./bike-types/ciclomontañismo.png'),
    label: 'Mountain Bike',
  },
  child: {
    image: require('./bike-types/infantil.png'),
    label: 'Infantil',
  },
  cruiser: {
    image: require('./bike-types/playera.png'),
    label: 'Playera',
  },
  route: {
    image: require('./bike-types/ruta.png'),
    label: 'Ruta',
  },
  urban: {
    image: require('./bike-types/urbana.png'),
    label: 'Urbana',
  },
  electricUrban: {
    image: require('./bike-types/electricUrban.png'),
    label: 'Urbana (Eléctrica)',
  },
  electricRoute: {
    image: require('./bike-types/electricRoute.png'),
    label: 'Ruta (Eléctrica)',
  },
  electricMountain: {
    image: require('./bike-types/electricMountainBike.png'),
    label: 'Mountain Bike (Eléctrica)',
  },
  electricScooter: {
    image: require('./bike-types/electricScooter.png'),
    label: 'Monopatín Eléctrico',
  },
  foldable: {
    image: require('./bike-types/foldable.png'),
    label: 'Plegable',
  },
};

export const BikeValues = require('./values/yellow-bikes.webp');

export const WhoWeAreMan = require('./values/businessman-whoWeAre.webp');

export const getItOn = {
  googlePlay: require('./app-stores/get-it-on-google-play.webp'),
  appleStore: require('./app-stores/get-it-on-apple-store.webp'),
};

export const OnlyBikes = require('./Logos/only-bike.png');

export const BikletaIcon = require('./Logos/logo512.png');

export const LogoBikleta = require('./Logos/Logo-Bikleta-black.png');

export const BikletaLogo = require('./Logos/Bikleta-Icon.png');

export const IgIcon = require('./Logos/IgIcon.png');

export const EcoIcon = require('./business/ecoIcon.png');

export const SuitcaseIcon = require('./business/suitcaseBusiness.png');

export const slidesImages = [
  require('./slides/slide1.webp'),
  require('./slides/slide2.webp'),
  require('./slides/slide3.webp'),
];

export const PointMarkers = {
  POI: require('./bikleta-points/markers/BikletaPointDefault.png'),
  StolenBike: require('./map-markers/stolenBikes/stolenBike.png'),
};

export const ClusterMarkers = {
  POI: require('./map-markers/pointsCluster.svg'),
  Stolenbikes: require('./map-markers/stolenCluster.svg'),
};

export const LogoAndText = require('./Logos/logoAndText.png');

export const OxigenImage = require('./stats/oxigenIcon.png');
export const co2Reduce = require('./stats/co2-reduce.png');

export const CommerceFlag = require('./bikleta-points/bikleta-point-example.webp');

export const BikletaPointsPerks = [
  {
    image: require('./bikleta-points/perks/BP-Perk-1.webp'),
    text: 'Aparecerás en nuestra lista de Bikleta Points dentro de la app para que los Bikleteros se acerquen y adquieran tus productos. ',
  },
  {
    image: require('./bikleta-points/perks/BP-Perk-2.webp'),
    text: 'Serás conocido por toda la comunidad y podrán recomendarte! ',
  },
  {
    image: require('./bikleta-points/perks/BP-Perk-3.webp'),
    text: 'Además tendrás importantes descuentos a la hora de publicitar en nuestra app. ',
  },
];

export const BikletaLogoComplete = {
  light: require('./Logos/Bikleta-Logo-complete.png'),
  dark: require('./Logos/Bikleta-Logo-complete-dark.png'),
};

export const callToAction = require('./callToAction.webp');

export const contactFormImg = require('./contact-form-image.webp');

export const AddBikletaPointSteps = [
  require('./bikletaPoint-steps/step-1.webp'),
  require('./bikletaPoint-steps/step-2.webp'),
  require('./bikletaPoint-steps/step-3.webp'),
  require('./bikletaPoint-steps/step-4.webp'),
  require('./bikletaPoint-steps/step-5.webp'),
];

export const SearchErrorImage = require('./backgrounds/SearchError.png');

export const AppPreviewComplete = require('./appPreviewComplete.webp');

export const LogoIcon = require('./Logos/logo512.png');

export const EmptyStateBenefit = require('./image-not-supported.png');

export const BackgroundHearder = require('./background-header.png');

export const BackgroundHearderMobile = require('./hero-background-mobile.png');

export const BikeIcon = require('./bikleta-icon.png');

export const DownButton = require('./button.png');

export const MockupPhone = require('./mockup-celular.png');

export const MidelBackground = require('./background-midel-footer.png');

export const BrandGroups = require('./brands-group.png');

export const KmExchange = require('./km-exchange.png');

export const BikeRegistration = require('./bike-registration.png');

export const FirstImageBanner = require('./first-image-banner.png');

export const FIBMobile = require('./fib-mobile.png');

export const QrBackground = require('./qr-background.png');

export const QrImage = require('./qr-image.png');

export const RutePoints = require('./rute-point.png');

export const FooterBackground = require('./background-footer.png');

export const FooterBackgroundMobile = require('./footer-mobile.png')

export const QrBox = require('./qr-box.png');

export const AppleIcon = require('./apple-icon-mobile.png')

export const GooglePlayIcon = require('./playstore-icon-mobile.png')