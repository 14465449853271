import * as React from 'react';
import ReactDOM from 'react-dom';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import AuthProvider from './providers/authContext';
import { AxiosProvider } from './providers/axiosContext';
import { WebRoutes } from './routes';
import theme from './theme/theme';


// import "react-toastify/dist/ReactToastify.css";
// // minified version is also included
import "react-toastify/dist/ReactToastify.min.css";

import '@fontsource/nunito';
import '@fontsource/nunito/200.css';
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
import '@fontsource/nunito/800.css';
import '@fontsource/nunito/900.css';

import '@fontsource/roboto';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { cssTransition, ToastContainer } from 'react-toastify';
import './theme/toastify-custom.css';
import { colors } from './utils/colors';

const router = createHashRouter(WebRoutes);

/*
--- React v18 temporary disabled: 
------ Cause of this: https://github.com/google-map-react/google-map-react/issues/1117#issuecomment-1195379330

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);
*/

const CustomSlide = cssTransition({
  enter: 'slide-in',
  exit: 'slide-out',
});

ReactDOM.render(
  <AuthProvider>
    <AxiosProvider>
      <ColorModeScript initialColorMode={'light'} />
      <ChakraProvider theme={theme}>
        <ToastContainer
          toastStyle={{
            borderRadius: 12,
            maxWidth: '97%',
            margin: 'auto',
            marginTop: '0.75rem',
          }}
          progressStyle={{
            // backgroundColor: colors.accent.main,
            height: '0.25rem',
          }}
          position="top-right"
          limit={3}
          autoClose={5000}
          transition={CustomSlide}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="dark"
        />
        <RouterProvider router={router} />
      </ChakraProvider>
    </AxiosProvider>
  </AuthProvider>,
  document.getElementById('root')
);
