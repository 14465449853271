import { usePlacesSearchService } from '../../../services/placesService/placesService';
import { useAsync } from '../../../utils/useAsync';
import { FormStepperController } from './interfaces';

export const useFormSectionController = (): FormStepperController => {
  const service = usePlacesSearchService();

  const [resultsData, , loadingResults, executeSearch, ,] = useAsync(
    service.fetchResults,
    null
  );

  const getInfoFromPhoneNumber = number => {
    const sections = number.split(' ');
    // console.log(sections, sections.length);
    if (sections.length === 1) {
      return {
        phoneNumber: sections[0],
      };
    }
    return {
      region: sections[0],
      phoneNumber: sections.slice(1, sections.length).join(''),
    };
  };

  return {
    setSearchQuery: executeSearch,
    loadingData: loadingResults,
    placesSearchResults: resultsData?.results,
    getInfoFromPhoneNumber,
  };
};
