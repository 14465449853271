export const colors = {
  backgroundColor: '#141414',
  fontColor: '#FFFFFF',

  accent: {
    main: '#f7d42d',
    50: '#f7d42d',
    100: '#f7d42d',
    200: '#f7d42d',
    300: '#f7d42d',
    400: '#f7d42d',
    500: '#2196f3',
    600: '#1e88e5',
    700: '#1976d2',
    800: '#1565c0',
    900: '#0d47a1',
  },
  black: {
    main: '#262626',
    50: '#f8f0f2',
    100: '#d9d9d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#120b0d',
  },
  gray: {
    main: '#AAA9A9',
    0: '#FDFDFC',
    50: '#fceff2',
    100: '#ddd8d9',
    200: '#c2bfbf',
    300: '#a6a5a5',
    400: '#8c8c8c',
    500: '#727272',
    600: '#5a5959',
    700: '#413f3f',
    800: '#292526',
    900: '#16090d',
  },
  red: {
    main: '#FF6051',
    50: '#ffe5e0',
    100: '#ffb7b1',
    200: '#ff897f',
    300: '#FF6051',
    400: '#fe2f1b',
    500: '#e51701',
    600: '#b30f00',
    700: '#810900',
    800: '#4f0400',
    900: '#200000',
  },

  purple: {
    main: '#3E3064',
    50: '#f1edfe',
    100: '#d3cbe8',
    200: '#b5a9d6',
    300: '#9786c4',
    400: '#7a64b3',
    500: '#604a99',
    600: '#4b3a78',
    700: '#352956',
    725: '#4D4B62',
    750: '#363449',
    800: '#201936',
    900: '#0c0717',
  },
};
