import React from 'react';
import { SpendProps } from './interfaces';
import { useSpendController } from './spend.controller';
import {
  Box,
  Center,
  Divider,
  HStack,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  PinInput,
  PinInputField,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Field, FormikProvider } from 'formik';
import { benefitsImage } from '../../../../../resources/images/index';
import {
  ArrowBackIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { colors } from '../../../../../utils/colors';
import { useNavigate } from 'react-router-dom';
import { BenefitModal } from '../../../../../extra/Modals/BenefitModal';
import AtomicCard from '../../../../../extra/AtomicComponents/AtomicCard';
import { useBenefitHistoryStore } from '../../../../../services/benefitsService/benefit.store';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CouponState } from '../../../../../services/benefitsService/benefit.dto';
import usePagination from '../../../../../utils/usePagination';

export const SpendView: React.FC<SpendProps> = ({
  useController = useSpendController,
}) => {
  const controller = useController();
  const navigate = useNavigate();
  const { benefitList } = useBenefitHistoryStore();

  const { next, prev, currentData, currentPage, jump, maxPage } = usePagination(
    benefitList,
    2
  );

  const statusObject: {
    [key in CouponState]: {
      iconImage: any;
      label?: string;
    };
  } = React.useMemo(
    () => ({
      successful: {
        iconImage: benefitsImage.successCheck,
      },
      duplicated: {
        iconImage: benefitsImage.exclamationCheck,
        label: 'Código duplicado',
      },
      invalid: {
        iconImage: benefitsImage.errorCheck,
        label: 'Código inválido o ya expirado',
      },
    }),
    []
  );

  return (
    <Box h={'80vh'}>
      <BenefitModal
        isOpen={controller.isModalOpen}
        onClose={controller.closeModal}
        modalData={controller.modalData}
        spendDate={controller.spendDate}
        setSpendDate={controller.setSpendDate}
      />
      <Stack direction={'row'} alignItems={'center'} width={'100%'} px={2}>
        <IconButton
          icon={<ArrowBackIcon boxSize={'1.5rem'} />}
          aria-label="backIconButton"
          color={colors.gray[0]}
          onClick={() => navigate('/admin/benefits')}
          variant={'ghost'}
        />
        <Stack direction={'column'} w={'100%'}>
          <Box>
            <Text fontSize={24} fontWeight={'800'} color={colors.gray[0]}>
              Canjear beneficio
            </Text>
          </Box>
        </Stack>
      </Stack>
      <Box px={3}>
        <Divider backgroundColor={'white'} mt={5} mb={12} w={'100%'} />
      </Box>
      <FormikProvider value={controller.formikInstance}>
        <Stack direction={'column'} h={'80%'}>
          <Center>
            <Stack direction={'column'} spacing={10}>
              <Box textAlign={'center'}>
                <Text
                  fontSize={20}
                  fontWeight={'800'}
                  lineHeight={'28px'}
                  color={'#FDFDFC'}>
                  Ingresa el código
                </Text>
              </Box>
              <HStack>
                <Field name="code">
                  {({ field, form }) => (
                    <PinInput
                      {...field}
                      variant={'filled'}
                      type="alphanumeric"
                      placeholder="-"
                      isDisabled={controller.formikInstance.isSubmitting}
                      onChange={async value => {
                        if (
                          controller.formikInstance.values.code.length === 6 &&
                          value.length === 6
                        ) {
                          return;
                        }
                        controller.formikInstance.setFieldValue('code', value);
                      }}>
                      <PinInputField
                        textTransform={'uppercase'}
                        style={{
                          backgroundColor: '#6B6980',
                          padding: '24px 0px',
                          height: '76px',
                          width: '56px',
                          borderRadius: '12px',
                          fontSize: '24px',
                          fontWeight: '600',
                        }}
                        _placeholder={{
                          fontSize: '24px',
                          fontWeight: '700',
                          color: 'white',
                          lineHeight: '28px',
                        }}
                      />
                      <PinInputField
                        textTransform={'uppercase'}
                        style={{
                          backgroundColor: '#6B6980',
                          padding: '24px 0px',
                          height: '76px',
                          width: '56px',
                          borderRadius: '12px',
                          fontSize: '24px',
                          fontWeight: '600',
                        }}
                        _placeholder={{
                          fontSize: '24px',
                          fontWeight: '700',
                          color: 'white',
                          lineHeight: '28px',
                        }}
                      />
                      <PinInputField
                        textTransform={'uppercase'}
                        style={{
                          backgroundColor: '#6B6980',
                          padding: '24px 0px',
                          height: '76px',
                          width: '56px',
                          borderRadius: '12px',
                          fontSize: '24px',
                          fontWeight: '600',
                        }}
                        _placeholder={{
                          fontSize: '24px',
                          fontWeight: '700',
                          color: 'white',
                          lineHeight: '28px',
                        }}
                      />
                      <PinInputField
                        textTransform={'uppercase'}
                        style={{
                          backgroundColor: '#6B6980',
                          padding: '24px 0px',
                          height: '76px',
                          width: '56px',
                          borderRadius: '12px',
                          fontSize: '24px',
                          fontWeight: '600',
                        }}
                        _placeholder={{
                          fontSize: '24px',
                          fontWeight: '700',
                          color: 'white',
                          lineHeight: '28px',
                        }}
                      />
                      <PinInputField
                        textTransform={'uppercase'}
                        style={{
                          backgroundColor: '#6B6980',
                          padding: '24px 0px',
                          height: '76px',
                          width: '56px',
                          borderRadius: '12px',
                          fontSize: '24px',
                          fontWeight: '600',
                        }}
                        _placeholder={{
                          fontSize: '24px',
                          fontWeight: '700',
                          color: 'white',
                          lineHeight: '28px',
                        }}
                      />
                      <PinInputField
                        textTransform={'uppercase'}
                        style={{
                          backgroundColor: '#6B6980',
                          padding: '24px 0px',
                          height: '76px',
                          width: '56px',
                          borderRadius: '12px',
                          fontSize: '24px',
                          fontWeight: '600',
                        }}
                        _placeholder={{
                          fontSize: '24px',
                          fontWeight: '700',
                          color: 'white',
                          lineHeight: '28px',
                        }}
                      />
                    </PinInput>
                  )}
                </Field>
              </HStack>

              <Box textAlign={'center'}>
                <Text
                  fontSize={20}
                  fontWeight={'800'}
                  lineHeight={'28px'}
                  color={'#FDFDFC'}>
                  Cargados recientemente
                </Text>
              </Box>

              {currentData()?.length > 0 ? (
                currentData()?.map(coupon => (
                  <AtomicCard
                    image={
                      statusObject[coupon.status].iconImage ??
                      statusObject.invalid.iconImage
                    }
                    title={coupon.code}
                    subtitle1={format(
                      new Date(coupon.consumedDate),
                      'dd MMMM yyyy, k:mm',
                      {
                        locale: es,
                      }
                    )}
                    subtitle2={
                      statusObject[coupon.status]?.label ??
                      `Desde ${format(
                        new Date(coupon.startValidDate),
                        'dd/MM/yyyy'
                      )} hasta ${format(
                        new Date(coupon.expirationDate),
                        'dd/MM/yyyy'
                      )}`
                    }
                  />
                ))
              ) : (
                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                  <Image src={benefitsImage.noCodeAlert} alt="No code image" />
                </Box>
              )}
            </Stack>
          </Center>
        </Stack>
        {currentData()?.length > 0 && (
          <Center w="full">
            <Stack spacing={5} direction={'row'}>
              <Tooltip label="Página anterior">
                <IconButton
                  aria-label="button-pag"
                  onClick={prev}
                  variant={'ghost'}
                  borderColor={'whiteAlpha.100'}
                  borderWidth={'medium'}
                  isDisabled={currentPage < 2}
                  icon={<ChevronLeftIcon h={6} w={6} />}
                />
              </Tooltip>

              <Stack direction={'row'} spacing={2}>
                <HStack>
                  <Text mr={8}>
                    Página{' '}
                    <Text fontWeight="bold" as="span">
                      {currentPage}
                    </Text>{' '}
                    de{' '}
                    <Text fontWeight="bold" as="span">
                      {maxPage}
                    </Text>
                  </Text>
                </HStack>
                <HStack>
                  <Text>Ir a página:</Text>{' '}
                  <NumberInput
                    ml={2}
                    mr={8}
                    w={28}
                    min={1}
                    max={maxPage}
                    onChange={value => {
                      const num = parseInt(value);
                      num && jump(num);
                    }}
                    defaultValue={currentPage + 1}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </HStack>
              </Stack>

              <Tooltip label="Página siguiente">
                <IconButton
                  aria-label="button-next"
                  onClick={next}
                  variant={'ghost'}
                  borderColor={'whiteAlpha.100'}
                  borderWidth={'medium'}
                  isDisabled={currentPage === maxPage}
                  icon={<ChevronRightIcon h={6} w={6} />}
                />
              </Tooltip>
            </Stack>
          </Center>
        )}
      </FormikProvider>
    </Box>
  );
};
