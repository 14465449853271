import axios from 'axios';
import { appUrl } from '../../conf';
import { BikeDTO } from './dto';

export interface BikletaPointsService {
  fetchBikes: (params?: any) => Promise<BikeDTO[]>;
}

export const useBikesService = (): BikletaPointsService => {
  const fetchBikes = async search => {
    //console.log('Params get: ', search);
    const res = await axios.get(appUrl + '/web/searchEngine/bikleta', {
      params: {
        search,
      },
    });
    return res.data;
  };

  return { fetchBikes };
};
