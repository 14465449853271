import { AxiosResponse } from 'axios';
import { useContext } from 'react';
import { AxiosContext } from '../../providers/axiosContext';

export interface AuthService {
  login: (usr: string, psw: string) => Promise<AxiosResponse<any, any>>;
  register: (data: any) => Promise<AxiosResponse<any, any>>;
  forgotPassword: (email: string) => Promise<AxiosResponse<any, any>>;
  changePassword: (
    code: number,
    psw: string,
    email: string
  ) => Promise<AxiosResponse<any, any>>;
  confirmPassword: (oldPassword: string) => Promise<AxiosResponse<any, any>>;
  changePasswordLogged: (
    newPassword: string
  ) => Promise<AxiosResponse<any, any>>;
  externalLogin: (
    service: string,
    info: any
  ) => Promise<AxiosResponse<any, any>>;
  validateEmail: (email: string) => Promise<any>;
}

export const useAuthService = (): AuthService => {
  const axiosContext = useContext(AxiosContext);

  const login = async (email: string, password: string) => {
    return axiosContext.publicAxios.post('/web/users/login', {
      email,
      password,
    });
  };

  const externalLogin = (service: string, info: any) => {
    return axiosContext.publicAxios.post(`/web/users/login/${service}`, info);
  };

  const register = async (data: any) => {
    //console.log('Data: ', data);

    const res = await axiosContext.publicAxios.post('/web/users/register', data);
    // console.log(res);
    return res;
  };

  const forgotPassword = async (email: string) => {
    return axiosContext.publicAxios.post('/web/users/forgot-password', {
      email,
    });
  };

  const changePassword = async (
    code: number,
    newPassword: string,
    email: string
  ) => {
    return axiosContext.publicAxios.post('/web/users/change-password', {
      code,
      newPassword,
      email,
    });
  };

  const confirmPassword = async (oldPassword: string) => {
    return axiosContext.authAxios.post('/web/users/confirm-password', {
      password: oldPassword,
    });
  };

  const changePasswordLogged = async (newPassword: string) => {
    return axiosContext.authAxios.post('/web/users/change-password-logged', {
      newPassword,
    });
  };

  const validateEmail = (email: string) => {
    return axiosContext.publicAxios.get(
      `/validation-utils/emails?email=${email}`
    );
  };

  return {
    login,
    register,
    changePassword,
    forgotPassword,
    confirmPassword,
    changePasswordLogged,
    externalLogin,
    validateEmail,
  };
};
