import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Hero } from '../../components/Header/Header';
import 'swiper/css/bundle';
import WithSubnavigation from '../../components/NavbarSub';
import { MediaCard } from '../../components/MediaCard/MediaCard';
import { MidelFooter } from '../../components/MidelFooter/MidelFooter';
import { Layout } from '../../components/TextImageLayout/TextImageLayout';
import { Banner } from '../../components/Banner/Banner';
import { Footer } from '../../components/Footer/Footer';

const Home = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflowX;
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.overflowX = originalStyle;
    };
  }, []); 

  return (
    <Box minWidth={'100%'} as="section" position={'relative'} zIndex={0}>
      <Box position={'fixed'} top="0" zIndex={'999'} width={'100%'}>
        <WithSubnavigation />
      </Box>
      <Hero />
      <MediaCard />
      <MidelFooter />
      <Layout />
      <Banner />
      <Footer />
    </Box>
  );
};

export default Home;
