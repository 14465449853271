// Chakra imports
import {
  Portal,
  useDisclosure,
  useToast,
  Box,
  Skeleton,
  useColorMode,
} from '@chakra-ui/react';
// Layout components
import AdminNavbar from '../components/Navbars/AdminNavbar';
import Sidebar from '../components/Sidebar/Sidebar';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// Custom Chakra theme
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import { adminRoutes as routes } from '../routes';
import Configurator from '../components/Configurator/Configurator';
import { useAsync } from '../utils/useAsync';
import { useProfileService } from '../services/profile/profileService';
import ModalAddForm from '../components/Admin/FormSection/formStepper.view';
import { useBikletaPointsService } from '../services/bikletaPoints/bikletaPointsService';
import { useStore } from '../utils/useStore';

function AdminLayout(props) {
  const { setColorMode } = useColorMode();
  setColorMode('dark');

  useEffect(() => {
    document.body.style.overflow = 'unset';
    return function cleanup() {};
  });

  useEffect(() => {
    return () => {
      setColorMode('light');
    };
  }, []);

  const {
    activePoint,
    setActivePoint,
    setCurrentUser,
    setSessionBPs,
    sessionBikletaPoints,
  } = useStore();
  const profileService = useProfileService();
  const bikletaPointsService = useBikletaPointsService();
  const toast = useToast();

  const [oldActivePoint, setOldActivePoint] = useState(null);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const [data, , userLoading, execute, ,] = useAsync(
    profileService.fetchProfile,
    null,
    true
  );

  const [myBPs, , bpsLoading, executeBP, ,] = useAsync(
    bikletaPointsService.getMyBPs,
    null,
    true
  );

  useEffect(() => {
    execute();
    executeBP();
  }, []);

  useEffect(() => {
    //console.log('User Data: ', data);
    data && setCurrentUser(data);
  }, [data]);

  useEffect(() => {
    //console.log('USER Bikleta Points: ', myBPs);
    if (myBPs) {
      myBPs && setSessionBPs(myBPs);
      myBPs.length > 0 ? setActivePoint(myBPs[0]) : onModalOpen();
    }
  }, [myBPs]);

  useEffect(() => {
    if (activePoint && activePoint._id !== oldActivePoint?._id) {
      setOldActivePoint(activePoint);
      toast.closeAll();
      toast({
        title: 'Administrando ' + activePoint.name,
        description: 'Los datos del Bikleta Point se cargaron exitosamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [activePoint]);

  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState('transparent');
  const [fixed, setFixed] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = routes => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      }
      if ('/' + window.location.pathname.split('/')[2] === routes[i].path) {
        return routes[i].name;
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }

    // On View change, scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = routes => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Chakra Color Mode
  return (
    <Box fontFamily={'Nunito'}>
      <Sidebar
        routes={routes}
        logoText={'Bikleta Point Admin'}
        display="none"
        variant={sidebarVariant}
        bikletaPointsState={{
          isLoading: bpsLoading,
          bikletaPoints: sessionBikletaPoints,
        }}
        onModalOpen={onModalOpen}
        {...rest}
      />
      <MainPanel
        w={{
          base: '100%',
          xl: 'calc(100% - 275px)',
        }}
        bgColor="#27273A"
        minH="100vh">
        <Portal>
          <AdminNavbar
            userData={data}
            userLoading={userLoading}
            onOpen={onOpen}
            logoText={'Bikleta Point Admin'}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            bikletaPointsState={{
              isLoading: bpsLoading,
              bikletaPoints: myBPs,
            }}
            onModalOpen={onModalOpen}
            {...rest}
          />
        </Portal>

        {getRoute() ? (
          <PanelContent p={0} m={0}>
            <PanelContainer bgColor="#313149" minH="100vh">
              <Skeleton
                isLoaded={!!activePoint}
                rounded="md"
                mt={{ base: '120px', md: '75px' }}>
                <Outlet />
                <ModalAddForm isOpen={isModalOpen} onClose={onModalClose} />
              </Skeleton>
            </PanelContainer>
          </PanelContent>
        ) : null}
        <Configurator
          secondary={getActiveNavbar(routes)}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          onSwitch={value => {
            setFixed(value);
          }}
          onOpaque={() => setSidebarVariant('opaque')}
          onTransparent={() => setSidebarVariant('transparent')}
        />
        <Portal>
          <Box hidden>
            <FixedPlugin
              secondary={getActiveNavbar(routes)}
              fixed={fixed}
              onOpen={onOpen}
            />
          </Box>
        </Portal>
      </MainPanel>
    </Box>
  );
}

export default AdminLayout;
