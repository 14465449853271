import React from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Input,
  Image,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link as ReachLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { BikletaLogoComplete } from '../assets';
import { MenuIcon, MenuIconDark } from './Icons/Icons';

const NAV_ITEMS = [
  {
    label: 'Bikleta Points',
    children: [
      {
        label: 'Registrar punto Bikleta',
        subLabel: 'Ingresa, completa el formulario y sé parte de la comunidad',
        href: '/auth',
      },
      {
        label: 'Descubrir Puntos Bikleta',
        subLabel: 'Explora el mapa y los Puntos Bikleta disponibles',
        href: '/map/points',
      },
    ],
  },
  {
    label: 'Bikletas robadas',
    children: [
      {
        label: 'Reportar Mi Bikleta',
        subLabel: 'Descarga la app y reporta tu bikleta',
        href: '/download',
      },
      {
        label: 'Ver Bikletas Robadas',
        subLabel: 'Explora el mapa y los puntos de robos',
        href: '/map/bikletas',
      },
    ],
  },
  {
    label: 'Blog',
    children: [
      {
        label: 'Visita nuestro blog',
        subLabel: 'Descubre las últimas noticias y novedades',
        href: 'https://bikleta.blog',
      },
    ]
  },
];

const useIsMobile = () => {
  const isMobileDevice = typeof navigator === "undefined" ? false : (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i));
  return isMobileDevice ? true : false;
}

const DesktopNav = ({ colorChange }) => {
  const linkColor = colorChange ? 'gray.600' : 'gray.200';
  const linkHoverColor = colorChange ? 'gray.800' : 'white';
  const popoverContentBgColor = 'white';

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Button
                variant="ghost"
                fontWeight={500}
                size={'sm'}
                p={2}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Button>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map(child => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  const isExternalLink = href.startsWith('http');

  const handleNavigation = () => {
    if (isExternalLink) {
      window.location.href = href;
    }
  };

  return (
    <Link
      as={isExternalLink ? undefined : ReachLink}
      to={isExternalLink ? undefined : { pathname: href }}
      onClick={handleNavigation}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('yellow.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'yellow.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'} color="gray">
            {subLabel}
          </Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'yellow.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};


const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      paddingBlock={4}
      maxW={'100%'}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href = null }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map(child => (
              <Link
                as={ReachLink}
                key={child.label}
                py={2}
                to={{ pathname: child.href }}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default function WithSubnavigation({ whiteBG = false }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [colorChange, setColorchange] = useState(whiteBG);
  const [isHover, setIsHover] = useState(false);
  const btnRef = React.useRef()
  const handleClick = () => {
    const { top } = document
      .getElementById('DownloadSection')
      .getBoundingClientRect();
    window.scrollTo({ top: top + 110, behavior: 'smooth' });
  };

  const history = useNavigate();
  const changeNavbarColor = () => {
    if (!whiteBG) {
      if (window.scrollY >= 80) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  return (
    <Box>
      <Flex
        bgColor={colorChange && 'white'}
        borderBottomRadius={'10px'}
        transition="background-color 300ms linear"
        display={'flex'}
        justifyContent={'space-between'}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ sm: 3, md: 10 }}
        borderBottom={0}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        shadow={colorChange && 'xl'}>
        <Flex
          justify={{ base: 'center', md: 'start' }}>
          {useIsMobile() === true &&
            <Button
              variant="ghost"
              fontWeight={500}
              size={'md'}
              marginRight={'5px'}
              ref={btnRef}
              onClick={onOpen}
              _hover={{
                textDecoration: 'none',
              }}
            >
              {colorChange ? <MenuIconDark h="10rem" /> : <MenuIcon h="10rem" />}
            </Button>
          }
          <Flex
            _hover={{ cursor: 'pointer' }}
            onClick={() => history('/')}>
            <Image
              style={{ marginTop: '5px' }}
              src={colorChange ? BikletaLogoComplete.dark : BikletaLogoComplete.light}
              h="1.5rem"
              alt="Company Logo"
            />
          </Flex>
        </Flex>
        <Stack
          flex={{ base: 1 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav colorChange={colorChange} />
          </Flex>
          <Button
            size={'sm'}
            width={'150px'}
            fontWeight={520}
            _hover={{ background: 'transparent' }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            border={isHover ? '1px solid #FFC700' : 'none'}
            textColor={isHover ? '#FFC700' : 'black'}
            bg={isHover ? '#FFF9DA' : '#FFC700'}
            onClick={() => handleClick()}
            >
            Descargar la app
          </Button>
        </Stack>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef} >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image
              src={BikletaLogoComplete.dark}
              h="1.5rem"
              alt="Company Logo"
            />
          </DrawerHeader>
          <DrawerBody >
            <MobileNav />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
