import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import { ServicesItemTypes } from '../../../utils/constants';
import Card from '../../../components/Card/Card';
import { useStore } from '../../../utils/useStore';
import { ServiceViewProps } from './interfaces';
import useServicesViewController from './services.controller';

const ServicesView: React.FC<ServiceViewProps> = ({
  useController = useServicesViewController,
}) => {
  const controller = useController();

  const { activePoint } = useStore();

  return (
    <Card mt={'8%'}>
      <Heading fontWeight={'medium'} p={2} fontSize={'3xl'}>
        Servicios
      </Heading>
      <Divider />
      <Box p={7}>
        <Grid templateColumns={'repeat(3, 1fr)'} gap={10}>
          <GridItem colSpan={3} px={'3%'}>
            <Text>
              Indica a los visitantes de tu tienda que pueden encontrar y cuales
              son los servicios que se ofrecen, para aumentar la cantidad de
              potenciales clientes.
            </Text>
          </GridItem>
          {ServicesItemTypes.map((item, index) => {
            const isServiceActive =
              (activePoint?.services &&
                activePoint.services[item.type]?.isActive) ??
              false;

            return (
              <GridItem
                key={'service-item-' + index}
                colSpan={{
                  sm: 3,
                  md: 1,
                }}
                h="12vh">
                <Card
                  onClick={() =>
                    controller.setServiceState(item.type, !isServiceActive)
                  }
                  bgColor={isServiceActive ? 'brand.50' : '#595966'}
                  borderRadius="12"
                  h="100%"
                  maxW={{ sm: '100%', md: '80%' }}
                  marginX={'auto'}
                  _hover={{
                    cursor: 'pointer',
                    bgColor: isServiceActive ? 'brand.100' : '#474752',
                  }}
                  px={3}
                  py={1}>
                  <Flex
                    gap="5"
                    alignItems={'center'}
                    justifyContent="space-between"
                    my={'auto'}>
                    <Text
                      color={isServiceActive ? 'black' : '#C4C4C4'}
                      fontSize={'1rem'}
                      noOfLines={2}>
                      {item.itemName}
                    </Text>
                    <Icon as={item.icon} fontSize="2.6rem" color={'#313149'} />
                  </Flex>
                </Card>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    </Card>
  );
};

export default ServicesView;
