import { Box, Image, Stack, useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../providers/authContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleClientId } from '../conf';
import { BikletaLogoComplete, slidesImages } from '../assets';

function AuthLayout() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const { setColorMode } = useColorMode();
  setColorMode('dark');

  useEffect(() => {
    document.body.style.overflow = 'unset';
    return function cleanup() {};
  });

  useEffect(() => {
    return () => {
      setColorMode('light');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authContext.authState.status === 'logged') {
      navigate('/admin');
    }
  }, [authContext.authState.status, navigate]);

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Stack
        h="100vh"
        position="relative"
        bgColor={'#313149'}
        fontFamily={'Nunito'}
        direction={{ sm: 'column', lg: 'row' }}
        spacing={0}>
        <Box w={{ sm: 'full', lg: '57%' }} h="full">
          <Outlet />
        </Box>
        <Box
          flexGrow={1}
          bgImage={slidesImages[0]}
          bgRepeat={'no-repeat'}
          bgPosition={{ sm: '55%' }}
          bgSize="cover"
          display={{ base: 'none', lg: 'block' }}
          style={{
            boxShadow: 'inset 0px 0px 250px 110px rgba(0,0,0,0.76)',
          }}
          pos={'relative'}>
          {/* <Box
            pos={'absolute'}
            bottom={4}
            right={0}
            bgColor={'brand.50'}
            p={4}
            pr={'10%'}
            borderLeftRadius={50}>
            <Image src={BikletaLogoComplete.dark} h="2.2rem" color={'black'} alt='Auth Side Image' />
          </Box> */}
        </Box>
      </Stack>
    </GoogleOAuthProvider>
  );
}
export default AuthLayout;
