import { useAsync } from '../../../utils/useAsync';
import { DashboardControllerProps } from './interfaces';
import { useBikletaPointsService } from '../../../services/bikletaPoints/bikletaPointsService';
import { useEffect, useState } from 'react';
import { useStore } from '../../../utils/useStore';

export const useDashboardViewController = (): DashboardControllerProps => {
  const bpService = useBikletaPointsService();
  const [isRefreshing, setIsrefreshing] = useState(false);
  const { activePoint, sessionStats, setSessionStatsData } = useStore();

  const [statsData, , loadingData, executeStats, , ,] = useAsync(
    bpService.getStatistics,
    null,
    true
  );

  useEffect(() => {
    if (activePoint && !sessionStats) {
      executeStats(activePoint._id);
    }
  }, [activePoint]);

  useEffect(() => {
    if (statsData) {
      setSessionStatsData(statsData);
    }
  }, [statsData]);

  const handleRefresh = async () => {
    try {
      setIsrefreshing(true);
      await executeStats(activePoint._id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsrefreshing(false);
    }
  };

  return {
    statsData: statsData || sessionStats,
    statsLoading: !!sessionStats ? false : loadingData,
    statsRefresh: handleRefresh,
    isRefreshing,
  };
};
