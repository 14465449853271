import { Box, Text, Image, Stack, HStack } from "@chakra-ui/react"
import { FirstImageBanner, QrBackground, RutePoints, QrBox, OnlyBikes, AppleIcon, GooglePlayIcon } from "../../assets"
import QRCode from "react-qr-code";
import { storeLinks } from "../../utils/constants";

export const Banner = () => {

    const useIsMobile = () => {
        const isMobileDevice = typeof navigator === "undefined" ? false : (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i));
        return isMobileDevice ? true : false;
    }

    return (
        <>
            <HStack

                height={{ base: '300px', md: '650px' }}
                style={{
                    width: '100%',
                    position: 'relative',
                    zIndex: 4,
                }}>
                <img
                    style={
                        {
                            position: "absolute",
                            zIndex: 99,
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            minHeight: useIsMobile() ? "340px" : "900px",
                            objectFit: 'cover'
                        }}
                    src={FirstImageBanner} />
                <Stack
                    marginLeft={{ base: '70px', md: '200px' }}
                    paddingBottom={{ base: '20px', md: '45px' }}
                    paddingLeft={{ base: '0', md: '20px' }}
                    paddingTop={{ base: '20px', md: '45px' }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 99,
                        alignItems: "center",
                        width: "40%"
                    }}>
                    <Text fontSize={{ base: '16px', md: '35px' }} style={{ color: "white", fontWeight: '600' }}>
                        SÚBETE A BIKLETA <br />
                        Y PEDALEA
                    </Text>
                    <Image height={{ base: '60px', md: '100px' }} width={{ base: '120px', md: '250px' }} src={RutePoints} />
                    <Text fontSize={{ base: '16px', md: '35px' }} style={{ color: '#FFC700', fontWeight: '700' }}>
                        HACIA UNA VIDA <br />
                        MAS SALUDABLE
                    </Text>
                </Stack>
            </HStack>
            <Box
                marginTop={{ base: '-100px', md: '0px' }}
                height={{ base: '490px', md: '800px' }}
                style={{
                    maxWidth: '100%',
                    position: 'relative',
                    zIndex: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: useIsMobile() ? "800px" : "800px",
                    objectFit: 'cover'
                }}>
                <img
                    style={
                        {
                            position: "absolute",
                            zIndex: 99,
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            height: "740px",
                            filter: `drop-shadow(white 20px ${useIsMobile() ? 16 : 32}px 0px) drop-shadow(white -20px 8px 0px)`,
                        }}
                    src={QrBackground} />
                <Box
                    flexDirection={useIsMobile() ? 'column' : 'row'}
                    bgSize='cover'
                    paddingInline={useIsMobile() ? '40px' : '0px'}
                    marginTop={{ base: '0px', md: '50px' }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#FFC700',
                        borderRadius: '20px',
                        paddingBlock: '10px',
                        zIndex: 110

                    }}>
                    {useIsMobile() === true ?
                        <Box
                            id="DownloadSection"
                            paddingBlock={{ base: '10px', md: '30px' }}
                            paddingInline={{ base: '0px', md: '60px' }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                            }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                <Text
                                    style={{
                                        color: "black",
                                        fontSize: '30px',
                                        fontWeight: '300',
                                        marginRight: '10px',
                                    }}>
                                    Descarga
                                </Text>
                                <Text
                                    style={{
                                        color: "black",
                                        fontSize: '30px',
                                        fontWeight: '800',
                                    }}>
                                    Bikleta
                                </Text>
                            </Box>
                            <Box
                                bgSize='cover'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    backgroundColor: 'black',
                                    borderRadius: '8px',
                                    width: '240px'
                                }}>
                                <Box
                                    _hover={{ cursor: 'pointer' }}
                                    as={'a'}
                                    target="_blank"
                                    href={storeLinks.apple}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingInline: '20px',
                                        paddingBlock: '10px'
                                    }}>
                                    <Image
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            marginRight: '20px'
                                        }}
                                        src={AppleIcon} />
                                    <Stack>
                                        <Text
                                            style={{
                                                color: "white",
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                marginRight: '10px',
                                            }}>
                                            Disponible en
                                        </Text>
                                        <Text
                                            style={{
                                                color: "white",
                                                fontSize: '16px',
                                                fontWeight: '800',
                                                marginTop: '-0.2rem'
                                            }}>
                                            App Store
                                        </Text>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box
                                _hover={{ cursor: 'pointer' }}
                                as={'a'}
                                target="_blank"
                                href={storeLinks.android}
                                bgSize='cover'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    backgroundColor: 'black',
                                    borderRadius: '8px',
                                    width: '240px'
                                }}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingInline: '20px',
                                        paddingBlock: '10px'
                                    }}>
                                    <Image
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            marginRight: '20px'
                                        }}
                                        src={GooglePlayIcon} />
                                    <Stack>
                                        <Text
                                            style={{
                                                color: "white",
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                marginRight: '10px',
                                            }}>
                                            Disponible en
                                        </Text>
                                        <Text
                                            style={{
                                                color: "white",
                                                fontSize: '16px',
                                                fontWeight: '800',
                                                marginTop: '-0.2rem'
                                            }}>
                                            Play Store
                                        </Text>
                                    </Stack>
                                </Box>
                            </Box>

                        </Box>
                        :
                        <Box
                            id="DownloadSection"
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                            <Box
                                paddingBlock={{ base: '10px', md: '30px' }}
                                paddingInline={{ base: '0px', md: '60px' }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    marginLeft: '100px',
                                    marginRight: '80px',
                                }} >
                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: '30px',
                                            fontWeight: '300',
                                            marginRight: '10px',
                                        }}>
                                        Descarga
                                    </Text>
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: '30px',
                                            fontWeight: '800',
                                        }}>
                                        Bikleta
                                    </Text>
                                </Box>
                                <QrSection section={'banner'} />
                            </Box>
                            <Image style={{ height: '150px', width: '475px' }} src={QrBox} />
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}

export const DownloadSection = () => {
    <Box
        width={{ base: '600px', md: '1000px', lg: '1440px' }}
        style={{
            maxWidth: '1440px',
            height: '1000px',
            position: 'relative',
            zIndex: 1,
            marginTop: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        bgImage={QrBackground}>
        <Box
            bgSize='cover'
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '60%',
                marginTop: '50px',
                height: '40%',
                backgroundColor: '#FFC700',
                borderRadius: '20px'
            }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    marginLeft: '100px',
                    marginRight: '80px',
                }} >
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                        style={{
                            color: "black",
                            fontSize: '30px',
                            fontWeight: '300',
                            marginRight: '10px',
                        }}>
                        Descarga
                    </Text>
                    <Text
                        style={{
                            color: "black",
                            fontSize: '30px',
                            fontWeight: '800',
                        }}>
                        Bikleta
                    </Text>
                </Box>
                <QrSection section={'banner'} />
            </Box>
            <Image style={{ height: '150px', width: '475px' }} src={QrBox} />
        </Box>
    </Box>
}

export const QrSection = ({ section }) => {
    return (
        <Box
            padding={section === 'banner' ? '20px' : '10px'}
            width={section === 'banner' ? '' : '100px'}
            height={section === 'banner' ? '' : '100px'}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '10px'
            }}>
            <Box
                padding={section === 'banner' ? '5px' : '3px'}
                width={section === 'banner' ? '40px' : '20px'}
                height={section === 'banner' ? '40px' : '20px'}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    backgroundColor: '#FFC700',
                    position: 'absolute',
                    zIndex: 2,
                }} >
                <Image src={OnlyBikes} />
            </Box>
            <QRCode
                style={{ width: '200px', height: '200px' }}
                value={'https://www.bikleta.com/download#/download/'}
            />
        </Box>
    )
}