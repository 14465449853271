import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../providers/authContext';

export default function PrivateRoute({ component: Component, ...rest }) {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.authState.status;

  return currentUser === 'loading' ? null : currentUser === 'logged' ? (
    <Component />
  ) : (
    <Navigate to="/auth/signin" />
  );
}
