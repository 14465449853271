import {
  Box,
  Center,
  Icon,
  Stack,
  Button,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  IoIosWarning,
  IoMdCall,
  IoMdCopy,
  IoMdMail,
  IoMdShare,
} from 'react-icons/io';
import { BikeDTO } from '../../services/bikesService/dto';
import { shareMethod } from '../../utils/shareMethod';
import { format } from 'date-fns';
import es from 'date-fns/esm/locale/es/index.js';
interface BikeDetailProps {
  item: BikeDTO;
}

const BikeDetail: React.FC<BikeDetailProps> = ({ item }) => {
  return (
    <>
      <Center
        h={{ sm: '20vh', md: '40%' }}
        bgSize={'contain'}
        bgRepeat={'no-repeat'}
        bgPos="center"
        bgColor={'#313149'}>
        <Icon as={IoIosWarning} w="50%" h="50%" color="white" />
      </Center>
      <Stack direction={'column'} spacing={3} w="100%" h="60%">
        <Box p={0} borderRadius="lg">
          <Box p={4}>
            <Text noOfLines={1} fontSize={'lg'} fontWeight={400}>
              {item.name}
            </Text>
            <Text noOfLines={1} fontSize={'sm'} mt={1} color="gray">
              {item.bikeType}
            </Text>
            <Text noOfLines={1} fontSize={'md'} mt={1}>
              {item.model}
            </Text>
          </Box>
          <Box h={0.5} bgColor="gray.300" />
          <Stack
            display={{ sm: 'none', lg: 'flex' }}
            direction={'column'}
            spacing={2}
            p={4}
            alignItems="left"
            color="gray.600">
            <Stack
              direction={'row'}
              justifyContent="space-between"
              fontSize={'sm'}>
              <Text>Fecha de Robo:</Text>
              <Text>
                {format(new Date(item.status.datetime), 'PPpp', {
                  locale: es,
                })}
              </Text>
            </Stack>
            <Text fontSize={'small'} hidden>
              Si encontraste esta Bikleta por favor ponete en contacto con su
              dueño a través de los siguientes canales:
            </Text>
            {item.owner.phone && (
              <Button
                leftIcon={<IoMdCall />}
                variant="outline"
                w="fit-content"
                hidden>
                {item.owner.phone}
              </Button>
            )}
            {item.owner.email && (
              <Button
                leftIcon={<IoMdMail />}
                variant="outline"
                w="fit-content"
                hidden>
                {item.owner.email}
              </Button>
            )}
            <Button
              hidden
              leftIcon={<IoMdCopy />}
              variant="outline"
              w="fit-content"
              onClick={() => {
                navigator.clipboard.writeText(
                  item.status.meta.location.lat +
                    ', ' +
                    item.status.meta.location.lng
                );
              }}>
              {item.status.meta.location.lat +
                ', ' +
                item.status.meta.location.lng}
            </Button>
            <Button
              leftIcon={<IoMdShare />}
              variant="outline"
              w="fit-content"
              hidden
              onClick={() =>
                shareMethod('Mira esta Bikleta Robada!', '?point=' + item.name)
              }>
              Compartir
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default BikeDetail;
