import React, { useEffect, useState } from 'react';
import MapComponent from '../../../extra/MapComponent';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  Button,
  Input,
  Image,
  InputGroup,
  IconButton,
  InputRightElement,
  Stack,
  Flex,
  Box,
  chakra,
  Center,
  useMediaQuery,
  useColorMode,
} from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { FaAppStore, FaArrowLeft, FaGooglePlay } from 'react-icons/fa';
import { BikesMapViewProps } from '../interfaces';
import { useMapViewController } from './mapview.controller';
import { isIOS } from '../../../utils/checkDeviceType';
import BikeListCard from '../../../components/BikeCard/bikeListCard';
import { BikeDTO } from '../../../services/bikesService/dto';
import BikeDetail from '../../../components/BikeDetail';
import CurrentLocationMarker from '../../../extra/CurrentLocationMarker';
import { shareMethod } from '../../../utils/shareMethod';
import { AiFillHome } from 'react-icons/ai';

const InviteCard = ({ isIOSDevice }) => (
  <Flex
    my="auto"
    maxW="full"
    bg="white"
    _hover={{
      cursor: 'pointer',
    }}
    rounded="lg"
    overflow="hidden"
    fontSize={'sm'}
    sx={{
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Old versions of Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      'user-select': 'none' /* Non-prefixed version, currently
                                 supported by Chrome, Edge, Opera and Firefox */,
    }}>
    <Box
      w={2.3 / 3}
      p={{
        base: 4,
        md: 4,
      }}>
      <chakra.h1
        fontSize="lg"
        color="gray.800"
        _dark={{
          color: 'white',
        }}>
        Registra tu Bikleta en la app
      </chakra.h1>

      <chakra.p
        mt={2}
        fontSize="smaller"
        color="gray.600"
        _dark={{
          color: 'gray.400',
        }}>
        Registra tu Bikleta y mantenla segura ante robos y extravíos.
      </chakra.p>
    </Box>

    <Center
      w={0.7 / 3}
      p={{
        base: 4,
        md: 4,
      }}
      borderLeftRadius="full"
      bgColor={'#313149'}>
      <IconButton
        aria-label="Descargar"
        bg="transparent"
        _hover={{
          bg: 'transparent',
          color: 'white',
        }}
        color={'brand.50'}
        fontSize="2xl"
        icon={isIOSDevice ? <FaAppStore /> : <FaGooglePlay />}
      />
    </Center>
  </Flex>
);

const MapView: React.FC<BikesMapViewProps> = props => {
  const { useController = useMapViewController } = props;
  const { setColorMode } = useColorMode();
  setColorMode('light');

  const variant = !useMediaQuery('(min-width: 768px)', { ssr: false })[0];

  const isIOSDevice = isIOS();

  const controller = useController();

  const navigation = useNavigate();

  const [currentPos, setCurrentPos] = useState(null);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    if (controller.focusedPoint) {
      setCurrentPos(
        variant
          ? {
              ...controller.focusedPoint.status.meta.location,
              lat: controller.focusedPoint.status.meta.location.lat - 0.0008,
            }
          : {
              ...controller.focusedPoint.status.meta.location,
              lng: controller.focusedPoint.status.meta.location.lng - 0.0013,
            }
      );
    }
  }, [controller.focusedPoint, variant]);

  return (
    <>
      <Drawer
        isOpen
        trapFocus={false}
        placement={variant ? 'bottom' : 'left'}
        variant={'alwaysOpen'}
        onClose={null}
        size="sm">
        <DrawerContent bgColor={!variant ? '#FAF9F7' : 'transparent'} p={0}>
          <DrawerHeader py={'2%'} display={{ sm: 'none', lg: 'block' }} px={2}>
            <Stack direction={'row'} w="100%" alignItems={'center'}>
              <IconButton
                as={Link}
                to="/"
                icon={<AiFillHome />}
                size="md"
                rounded={'full'}
                color="black"
                bg="white"
                aria-label="Home Button"
              />
              <InputGroup w={'100%'} size="md">
                <InputRightElement>
                  <IconButton
                    bg={'red.200'}
                    isLoading={
                      controller.loading || controller.refreshing || false
                    }
                    aria-label="Search"
                    size="sm"
                    fontSize={'smaller'}
                    isRound
                    onClick={() => controller.setSearchInput(searchText)}
                    icon={<SearchIcon />}
                  />
                </InputRightElement>
                <Input
                  autoFocus={false}
                  rounded={'full'}
                  size={'md'}
                  placeholder={'Buscar...'}
                  bgColor={'white'}
                  onBlur={e =>
                    setSearchText(
                      e.currentTarget.value === ''
                        ? null
                        : e.currentTarget.value
                    )
                  }
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      controller.setSearchInput(
                        e.currentTarget.value === ''
                          ? null
                          : e.currentTarget.value
                      );
                    }
                  }}
                />
              </InputGroup>
            </Stack>
          </DrawerHeader>
          <DrawerBody
            sx={{
              '&::-webkit-scrollbar': {
                width: '7px',
                borderRadius: '10px',
                backgroundColor: `rgba(0, 0, 0, 0.1)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.4)`,
                borderRadius: '10px',
              },
            }}
            py={0}
            px={{ sm: 2, md: 0 }}
            pb={{ sm: 4, md: 0 }}
            maxH={{ sm: '20%', md: '100%' }}
            mt="auto">
            {!variant ? (
              <Stack direction={'column'} spacing={2}>
                {controller.data?.length > 0 &&
                  controller.data.map((item: BikeDTO, index) => (
                    <BikeListCard
                      key={'bike-' + index}
                      itemData={item}
                      setActiveItem={() => controller.setFocusedPoint(item)}
                      url={null}
                    />
                  ))}
              </Stack>
            ) : (
              <Swiper
                breakpoints={{
                  280: {
                    direction: 'horizontal',
                    slidesPerView: 1,
                  },
                  960: {
                    direction: 'vertical',
                    slidesPerView: 3.8,
                  },
                }}
                spaceBetween={20}
                loop={false}
                mousewheel={true}
                modules={[Pagination, Mousewheel]}
                className="mySwiper">
                {controller.data?.length > 0 &&
                  controller.data.map((item: BikeDTO) => (
                    <SwiperSlide>
                      <BikeListCard
                        itemData={item}
                        setActiveItem={() => controller.setFocusedPoint(item)}
                        url={null}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </DrawerBody>

          <DrawerFooter
            m={0}
            p={1}
            pb={0}
            display={{ sm: 'none', lg: 'block' }}
            onClick={() => navigation('/download')}>
            <InviteCard isIOSDevice={isIOSDevice} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={controller.focusedPoint !== null}
        placement={variant ? 'bottom' : 'left'}
        variant={'alwaysOpen'}
        onClose={() => controller.setFocusedPoint(null)}
        size={'sm'}>
        <DrawerContent p={0}>
          <DrawerBody bgColor="gray.100" p={0}>
            <IconButton
              aria-label="Back button"
              pos={'absolute'}
              top={3}
              left={3}
              icon={<FaArrowLeft />}
              bgColor="white"
              color="gray.700"
              size="sm"
              rounded={'full'}
              onClick={() => controller.setFocusedPoint(null)}
            />
            <BikeDetail item={controller.focusedPoint} />
          </DrawerBody>

          <DrawerFooter bgColor="gray.100" justifyContent={'space-around'}>
            <Box display={{ sm: 'none', lg: 'flex' }} m={0}>
              <InviteCard isIOSDevice={isIOSDevice} />
            </Box>
            <Box display={'none'}>
              <Button
                size="sm"
                as="a"
                rounded="full"
                variant="outline"
                colorScheme={'red'}
                mr={3}
                href={'tel:' + controller.focusedPoint?.owner?.phone ?? ''}>
                Llamar
              </Button>
              <Button
                size="sm"
                as="a"
                rounded="full"
                variant="outline"
                colorScheme={'red'}
                mr={3}
                href={'mailto: ' + controller.focusedPoint?.owner.email ?? ''}>
                Enviar Correo
              </Button>
              <Button
                size="sm"
                rounded="full"
                variant="outline"
                colorScheme={'red'}
                mr={3}
                onClick={() =>
                  shareMethod(
                    controller.focusedPoint.name,
                    'Mapa de Bikleta Points'
                  )
                }>
                Compartir
              </Button>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <MapComponent
        height={'100vh'}
        zoom={controller.focusedPoint ? 18 : 13}
        center={currentPos || controller.centerPoint}
        onLoad={controller.initView}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: !variant,
          gestureHandling: 'greedy',
          fullscreenControl: false,
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.business',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'transit.station.bus',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
        points={controller.data}
        pointType={'StolenBike'}
        pointFocuser={controller.setFocusedPoint}
        extra={
          <Stack
            direction={'row'}
            w="100%"
            alignItems={'center'}
            p={'2%'}
            display={{ sm: 'flex', lg: 'none' }}>
            <Image
              borderRadius="full"
              pos="relative"
              shadow={'xl'}
              _hover={{
                cursor: 'pointer',
              }}
              onClick={() => navigation('/')}
              boxSize="5vh"
              src={require('../../../assets/Logos/logo512.png')}
              alt="App Logo"
            />
            <InputGroup w={'100%'} size="md">
              <InputRightElement>
                <IconButton
                  bg={'red.200'}
                  isLoading={
                    controller.loading || controller.refreshing || false
                  }
                  aria-label="Search"
                  size="sm"
                  fontSize={'smaller'}
                  isRound
                  onClick={() => controller.setSearchInput(searchText)}
                  icon={<SearchIcon />}
                />
              </InputRightElement>
              <Input
                rounded={'full'}
                size={'md'}
                placeholder={'Buscar...'}
                bgColor={'white'}
                onBlur={e => setSearchText(e.currentTarget.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    controller.setSearchInput(e.currentTarget.value);
                    e.currentTarget.blur();
                  }
                }}
              />
            </InputGroup>
          </Stack>
        }>
        <CurrentLocationMarker />
      </MapComponent>
    </>
  );
};

export default MapView;
