import React, { useEffect, useMemo, useState } from 'react';
import { RecoveryStep, ResetPasswordProps } from './interfaces';
import { useResetPasswordController } from './reset-password.controller';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { FormikProvider, getIn, useFormik } from 'formik';
import { FaEnvelope } from 'react-icons/fa';
import { TbCircleChevronRight } from 'react-icons/tb';
import { BiLock } from 'react-icons/bi';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import * as Yup from 'yup';

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  useController = useResetPasswordController,
}) => {
  const controller = useController();
  const [swiperController, setSwiperController] = useState(null);

  return (
    <Swiper
      className="mySwiper"
      style={{
        height: '100%',
      }}
      loop={false}
      allowTouchMove={false}
      onSwiper={setSwiperController}>
      <SwiperSlide>
        <MailInput
          controller={controller}
          swiperController={swiperController}
        />
      </SwiperSlide>
      <SwiperSlide>
        <CodeInput
          controller={controller}
          swiperController={swiperController}
        />
      </SwiperSlide>
    </Swiper>
  );
};

const MailInput: React.FC<RecoveryStep> = ({
  controller,
  swiperController,
}) => {
  useEffect(() => {
    controller.isLoading && console.log('Loading');
  }, [controller.isLoading]);

  const MailSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required('Debe ingresar un correo')
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            'Este mail contiene caracteres inválidos'
          )
          .max(150, 'El mail es muy largo'),
        // .test('validation', 'El email ingresado no es válido', validateEmail),
      }),
    []
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: MailSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values =>
      controller.recoveryEmailSend(values.email, swiperController),
  });

  return (
    <Center w="100%" h="full" overflowY={'auto'} mx="auto">
      <Flex
        alignItems="center"
        justifyContent="start"
        style={{ userSelect: 'none' }}>
        <FormikProvider value={formik}>
          <Stack
            direction="column"
            w="100%"
            h="100vh"
            justifyContent={'center'}
            p="30px"
            textAlign={'start'}
            spacing={3}>
            <Heading fontSize="1.75rem" fontFamily={'Nunito'}>
              Recuperar contraseña
            </Heading>
            <Text mb="30px" ms="4px" fontWeight="bold" fontSize="13px">
              Escribe tu correo y enviaremos un email con el código de
              recuperación
            </Text>
            {/* <Field name="email">
              {({ field }) => (
                <FormControl
                  isInvalid={getIn(formik.errors, 'email')}
                  isRequired
                  mb={5}>
                  <EmailInput
                    field={field}
                    form={formik}
                    validationState={validationState}
                    leftIcon={<FaEnvelope />}
                    inputProps={{
                      placeholder: 'Email',
                      borderRadius: 4,
                      size: 'lg',
                      fontSize: 'sm',
                    }}
                    inputGroupProps={{
                      size: 'lg',
                    }}
                  />
                  <FormErrorMessage fontSize={'small'}>
                    {formik.errors.email}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field> */}
            <FormControl
              isInvalid={getIn(formik.errors, 'email')}
              isRequired
              mb={5}>
              <InputGroup size={'lg'} variant="outline">
                <Input
                  borderRadius="4px"
                  variant="outline"
                  fontSize="sm"
                  color={'white'}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="email"
                  placeholder="Email"
                  size="lg"
                  name="email"
                  autoComplete="new-email"
                  _autofill={{
                    textFillColor: 'white',
                    boxShadow: 'transparent',
                    transition: 'background-color 5000s ease-in-out 0s',
                  }}
                />
                <InputLeftElement children={<FaEnvelope color="white" />} />
              </InputGroup>
              {getIn(formik.errors, 'email') ? (
                <FormErrorMessage fontSize={'x-small'}>
                  {getIn(formik.errors, 'email')}
                </FormErrorMessage>
              ) : null}
            </FormControl>
            <Center mb={15}>
              <Button
                isLoading={controller.isLoading}
                type="submit"
                bg="brand.50"
                rounded={'full'}
                w="65%"
                mx={'auto'}
                textColor={'black'}
                _hover={{
                  bg: 'yellow.400',
                }}
                _active={{
                  bg: 'yellow.800',
                }}
                onClick={() =>
                  formik.validateForm().then(res => {
                    Object.keys(res).length === 0 && formik.submitForm();
                  })
                }>
                Enviar
              </Button>
            </Center>
            <HStack my="4">
              <Divider />
            </HStack>
            <Stack my="4" spacing="4">
              <Button
                variant="outline"
                onClick={() => controller.signIn()}
                leftIcon={<Box as={FcGoogle} color="red.500" />}>
                Ingresar con Google
              </Button>
            </Stack>
            <Text alignSelf="center">
              Ya tienes cuenta?{' '}
              <Link
                as={ReachLink}
                fontWeight="bold"
                to="/auth/signin"
                _hover={{
                  textDecoration: 'underline',
                }}>
                Ingresar
              </Link>
            </Text>
          </Stack>
        </FormikProvider>
      </Flex>
    </Center>
  );
};

const CodeInput: React.FC<RecoveryStep> = ({
  controller,
  swiperController,
}) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const CodeSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .required('Este campo es requerido')
          .matches(/^[0-9]+$/, 'Este código contiene caracteres inválidos')
          .max(6, 'El código no es valido'),
        newPass: Yup.string()
          .required('Este campo es requerido')
          .min(8, 'La contraseña debe tener minimo 8 caracteres')
          .matches(
            /(?=.*?[a-z])/,
            'La contraseña debe contener una letra minuscula'
          )
          .matches(
            /(?=.*?[0-9])/,
            'La contraseña debe contener al menos un numero'
          )
          .matches(
            /(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/,
            'La contraseña debe contener al menos un caracter especial'
          ),
      }),
    []
  );

  const formik = useFormik<{ code: number; newPass: string }>({
    initialValues: {
      code: undefined,
      newPass: undefined,
    },
    onSubmit: ({ newPass, code }) =>
      controller.postNewPassword(code, newPass, swiperController),
    validationSchema: CodeSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  return (
    <Center w="100%" h="full" overflowY={'auto'} mx="auto">
      <Flex
        alignItems="center"
        justifyContent="start"
        style={{ userSelect: 'none' }}>
        <Stack
          direction="column"
          w="100%"
          h="100vh"
          justifyContent={'center'}
          p="30px"
          textAlign={'start'}
          spacing={3}>
          <Heading fontSize="32px" fontFamily={'Nunito'}>
            Nueva contraseña
          </Heading>
          <Text mb="30px" ms="4px" fontWeight="bold" fontSize="13px">
            Ingresa el código que enviamos a tu correo y la nueva contraseña
          </Text>
          <FormControl
            isInvalid={getIn(formik.errors, 'code')}
            isRequired
            mb={5}>
            <InputGroup size={'lg'} variant="outline">
              <Input
                borderRadius="4px"
                variant="outline"
                fontSize="sm"
                color={'white'}
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                placeholder="Inserte el código"
                size="lg"
                name="code"
                autoComplete="code"
                _autofill={{
                  textFillColor: 'white',
                  boxShadow: 'transparent',
                  transition: 'background-color 5000s ease-in-out 0s',
                }}
              />
              <InputLeftElement
                children={<TbCircleChevronRight color="white" />}
              />
            </InputGroup>
            {getIn(formik.errors, 'code') ? (
              <FormErrorMessage fontSize={'x-small'}>
                {getIn(formik.errors, 'code')}
              </FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl
            isInvalid={getIn(formik.errors, 'newPass')}
            isRequired
            mb={5}>
            <InputGroup size={'lg'} variant="outline">
              <Input
                borderRadius="4px"
                variant="outline"
                fontSize="sm"
                color={'white'}
                value={formik.values.newPass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={show ? 'text' : 'password'}
                placeholder="Nueva contraseña"
                size="lg"
                name="newPass"
                autoComplete="new-password"
                _autofill={{
                  textFillColor: 'white',
                  boxShadow: 'transparent',
                  transition: 'background-color 5000s ease-in-out 0s',
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    formik.submitForm();
                  }
                }}
              />
              <InputLeftElement children={<BiLock color="white" />} />
              <InputRightElement
                _hover={{
                  cursor: 'pointer',
                }}
                children={
                  show ? <FiEye color="white" /> : <FiEyeOff color="white" />
                }
                onClick={handleClick}
              />
            </InputGroup>
            {getIn(formik.errors, 'newPass') ? (
              <FormErrorMessage fontSize={'x-small'}>
                {getIn(formik.errors, 'newPass')}
              </FormErrorMessage>
            ) : null}
          </FormControl>
          <Center mb={15}>
            <Button
              isLoading={controller.isLoading}
              type="submit"
              bg="brand.50"
              rounded={'full'}
              w="65%"
              mx={'auto'}
              textColor={'black'}
              _hover={{
                bg: 'yellow.400',
              }}
              _active={{
                bg: 'yellow.800',
              }}
              onClick={() =>
                formik.validateForm().then(res => {
                  Object.keys(res).length === 0 && formik.submitForm();
                })
              }>
              Confirmar
            </Button>
          </Center>
          <HStack my="4">
            <Divider />
          </HStack>
          <Stack my="4" spacing="4">
            <Button
              variant="outline"
              onClick={() => controller.signIn()}
              leftIcon={<Box as={FcGoogle} color="red.500" />}>
              Ingresar con Google
            </Button>
          </Stack>
          <Text alignSelf="center">
            Ya tienes cuenta?{' '}
            <Link
              as={ReachLink}
              fontWeight="bold"
              to="/auth/signin"
              _hover={{
                textDecoration: 'underline',
              }}>
              Ingresar
            </Link>
          </Text>
        </Stack>
      </Flex>
    </Center>
  );
};
