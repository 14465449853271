import create from 'zustand';
import { BenefitsType, POInew } from '../services/bikletaPoints/dto';
import { ProfileDTO } from '../services/profile/dto';
import { devtools } from 'zustand/middleware';
import { StatsType } from '../views/Admin/Dashboard/interfaces';

interface StoreStateTypes {
  currentUser: ProfileDTO | null;
  setCurrentUser: (userData: ProfileDTO) => void;
  activePoint: POInew | null;
  setActivePoint: (point: POInew) => void;
  sessionBikletaPoints: POInew[] | null;
  setSessionBPs: (points: POInew[]) => void;
  selectedBenefits: BenefitsType | null;
  setSelectedBenefits: (benefit?: BenefitsType) => void;
  updateBikletaPoint: (point: POInew) => void;

  spendDate: Date | null;
  setSpendDate: () => void;
  sessionStats: StatsType | null;
  setSessionStatsData: (stats: StatsType) => void;
  reset: () => void;
}

export const useStore = create<StoreStateTypes>()(
  devtools(set => ({
    currentUser: null,
    setCurrentUser: userData => set({ currentUser: userData }),
    activePoint: null,
    setActivePoint: poi => set({ activePoint: poi, sessionStats: null }),
    sessionBikletaPoints: null,
    setSessionBPs: points => set({ sessionBikletaPoints: points }),
    selectedBenefits: null,
    setSelectedBenefits: benefit => set({ selectedBenefits: benefit ?? null }),
    updateBikletaPoint: point => {
      set(state => {
        const tempState = state.sessionBikletaPoints.map(item =>
          item._id === point._id ? point : item
        );

        return {
          sessionBikletaPoints: tempState,
          activePoint: point,
        };
      });
    },

    spendDate: null,
    setSpendDate: () => set({ spendDate: new Date() }),

    sessionStats: null,
    setSessionStatsData: statsData => set({ sessionStats: statsData }),
    reset: () =>
      set({
        currentUser: null,
        sessionBikletaPoints: null,
        activePoint: null,
      }),
  }))
);
