import React from 'react';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  Heading,
  Input,
  Text,
  FormErrorMessage,
  Link,
  InputGroup,
  InputRightElement,
  HStack,
  Divider,
  Stack,
  InputLeftElement,
  Center,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { FormikProvider, getIn, useFormik } from 'formik';
import { LoginViewProps } from './interfaces';
import { useLoginController } from './login.controller';
import * as Yup from 'yup';
import { Link as ReachLink } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { BikletaIcon } from '../../../components/Icons/Icons';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Correo invalido')
    .required('Debe ingresar un correo'),
  password: Yup.string().required('Debe ingresar la contraseña'),
});

const LoginView: React.FC<LoginViewProps> = props => {
  const { useController = useLoginController } = props;
  const controller = useController();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: controller.onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Stack direction={'column'} h="full" spacing={4}>
        <Center mx={'auto'} h={'15%'} alignItems={'flex-end'}>
          <BikletaIcon color={'brand.50'} h={'5em'} w={'5em'} />
        </Center>
        <Center w="100%" mx="auto">
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: 'none' }}>
            <Flex direction="column" w="100%" p="30px">
              <Heading
                color={'brand.50'}
                fontSize="32px"
                mb="10px"
                fontFamily={'Nunito'}>
                Bienvenido
              </Heading>
              <Text
                mb="30px"
                ms="4px"
                color={'white'}
                fontWeight="bold"
                fontSize="12.5px">
                Ingrese sus credenciales de administrador para su Bikleta Point
              </Text>
              <FormControl
                isInvalid={getIn(formik.errors, 'email')}
                isRequired
                mb={5}>
                <InputGroup size={'lg'} variant="outline">
                  <Input
                    borderRadius="4px"
                    variant="outline"
                    fontSize="sm"
                    color={'white'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="email"
                    placeholder="Email"
                    size="lg"
                    name="email"
                    autoComplete="new-email"
                    _autofill={{
                      textFillColor: 'white',
                      boxShadow: 'transparent',
                      transition: 'background-color 5000s ease-in-out 0s',
                    }}
                  />
                  <InputLeftElement children={<FaEnvelope color="white" />} />
                </InputGroup>
                {getIn(formik.errors, 'email') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(formik.errors, 'email')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={getIn(formik.errors, 'password')}
                isRequired
                mb={2}>
                <InputGroup size="lg" variant={'outline'}>
                  <Input
                    borderRadius="4px"
                    fontSize="sm"
                    color={'white'}
                    type={show ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="Contraseña"
                    autoComplete="new-password"
                    size="lg"
                    name="password"
                    _autofill={{
                      textFillColor: 'white',
                      boxShadow: 'transparent',
                      transition: 'background-color 5000s ease-in-out 0s',
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        controller.onSubmit(formik.values);
                      }
                    }}
                  />
                  <InputLeftElement children={<FaLock color="white" />} />
                  <InputRightElement
                    _hover={{
                      cursor: 'pointer',
                    }}
                    children={
                      show ? (
                        <FiEye color="white" />
                      ) : (
                        <FiEyeOff color="white" />
                      )
                    }
                    onClick={handleClick}
                  />
                </InputGroup>
                {getIn(formik.errors, 'password') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(formik.errors, 'password')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <Link
                as={ReachLink}
                fontWeight={'semibold'}
                to="/auth/reset-password"
                mt={1}
                mb={5}
                _hover={{
                  textDecoration: 'underline',
                }}>
                ¿Olvidaste tu contraseña?
              </Link>
              <Button
                isLoading={controller.isLoading}
                type="submit"
                bg="brand.50"
                rounded={'full'}
                w="65%"
                mx={'auto'}
                h="45"
                mb="15px"
                textColor={'black'}
                _hover={{
                  bg: 'yellow.400',
                }}
                _active={{
                  bg: 'yellow.800',
                }}
                onClick={formik.submitForm}>
                Ingresar
              </Button>
              <HStack my="2">
                <Divider />
              </HStack>
              <Stack my="4" spacing="4">
                <Button
                  variant="outline"
                  color="white"
                  onClick={() => controller.signIn()}
                  leftIcon={<Box as={FcGoogle} color="red.500" />}>
                  Ingresar con Google
                </Button>
              </Stack>
              <Text alignSelf="center" color="white">
                No tienes cuenta?{' '}
                <Link
                  as={ReachLink}
                  fontWeight="bold"
                  to="/auth/signup"
                  _hover={{
                    textDecoration: 'underline',
                  }}>
                  Registrarte
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Center>
      </Stack>
    </FormikProvider>
  );
};

export default LoginView;
