import { Box, Text, Image, Stack } from "@chakra-ui/react"
import { MockupPhone } from "../../assets"
export const MediaCard = () => {
    return (
        <Box
            id="MediaCard"
            flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
            marginTop={{ base: '-110px', md: '-200px', lg: '-260px' }}
            marginBottom={{ base: '20px', md: '0px' }}
            paddingTop={{ base: '0px', md: '20px', lg: '30px' }}
            style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                zIndex: 1,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
            }}>
            <Image style={{ height: "100%", width: "100%" }} src={MockupPhone} />
            <Stack
                paddingInline={{ base: '10px', md: '60px', lg: '100px' }}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                <Text
                    fontSize={{ base: '24px', md: '40px', lg: '40px' }}
                    textAlign={{ base: 'center', md: 'start', lg: 'start' }}
                    style={{ color: "black", marginTop: '20px', fontWeight: '600' }}>
                    Unidos para respirar  <br />
                    un mejor aire.
                </Text>
                <Text
                    textAlign={{ base: 'center', md: 'left', lg: 'start' }}
                    fontSize={{ base: '15px', md: '15px', lg: '20px' }}
                    style={{ color: "black", marginTop: '20px', fontWeight: '400' }}>
                    En Bikleta motivamos a nuestros usuarios a <br />
                    usar el medio de transporte más económico, <br />
                    sostenible y saludable:
                </Text>
                <Text
                    textAlign={{ base: 'center', md: 'left', lg: 'start' }}
                    fontSize={{ base: '15px', md: '25px', lg: '26px' }}
                    style={{ color: "black", marginTop: '20px', fontWeight: '700' }}>
                    NUESTRAS BICICLETAS
                </Text>
                <Text
                    textAlign={{ base: 'center', md: 'left', lg: 'start' }}
                    fontSize={{ base: '15px', md: '15px', lg: '20px' }}
                    style={{ color: "black", marginTop: '25px', fontWeight: '400' }}>
                    Por cada kilómetro pedaleado y acumulado ofrecemos a nuestros ciclistas beneficios increíbles para incentivarlos a contribuir
                    con un futuro mejor
                </Text>
            </Stack>
        </Box>
    )
}