import { useContext, useState } from 'react';
import { AuthContext } from '../../../providers/authContext';
import { useAuthService } from '../../../services/auth/authService';
import { RegisterController } from './interfaces';
import { useGoogleOneTapLogin, useGoogleLogin } from '@react-oauth/google';

export const useRegisterController = (): RegisterController => {
  const authService = useAuthService();
  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailNotExist, setEmailNotExist] = useState(false);

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      onSuccess(credentialResponse);
    },
    onError: () => {
      console.log('Login Failed');
    },
    cancel_on_tap_outside: false,
  });

  const getInfoFromPhoneNumber = number => {
    const sections = number.split(' ');
    if (sections.length === 1) {
      return {
        phoneNumber: sections[0],
      };
    }
    return {
      region: sections[0],
      phoneNumber: sections.slice(1, sections.length).join(''),
    };
  };

  const onSuccess = async userInfo => {
    setIsLoading(true);
    try {
      const res = await authService.externalLogin('google', {
        idToken: userInfo.credential,
        accessToken: userInfo.access_token,
      });
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsLoading(false);
        return;
      }
      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
    } catch (e: any) {
      const error = e.message;
      if (error === 'Request failed with status code 400') {
      } else {
        setEmailNotExist(true);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onFailure = res => {};

  const signIn = useGoogleLogin({
    onSuccess,
    flow: 'implicit',
  });

  const onRegisterPressed = () => {};

  const onForgotPressed = () => {};

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      const mappedData = {
        email: data.email,
        country: data?.country.label,
        firstname: data.name.split(' ')[0],
        lastname: data.name.replace(data.name.split(' ')[0], '').trim(),
        password: data.password,
        idNumber: data?.idNumber,
        phoneRegion: data?.country.label,
        name: data.name,
        phone:
          data?.phoneRegion && data?.phone ? data.phoneRegion + data.phone : '',
      };

      const res = await authService.register(mappedData);
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsLoading(false);
        return;
      }
      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
    } catch (e: any) {
      const error = e.message;
      console.log(e);

      if (error === 'Request failed with status code 400') {
      } else {
        setEmailNotExist(true);
      }
      setIsLoading(false);
    }
  };

  const onError = () => {
    // return console.log({ errors, e });
  };

  return {
    onSubmit,
    onError,
    onRegisterPressed,
    onForgotPressed,
    isLoading,
    passwordVisible,
    setPasswordVisible,
    emailNotExist,
    setEmailNotExist,
    onSuccess,
    onFailure,
    signIn,
    getInfoFromPhoneNumber,
  };
};
