import { BenefitsViewController } from './interfaces';
import { useBikletaPointsService } from '../../../services/bikletaPoints/bikletaPointsService';
import { useEffect, useState } from 'react';
import { useStore } from '../../../utils/useStore';
import { BenefitsType } from '../../../services/bikletaPoints/dto';
import { useAsync } from '../../../utils/useAsync';
import { useToast } from '@chakra-ui/react';

export const useBenefitsViewController = (): BenefitsViewController => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const bpService = useBikletaPointsService();

  const { activePoint, setActivePoint } = useStore();

  const [data, error, loading, execute, refreshing, refresh] = useAsync(
    bpService.getBenefits,
    []
  );

  useEffect(() => {
    if (activePoint) {
      execute(activePoint._id);
    }
  }, [activePoint]);

  const submitBenefitData = async (data: BenefitsType) => {
    try {
      setIsSubmitting(true);
      const res = await bpService.createBenefit(activePoint._id, data);

      setActivePoint(res);
    } catch (error) {
      console.error(error);
      throw new Error('error at submit');
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateBenefit = async (data: BenefitsType) => {
    try {
      setIsSubmitting(true);
      const res = await bpService.updateBenefit(activePoint._id, data);

      setActivePoint(res);
    } catch (error) {
      console.error(error);
      throw new Error('error at update');
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteBenefits = async (benefitsToDelete: any[]) => {
    const mappedBenefits = benefitsToDelete.map(item => item.values._id);
    try {
      setIsDeleting(true);
      await bpService.deleteBenefit(activePoint._id, mappedBenefits);

      toast({
        title: 'Beneficios Borrados',
        description: 'Se eliminaron los beneficios de tu Bikleta Point.',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });

      refresh(activePoint._id);
    } catch (error) {
      console.error(error);
      toast({
        title: 'Ocurrió un error',
        description: 'Intentelo nuevamente.',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDeleting(false);
    }

    return;
  };

  return {
    submitBenefitData,
    deleteBenefits,
    updateBenefit,
    isDeleting,
    isSubmitting,
    benefitsData: data,
  };
};
