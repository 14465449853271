import { useEffect } from 'react';
import { isIOS } from '../../utils/checkDeviceType';
import { storeLinks } from '../../utils/constants';
import { Navigate } from 'react-router-dom';

const DownloadPage = () => {
  useEffect(() => {
    const isIOSDevice = isIOS();
    window.location.assign(isIOSDevice ? storeLinks.apple : storeLinks.android);
  }, []);
  return <Navigate to='/' />;
};

export default DownloadPage;
