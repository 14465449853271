import { useContext } from 'react';
import { AxiosContext } from '../../providers/axiosContext';
import { ProfileDTO } from './dto';
export interface ProfileService {
  fetchProfile: () => Promise<ProfileDTO>;
}

export const useProfileService = (): ProfileService => {
  const axiosContext = useContext(AxiosContext);

  const fetchProfile = async () => {
    const res = await axiosContext.authAxios.get('/web/users/profile?full=true');
    //console.log(res.data);
    return res.data;
  };

  return {
    fetchProfile,
  };
};
