import { useFormik } from 'formik';
import { AddController } from './interfaces';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '@chakra-ui/react';
import { BenefitsType } from '../../../../../services/bikletaPoints/dto';
import { useBikletaPointsService } from '../../../../../services/bikletaPoints/bikletaPointsService';
import { useStore } from '../../../../../utils/useStore';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Por favor, ingresa un título')
    .min(10, 'El título no puede contener menos de 10 caractéres')
    .max(40, 'El título no puede contener más de 40 caractéres'),
  description: Yup.string().required('Por favor, ingresa una descripción'),
  status: Yup.string().required('Elija un estado'),
  startDate: Yup.string().required('Ingrese Fecha de inicio'),
  endDate: Yup.string().required('Ingresa Fecha de fin'),
  type: Yup.string().required('Por favor, selecciona un tipo'),
  benefitTag: Yup.string(),
  unlimitedQuantity: Yup.boolean(),
  availableQuantity: Yup.number()
    .nullable()
    .when('unlimitedQuantity', {
      is: val => val === false,
      then: Yup.number()
        .typeError('Debe colocar un número')
        .min(1, 'La cantidad debe ser mayor o igual a 1')
        .max(10000, 'La cantidad maxima es de 10.000')
        .required('Debe colocar una cantidad'),
      otherwise: null,
    }),
  image: Yup.object().shape({
    cover: Yup.object().shape({
      url: Yup.string().required('Debe subir una imagen del beneficio'),
    }),
    banner: Yup.object().shape({
      url: Yup.string().required(
        'Debe subir una imagen de banner del beneficio'
      ),
    }),
  }),
});

export const useAddController = (): AddController => {
  const toast = useToast();
  const { activePoint } = useStore();
  const bikletaPointsService = useBikletaPointsService();
  const navigation = useNavigate();
  const handleSubmit = async values => {
    try {
      if (values.unlimitedQuantity === true) {
        delete values.availableQuantity;
      }
      delete values.unlimitedQuantity;

      const temporalBenefit: BenefitsType = {
        ...values,
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
      };
      await bikletaPointsService.createBenefit(
        activePoint._id,
        temporalBenefit
      );
      toast({
        description: 'Formulario enviado con éxito!',
        title: 'Beneficio creado',
        position: 'top-right',
        status: 'success',
        duration: 2500,
      });
      navigation('/admin/benefits');
      return;
    } catch (error) {
      console.log(error);
      toast({
        description: 'Error al enviar el formulario',
        title: 'Ocurrió un error',
        position: 'top-right',
        status: 'error',
        duration: 2500,
      });
      return;
    }
  };

  const capitalizeFirstLetter = (str: string | undefined) => {
    if (!str || str.length === 0) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const form = useFormik({
    initialValues: {
      title: '',
      description: '',
      unlimitedQuantity: false,
      availableQuantity: null,
      status: 'active',
      type: 'discount',
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    form.errors && console.log(form.errors);
  }, [form.errors]);

  return {
    formikInstance: form,
    capitalizeFirstLetter,
  };
};
