import {
  Box,
  Stack,
  Text,
  Center,
  Icon,
  IconButton,
  Button,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { BiEnvelope, BiWorld } from 'react-icons/bi';
import { FaChevronDown, FaMapMarkerAlt, FaRegClock } from 'react-icons/fa';
import { IoMdCall, IoMdCopy, IoMdShare } from 'react-icons/io';
import { shareMethod } from '../../utils/shareMethod';
import { BikletaIcon } from '../Icons/Icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './styles.css';

interface CommerceDetailProps {
  item: any;
}

const CommerceDetail: React.FC<CommerceDetailProps> = ({ item }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Center
        h={{ sm: '20vh', md: '40%' }}
        bgSize={'contain'}
        bgRepeat={'no-repeat'}
        bgPos="center"
        bgColor={'#313149'}>
        {item?.images.length > 0 ? (
          <Swiper
            className="mySwiper"
            navigation={false}
            loop={false}
            autoplay={false}
            modules={[Navigation, Pagination, Autoplay, EffectFade]}
            pagination={true}>
            {item?.images.map((image, index) => (
              <SwiperSlide key={'slide-' + index}>
                <Stack
                  direction={'column'}
                  w="100%"
                  h="100%"
                  bgImage={image}
                  bgRepeat={'no-repeat'}
                  bgPosition={{ sm: '55%' }}
                  bgSize="cover"
                  px={3}
                  spacing={4}></Stack>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Icon
            as={BikletaIcon}
            w="100%"
            h="80%"
            pl={'10%'}
            mt="auto"
            color={'white'}
          />
        )}
      </Center>
      <Stack direction={'column'} spacing={3} w="100%" h="60%">
        <Box p={0} borderRadius="lg">
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems={'center'}
            p={4}>
            <Box>
              <Text noOfLines={1} fontSize={'lg'}>
                {item.name}
              </Text>
              <Text noOfLines={1} fontSize={'sm'} mt={2} color="gray.500">
                {item.type}
              </Text>
            </Box>
            <IconButton
              aria-label="share point"
              icon={<IoMdShare />}
              variant="ghost"
              w="fit-content"
              fontSize={'2xl'}
              fontWeight="normal"
              onClick={() =>
                shareMethod('Mira este punto Bikleta!', '?point=' + item._id)
              }
            />
          </Stack>
          <Box h={0.5} bgColor="gray.300" />
          <Stack
            display={{ sm: 'none', lg: 'flex' }}
            direction={'column'}
            mt={3}
            spacing={1}
            p={{ sm: 4, md: 0 }}
            alignItems="left"
            color="gray.600">
            <Button
              leftIcon={<FaMapMarkerAlt />}
              variant="ghost"
              w="fit-content"
              fontSize={'md'}
              fontWeight="normal"
              onClick={() => {
                navigator.clipboard.writeText(
                  //@ts-ignore
                  item.address?.addressLine || item.address
                );
              }}>
              {item.address?.addressLine || item.address}
            </Button>
            <Button
              leftIcon={<FaRegClock />}
              rightIcon={<FaChevronDown />}
              variant="ghost"
              w="fit-content"
              fontSize={'md'}
              onClick={onToggle}
              fontWeight="normal"
              hidden>
              Abierto - Cierra a las 19:30
            </Button>
            <Collapse in={isOpen} animateOpacity>
              <Box p="20px" bg="gray.100" m="1" rounded="md" shadow="md">
                <Text color="black">Horarios</Text>
              </Box>
            </Collapse>
            {item?.web && (
              <Button
                as="a"
                href={item.web}
                target="_blank"
                leftIcon={<BiWorld />}
                variant="ghost"
                w="fit-content"
                fontSize={'md'}
                fontWeight="normal">
                {item.web}
              </Button>
            )}
            {item?.phone && (
              <Button
                leftIcon={<IoMdCall />}
                variant="ghost"
                w="fit-content"
                fontSize={'md'}
                fontWeight="normal">
                {item.phone}
              </Button>
            )}
            {item?.email && (
              <Button
                as="a"
                href={'mailto:' + item.email}
                target="_blank"
                leftIcon={<BiEnvelope />}
                variant="ghost"
                w="fit-content"
                fontSize={'md'}
                fontWeight="normal">
                {item.email}
              </Button>
            )}
            <Button
              leftIcon={<IoMdCopy />}
              variant="ghost"
              w="fit-content"
              fontSize={'md'}
              fontWeight="normal"
              onClick={() => {
                navigator.clipboard.writeText(
                  item.point.lat + ', ' + item.point.lng
                );
              }}>
              {item.point.lat + ', ' + item.point.lng}
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default CommerceDetail;
