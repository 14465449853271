import { Box, Stack, Text, Image, chakra } from '@chakra-ui/react';
import { BikletaLogo, IgIcon } from '../../assets';

export const Footer = () => {
    const useIsMobile = () => {
        const isMobileDevice = typeof navigator === "undefined" ? false : (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i));
        return isMobileDevice ? true : false;
    }

    return (
        <Box
            marginBottom={{ base: '0px', md: '0' }}
            marginLeft={{ base: '0px', md: '-20px' }}
            paddingInline={{ base: '25px', md: '100px' }}
            marginTop={{ base: '-200px', md: '-145px' }}
            backgroundPosition={{ base: 'center', md: 'center' }}
            width={{ base: '100%', md: '102%' }}
            height={{ base: '250px', md: '300px' }}
            style={{
                display: 'flex',
                flex: 1,
                position: 'relative',
                zIndex: 2,
                backgroundColor: "#0d0d0d",
            }}>
            <Box
                paddingBottom={{ base: '0px', md: '30px' }}
                flex={1}
                zIndex={106}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                <Stack
                    marginLeft={{ base: '5px', md: '0px' }}
                    marginBottom={{ base: '30px', md: '20px' }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'flex-end'
                    }}>
                    <Image
                        height={{ base: '20px', md: '40px' }}
                        width={{ base: '30px', md: '70px' }}
                        src={BikletaLogo} />
                    <Text
                        fontSize={{ base: '10px', md: '20px' }}
                        style={{
                            color: "white",
                            fontWeight: '600',
                            marginLeft: '10px'
                        }}>Bikleta</Text>
                </Stack>
                {useIsMobile() ?
                    <Stack
                        marginBottom={{ base: '30px', md: '20px' }}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf: 'flex-end',
                            maxWidth: '500px'
                        }}>
                        <chakra.a
                            href={'https://www.instagram.com/bikleta_app/'}
                            _hover={{
                                color: 'gray.500',
                            }}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                            <Image style={{ width: '20px', height: '20px', alignSelf: 'center' }} src={IgIcon} />
                            <Text
                                fontSize={{ base: '8px', md: '16px' }}
                                style={{
                                    color: "white",
                                    fontWeight: '600',
                                    marginTop: '5px'
                                }}>
                                Copyright 2024. Todos los derechos reservados
                            </Text>
                        </chakra.a>
                    </Stack>
                    :
                    <>
                        <Stack
                            marginBottom={{ base: '30px', md: '20px' }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignSelf: 'flex-end',
                                maxWidth: '500px'
                            }}>
                            <Text
                                fontSize={{ base: '8px', md: '16px' }}
                                style={{
                                    color: "white",
                                    fontWeight: '600'
                                }}>
                                Copyright 2024. Todos los derechos reservados
                            </Text>
                        </Stack>
                        <Stack
                            marginBottom={{ base: '30px', md: '20px' }}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                alignSelf: 'flex-end',
                                maxWidth: '500px'
                            }}>
                            <chakra.a
                                href={'https://www.instagram.com/bikleta_app/'}
                                _hover={{
                                    color: 'gray.500',
                                }}
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                            >
                                <Image src={IgIcon} />
                                <Text
                                    fontSize={{ base: '8px', md: '16px' }}
                                    style={{
                                        marginLeft: '10px',
                                        color: "white",
                                        fontWeight: '600',
                                        marginBottom: '5px'
                                    }}>
                                    @bikleta_app
                                </Text>
                            </chakra.a>
                        </Stack>
                    </>
                }
            </Box>
        </Box>
    )
}