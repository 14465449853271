export const shareMethod = (title: string, urlParams?: any) => {
  const shareDetails = {
    url: window.location.href + urlParams,
    title,
  };
  if (navigator.share) {
    try {
      navigator.share(shareDetails);
    } catch (error) {
      console.log(`Error sharinng: ${error}`);
    }
  } else {
    // fallback code
    console.log('Web share is currently not supported on this browser.');
  }
};