export const headingStyles = {
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'Roboto, Nunito',
      },
    },
  },
};

export const headingAdminStyles = {
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'Nunito, Roboto',
      },
    },
  },
};
