import {
  Box,
  VStack,
  Grid,
  Image,
  Center,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchErrorImage } from '../../assets';

function ErrorView({
  errorTitle = 'Ups! Algo salió mal',
  errorMessage = 'Al parecer ocurrió un error',
  returnToHome = true,
  returnToUrl = undefined,
  returnMessage = 'Volver a Inicio',
}) {
  const location = useNavigate();
  return (
    <Box textAlign="center" fontSize="xl" bgColor={'yellow.400'}>
      <Grid minH="100vh" p={3}>
        <VStack spacing={8}>
          <Center pt={'16vh'} pb={'2%'}>
            <Image src={SearchErrorImage} h="12rem" />
          </Center>
          <Heading>{errorTitle}</Heading>
          <Text px={{ sm: '1vw', md: '15vw' }}>{errorMessage}</Text>
          {(returnToHome || returnToUrl) && (
            <Button
              onClick={() =>
                returnToHome
                  ? location('/')
                  : (window.location.href = returnToUrl)
              }>
              {returnMessage}
            </Button>
          )}
        </VStack>
      </Grid>
    </Box>
  );
}

export default ErrorView;
