import { useContext, useState } from 'react';
import { AuthContext } from '../../../providers/authContext';
import { useAuthService } from '../../../services/auth/authService';
import { LoginController } from './interfaces';
import { useGoogleOneTapLogin, useGoogleLogin } from '@react-oauth/google';
import { useToast } from '@chakra-ui/react';

export const useLoginController = (): LoginController => {
  const authService = useAuthService();
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailNotExist, setEmailNotExist] = useState(false);
  const toast = useToast();

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      onSuccess(credentialResponse);
    },
    onError: () => {
      console.log('Login rejected by user');
    },
    cancel_on_tap_outside: false,
  });

  const onRegisterPressed = () => {};

  const onForgotPressed = () => {};

  const onSuccess = async userInfo => {
    setIsLoading(true);
    try {
      console.log({
        idToken: userInfo.credential,
        accessToken: userInfo.access_token,
      });
      const res = await authService.externalLogin('google', {
        idToken: userInfo.credential,
        accessToken: userInfo.access_token,
      });
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsLoading(false);
        alert('Ocurrio un error');
        return;
      }
      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
    } catch (e: any) {
      const error = e.message;
      if (error === 'Request failed with status code 400') {
      } else {
        setEmailNotExist(true);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onFailure = res => {
    console.error('Error: ', res);
  };

  const signIn = useGoogleLogin({
    onSuccess,
    flow: 'implicit',
  });

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      const cleanEmail = data.email.trim();
      const res = await authService.login(cleanEmail, data.password);
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsLoading(false);
        return;
      }
      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
    } catch (e: any) {
      if (e?.response?.status === 400) {
        toast({
          position: 'top-left',
          status: 'error',
          title: 'Ocurrió un error',
          description: 'Las credenciales de usuario no son correctas',
        });
      } else {
        toast({
          position: 'top-left',
          status: 'error',
          title: 'Ocurrió un error',
          description: 'Vuelva a intentar más tarde',
        });
        setEmailNotExist(true);
      }
      setIsLoading(false);
    }
  };

  const onError = () => {
    // return console.log({ errors, e });
  };

  return {
    onSubmit,
    onError,
    onRegisterPressed,
    onForgotPressed,
    isLoading,
    passwordVisible,
    setPasswordVisible,
    emailNotExist,
    setEmailNotExist,
    onSuccess,
    onFailure,
    signIn,
  };
};
