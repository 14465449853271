import { isMobile } from 'react-device-detect';

export function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel',
      'Macintosh',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isMobileDevice(): boolean {
  const { innerWidth, innerHeight } = window;
  const isMobileDevice = isMobile || innerWidth <= 768 || innerHeight <= 576;
  return isMobileDevice;
}
