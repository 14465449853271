import {
  Box,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
  TextProps,
} from '@chakra-ui/react';
import React from 'react';

interface AtomicCardProps {
  image: string;
  title: string;
  subtitle1: string;
  subtitle2: string;
  _text?: TextProps;
}

const AtomicCard: React.FC<AtomicCardProps> = ({
  image,
  title,
  subtitle1,
  subtitle2,
  _text,
  ...props
}) => {
  return (
    <Box>
      <Box px={2}>
        <Stack direction={'row'} spacing={6}>
          <Box alignItems={'center'} justifyContent={'center'} display={'flex'}>
            <Image src={image} alt="CheckImage" w={'24px'} h={'24px'} />
          </Box>
          <Box>
            <Text fontSize={16} fontWeight={'800'} color={'#FDFDFC'} {..._text}>
              {title}
            </Text>
            <Text fontSize={14} fontWeight={'700'} color={'#D8D7E2'} {..._text}>
              {subtitle1}
            </Text>
            <Text fontSize={14} fontWeight={'400'} color={'#9F9DB0'}>
              {subtitle2}
            </Text>
          </Box>
        </Stack>
      </Box>
      <Box mt={3}>
        <Divider border={'1px'} />
      </Box>
    </Box>
  );
};

export default AtomicCard;
