import React from 'react';
import { Box, VStack, Grid, Image, Center, Spinner } from '@chakra-ui/react';
import { LogoBikleta } from '../../assets';

function LoadingPage() {
  return (
    <Box textAlign="center" fontSize="xl" bgColor={'yellow.400'} h="100vh">
      <Grid minH="100%" p={3}>
        <VStack height={'100%'} spacing={8}>
          <Center pt={'30vh'} pb={'2%'}>
            <Image src={LogoBikleta} alt="Bikleta" height={'15vh'} />
          </Center>
          <Spinner size="xl" color="black" />
        </VStack>
      </Grid>
    </Box>
  );
}

export default LoadingPage;
