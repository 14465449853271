import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

function TimelineRow(props) {
  const { logo, title, color, isLast, bgIconColor, mb, boldLabel } = props;
  const textColor = useColorModeValue('gray.700', 'white.300');

  return (
    <Flex
      alignItems="center"
      h={isLast ? 10 : '100%'}
      justifyContent="start"
      mb={mb || 2}>
      <Flex direction="column" h="100%">
        <Icon
          as={logo}
          bg={bgIconColor || 'transparent'}
          color={color}
          boxSize={5}
          pe="6px"
          zIndex="1"
          position="relative"
          ml={0.5}
          right={document.documentElement.dir === 'rtl' ? '-8px' : ''}
          left={document.documentElement.dir === 'rtl' ? '' : '-8px'}
        />
        <Box
          w="2px"
          bg={'gray.400'}
          borderRadius="full"
          mb={-1.5}
          h={isLast ? 0 : '100%'}></Box>
      </Flex>
      <Flex direction="column" justifyContent="flex-start" h="100%" mt="1%">
        <Text
          fontSize="sm"
          color={textColor}
          fontWeight={boldLabel ? 'bold' : 'normal'}>
          {title}
        </Text>
      </Flex>
    </Flex>
  );
}

export default TimelineRow;
