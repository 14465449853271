import React from 'react';
import {
  Box,
  Button,
  Divider,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

import { benefitsImage } from '../../resources/images/index';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { colors } from '../../utils/colors';

export const BenefitModal = ({
  isOpen,
  onClose,
  modalData,
  spendDate,
  setSpendDate,
}) => {
  let modalTitle = '';
  let modalIcon = '';
  if (modalData && modalData) {
    console.log('modaldata:', modalData);
    switch (modalData.status) {
      case 'active':
        modalTitle = 'Código válido';
        modalIcon = benefitsImage.successCheck;
        break;
      case 'used':
        modalTitle = 'Código duplicado';
        modalIcon = benefitsImage.exclamationCheck;
        break;
      case 'expired':
        modalTitle = 'Código invalidado';
        modalIcon = benefitsImage.errorCheck;
        break;
      default:
        modalTitle = 'Código inválido';
        modalIcon = benefitsImage.errorCheck;
        break;
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius={12}
          backgroundColor={'#4D4B62'}
          boxShadow="none">
          <ModalHeader textAlign={'center'}>{modalTitle}</ModalHeader>
          <Box w={'full'} display={'flex'} justifyContent={'center'}>
            <Divider mb={6} bgColor={'#6B6980'} borderWidth={'1px'} w={'80%'} />
          </Box>
          <ModalCloseButton />
          <ModalBody>
            {modalData && (
              <Box>
                <Stack direction={'row'} spacing={4}>
                  <Box ml={6}>
                    <Image src={modalIcon} />
                  </Box>
                  <Box>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'20px'}>
                      {modalData?.code
                        ? modalData?.code
                        : 'El código ingresado no existe'}
                    </Text>
                    <Text
                      fontSize={'14px'}
                      fontWeight={'500'}
                      lineHeight={'20px'}>
                      {format(new Date(spendDate), 'dd MMMM yyyy, k:mm', {
                        locale: es,
                      })}
                    </Text>
                  </Box>
                </Stack>
              </Box>
            )}
          </ModalBody>

          <ModalFooter justifyContent={'center'}>
            <Button
              backgroundColor={'#FFC700'}
              fontSize={14}
              fontWeight={'500'}
              color={'#363449'}
              borderRadius={100}
              p={'8px 32px'}
              size={'sm'}
              _hover={{ bgColor: '#FFD84E' }}
              _focus={{ bgColor: '#FFED90' }}
              onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
