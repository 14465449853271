import React from 'react';
import { Box, Image, chakra, Text, Stack } from '@chakra-ui/react';
import { BenefitsType } from '../../services/bikletaPoints/dto';
import { typeOptions } from '../../utils/constants';
import { colors } from '../../utils/colors';
import { EmptyStateBenefit } from '../../assets';

export interface BenefitsCardPreviewProps {
  imageURL?: string | any;
  formikContext: any;
  benefitObject: Partial<BenefitsType>;
}

const BenefitPreviewer: React.FC<BenefitsCardPreviewProps> = ({
  imageURL,
  formikContext,
  benefitObject,
}) => {
  const { description, endDate, title, type, startDate, image } = benefitObject;

  const typeParsed =
    typeOptions.find(option => option.value === type)?.label ??
    'Tipo de beneficio';

  const dates =
    endDate?.toString().length > 2 && startDate?.toString().length > 2
      ? `Desde ${startDate} hasta ${endDate}`
      : 'Desde 1999/01/01 Hasta 2000/01/01';

  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      overflow={'hidden'}
      borderRadius="16px">
      <Box
        h={'590px'}
        mx="auto"
        w={'366px'}
        borderRadius={'16px'}
        // rounded={'3xl'}
        pos="relative"
        shadow="md"
        bg="white"
        _dark={{
          bg: colors.purple[725],
        }}
        maxW="md">
        <Box
          h={'50%'}
          display={'flex'}
          justifyContent={'center'}
          bgColor={'#86849A'}
          borderRadius={'16px'}>
          {image?.cover?.url ? (
            <Image
              borderRadius={'16px'}
              objectFit={'cover'}
              src={image?.cover.url}
              alt="Article"
            />
          ) : (
            <Stack
              direction={'column'}
              spacing={4}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}>
              <Box>
                <Image
                  borderRadius={'16px'}
                  objectFit={'none'}
                  src={EmptyStateBenefit}
                  alt="Article"
                />
              </Box>
              <Box w={'70%'}>
                <Text
                  color={'#D8D7E2'}
                  fontSize={20}
                  fontWeight={'800'}
                  lineHeight={'24px'}
                  textAlign={'center'}>
                  Todavía no cargaste ninguna foto
                </Text>
              </Box>
            </Stack>
          )}
        </Box>

        <Box
          pos={'absolute'}
          top={5}
          right={0}
          px={3}
          py={1}
          bgColor={benefitObject?.benefitTag ? colors.accent.main : '#D8D7E2'}
          borderTopLeftRadius={'full'}
          borderBottomLeftRadius={'full'}>
          <Text textColor={'black'} fontSize={'small'}>
            {benefitObject?.benefitTag ?? 'Etiqueta'}
          </Text>
        </Box>

        <Box p={4}>
          <Box px={2}>
            <Text
              display="block"
              noOfLines={2}
              color="#FDFDFC"
              fontWeight="700"
              fontSize={20}
              lineHeight={'24px'}
              mt={2}>
              {title?.length > 1 ? title : 'Título de tu beneficio'}
            </Text>
            <chakra.span
              fontSize="16px"
              fontWeight={'700'}
              lineHeight={'20px'}
              color="#FDFDFC"
              _dark={{
                color: '#FDFDFC',
              }}>
              {typeParsed}
            </chakra.span>
            <chakra.p
              fontSize={'15px'}
              lineHeight={'20px'}
              fontWeight={'400'}
              color="#FDFDFC"
              _dark={{
                color: '#FDFDFC',
              }}>
              {dates}
            </chakra.p>
            <chakra.p
              mt={5}
              noOfLines={5}
              fontSize="15px"
              fontWeight="400"
              lineHeight={'20px'}
              color="#FDFDFC"
              _dark={{
                color: '#FDFDFC',
              }}>
              {description?.length > 1
                ? description
                : '¡Oferta imperdible! Ahorra un 10% en todas tus compras. Descubre una amplia selección de bicicletas de alta calidad y accesorios de primer nivel. Visítanos hoy y aprovecha este descuento exclusivo. ¡Pedalea hacia grandes ahorros!'}
            </chakra.p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BenefitPreviewer;
