import { HStack, Text, Box, Image } from '@chakra-ui/react';
import { MidelBackground, BrandGroups } from "../../assets"

export const MidelFooter = () => {
    return (
        <HStack
            height={{ base: '300px', md: '500px' }}
            marginTop={{ base: '0', md: '-250px' }}
            minHeight={{ md: "250px" }}
            justifyContent= {{base:'center', md:'center', lg:'center'}}
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                position: 'relative',
                zIndex: 1,
            }}
        >
            <Image style={{ position: "absolute", zIndex: 99, top: 0, left: 0, right: 0, bottom: 0, width: "100%", minHeight: "250px" }} src={MidelBackground} />
            <Text
                fontSize={{ base: '50px', md: '70px', lg: '100px' }}
                style={{ fontWeight: '800', color: 'white', zIndex: 100 }}
            >
                +400
            </Text>
            <Text fontSize={{ base: '15px', md: '20px', lg: '25px' }} style={{ fontWeight: '500', color: 'white', zIndex: 100 }}>
                comercios se suman a tu pedaleo
            </Text>
        </HStack>
    )
}

// const animationVariants = {
//     initial: {
//         opacity: 1,
//         x: '-100vw',
//     },
//     animate: {
//         opacity: 1,
//         x: '100vw',
//         transition: { duration: 30 },
//         repeat: 4,
//     },
//     exit: {
//         opacity: 0,
//         x: '100vw', // Adjust for exit to left or right
//     },
// };

const ImageSlider = () => {
    return (
        // <motion.div
        //     variants={animationVariants}
        //     animate={animationVariants.animate}
        //     initial={animationVariants.initial}
        //     layout
        // >
        <Box
            zIndex={101}
            paddingInline={{ base: '20px', md: 0, lg: 0 }}
            position="relative"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Image
                width={{ base: 'auto', md: '400px', lg: '1300px' }}
                style={{ marginTop: '40px' }}
                src={BrandGroups} alt="Logos de marcas"
                animation={'enter-animation 0.5s ease-in-out forwards'}
            />
        </Box>
        // </motion.div>
    )
}