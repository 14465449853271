import { Badge, Text } from '@chakra-ui/react';
import React from 'react';
import CustomTable from '../../../components/Admin/Table';
import { BenefitStates, BenefitTypes } from '../../../utils/constants';
import { useBenefitsViewController } from './benefits.controller';
import { BenefitsViewProps } from './interfaces';
import { useStore } from '../../../utils/useStore';
import { format } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useNavigate } from 'react-router-dom';

const BenefitsView: React.FC<BenefitsViewProps> = ({
  useController = useBenefitsViewController,
}) => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        accessor: '_id',
      },
      {
        Header: 'Beneficio',
        width: '5vw',
        accessor: 'description',
        Cell: ({
          row: {
            original: { description },
          },
        }) => (
          <Text maxW={'18vw'} isTruncated aria-label={description}>
            {description}
          </Text>
        ),
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({
          row: {
            original: { status },
          },
        }) => {
          const stateItem = BenefitStates.find(item => item.value === status);

          return (
            <Badge colorScheme={stateItem.color} borderRadius="5px">
              {stateItem.label}
            </Badge>
          );
        },
      },
      {
        Header: 'Vigencia (desde)',
        accessor: 'startDate',
        Cell: ({
          row: {
            original: { startDate },
          },
        }) => {
          const fixedDate = startDate.substring(0, startDate.length - 1);
          return <Text>{format(new Date(fixedDate), 'dd MMM yyyy')}</Text>;
        },
      },
      {
        Header: 'Vigencia (hasta)',
        accessor: 'endDate',
        Cell: ({
          row: {
            original: { endDate },
          },
        }) => {
          const fixedDate = endDate.substring(0, endDate.length - 1);
          return <Text>{format(new Date(fixedDate), 'dd MMM yyyy')}</Text>;
        },
      },
      {
        Header: 'Tipo',
        accessor: 'type',
        Cell: ({
          row: {
            original: { type },
          },
        }) => {
          const itemType = BenefitTypes.find(item => item.value === type);

          return <Text>{itemType.label}</Text>;
        },
      },
    ],
    []
  );

  const { setSelectedBenefits } = useStore();

  const controller = useController();

  const editBenefitMode = benefitData => {
    const benefitObj = controller.benefitsData.find(
      data => data._id === benefitData._id
    );
    setSelectedBenefits(benefitObj);
    navigate('edit');
  };

  return (
    <>
      <CustomTable
        columns={columns}
        data={controller.benefitsData}
        initialHiddenColumns={['_id']}
        openAdd={() => navigate('add')}
        openEdit={editBenefitMode}
        deleteAction={{
          warningLabel: 'beneficio',
          method: controller.deleteBenefits,
          isDeleting: controller.isDeleting,
        }}
      />
    </>
  );
};

export default BenefitsView;
