import { useBikletaPointsService } from '../../../services/bikletaPoints/bikletaPointsService';
import { useStore } from '../../../utils/useStore';
import { ServiceViewController } from './interfaces';

const useServicesViewController = (): ServiceViewController => {
  const bpService = useBikletaPointsService();

  const { activePoint, setActivePoint, updateBikletaPoint } = useStore();

  const setServiceState = async (serviceType: string, isActive) => {
    try {
      const res = await bpService.updateService(activePoint._id, {
        serviceType,
        isActive,
      });

      setActivePoint(res.data)
      updateBikletaPoint(res.data)
    } catch (error) {
      console.error(error);
    }
  };

  return {
    setServiceState,
  };
};

export default useServicesViewController;
