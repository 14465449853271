import { useCallback, useContext, useState } from 'react';
import { useAuthService } from '../../../services/auth/authService';
import { ResetPasswordController } from './interfaces';
import Swiper from 'swiper';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../../../providers/authContext';
import { sleep } from '../../../utils/sleep';

export const useResetPasswordController = (): ResetPasswordController => {
  const [email, setEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const authService = useAuthService();
  const authContext = useContext(AuthContext);

  const navigation = useNavigate();

  const onSuccess = async userInfo => {
    setIsLoading(true);
    try {
      const res = await authService.externalLogin('google', {
        idToken: userInfo.credential,
        accessToken: userInfo.access_token,
      });
      if (!res.data.accessToken || !res.data.refreshToken) {
        setIsLoading(false);
        return;
      }
      await authContext.login(
        res.data.accessToken,
        res.data.refreshToken,
        res.data.userId
      );
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const recoveryEmailSend = async (
    emailPassed: string,
    swiperController: Swiper
  ) => {
    setIsLoading(true);
    try {
      await authService.forgotPassword(emailPassed.trim());
      toast({
        status: 'success',
        title: 'Correo enviado',
        description:
          'Se envio un correo con el código de recuperacion a ' +
          emailPassed.trim(),
        position: 'top-right',
      });
      swiperController.slideNext();
    } catch (e) {
      console.log(e);
      toast({
        status: 'error',
        title: 'Ocurrió un error',
        description: 'No se pudo enviar el correo. Intentelo nuevamente.',
        position: 'top-right',
      });
    } finally {
      await sleep(200);
      setIsLoading(false);
      setEmail(emailPassed);
    }
  };

  const postNewPassword = async (
    code: number,
    password: string,
    swiperController: Swiper
  ) => {
    setIsLoading(true);
    try {
      await authService.changePassword(Number(code), password, email);
      toast({
        status: 'success',
        title: 'Contraseña actualizada',
        description: 'Se actualizó la contraseña exitosamente',
        position: 'top-left',
      });
      navigation('/auth');
    } catch (e: any) {
      if (e.response.status === 403) {
        toast({
          status: 'error',
          title: 'Ocurrió un error',
          description:
            'No se pudo enviar el correo. Verifique el código de seguridad.',
          position: 'top-right',
        });
      } else {
        toast({
          status: 'error',
          title: 'Ocurrió un error',
          description:
            'No se pudo enviar el correo. Intente la recuperación nuevamente.',
          position: 'top-right',
        });
        swiperController.slideTo(0);
      }
    } finally {
      await sleep(200);
      setIsLoading(false);
    }
  };

  const passwordChecker = useCallback((password: string) => {
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/;
    const lowercasePassword = lowercaseRegExp.test(password);
    const digitsPassword = digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    if (!lowercasePassword) {
      return 'screens.register.inputs.password.errors.lowercaseRequired';
    } else if (!digitsPassword) {
      return 'screens.register.inputs.password.errors.numberRequired';
    } else if (!specialCharPassword) {
      return 'screens.register.inputs.password.errors.specialCharacterRequired';
    }
    return '';
  }, []);

  const signIn = useGoogleLogin({
    onSuccess,
    flow: 'implicit',
  });

  return {
    example: 'example',
    recoveryEmailSend,
    postNewPassword,
    passwordChecker,
    isLoading,
    signIn,
  };
};
