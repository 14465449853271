import { OverlayView } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';
import { StatusIndicator } from '../StatusIndicator';
import { Flex } from '@chakra-ui/react';

interface UserLocationProps {
  lat: number;
  lng: number;
}

const centerDef = {
  lat: -31.417,
  lng: -64.183,
};

const CurrentLocationMarker = () => {
  const [userLocation, setUserLocation] = useState<UserLocationProps>(null);

  useEffect(() => {
    //console.log('user Location: ', userLocation);

    if (!userLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(res =>
          setUserLocation({
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          })
        );
      } else {
        setUserLocation(centerDef);
      }
    }
  }, [userLocation]);

  return (
    userLocation && (
      <OverlayView
        position={{
          lat: userLocation.lat,
          lng: userLocation.lng,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <Flex justifyContent="center" alignItems="center" w="full">
          <StatusIndicator color="yellow.400" w={17.5} h={17.5} />
        </Flex>
      </OverlayView>
    )
  );
};

export default CurrentLocationMarker;
