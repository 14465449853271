import { Marker } from '@react-google-maps/api';
import { PointMarkers } from '../../assets';

interface CustomPointMarkerProps {
  item: any;
  clusterer?: any;
  type: 'POI' | 'StolenBike';
  setFocused: (item: any) => void;
}

const CustomPointMarker: React.FC<CustomPointMarkerProps> = ({
  clusterer,
  item,
  type,
  setFocused,
}) => {
  const locationObj = type === 'POI' ? item.point : item.status.meta.location;

  return (
    <Marker
      clusterer={clusterer}
      position={{
        lat: locationObj.lat,
        lng: locationObj.lng,
      }}
      icon={PointMarkers[type]}
      onClick={() => {
        setFocused(item);
      }}
    />
  );
};

export default CustomPointMarker;
