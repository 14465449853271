import { useFormik } from 'formik';
import { SpendController } from './interfaces';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useBikletaPointsService } from '../../../../../services/bikletaPoints/bikletaPointsService';
import { useStore } from '../../../../../utils/useStore';
import { useBenefitHistoryStore } from '../../../../../services/benefitsService/benefit.store';

const codeValidator = Yup.object().shape({
  code: Yup.string()
    .min(6, 'El codigo no debe ser menor a 6 caracteres')
    .required('El codigo es requerido'),
});

export const useSpendController = (): SpendController => {
  const [checkStatus, setCheckStatus] = useState();
  const bpService = useBikletaPointsService();
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { spendDate, setSpendDate } = useStore();

  const { pushCoupon } = useBenefitHistoryStore();

  const handleSubmit = async values => {
    try {
      const data = await bpService.checkCoupon(values.code.toUpperCase());
      if (data?.alreadyUsed) {
        console.log('duplicated');
        pushCoupon({
          ...data,
          status: 'duplicated',
          consumedDate: new Date(),
        });
        setModalData(data);
      } else {
        console.log('successful');
        pushCoupon({
          ...data,
          status: 'successful',
          consumedDate: new Date(),
        });
        setModalData({ ...data, status: 'active' });
      }
      setSpendDate();
      setIsModalOpen(true);
      formik.resetForm();
    } catch (error) {
      console.log('error');
      pushCoupon({
        status: 'invalid',
        consumedDate: new Date(),
        code: values.code.toUpperCase(),
      });

      setModalData({ status: 'error' });
      setSpendDate();
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formik = useFormik({
    initialValues: { code: '' },

    onSubmit: async values => {
      console.log('submit');
      await handleSubmit(values);
    },
    validationSchema: codeValidator,
  });

  useEffect(() => {
    if (formik.values.code.length === 6) {
      formik.submitForm();
    }
  }, [formik.values]);

  return {
    formikInstance: formik,
    checkStatus,
    setCheckStatus,
    openModal: setIsModalOpen,
    closeModal,
    modalData,
    isModalOpen,
    spendDate,
    setSpendDate,
  };
};
