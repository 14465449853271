import React from 'react';
import { EditProps } from './interfaces';
import { useEditController } from './edit.controller';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  Stack,
  IconButton,
  Divider,
  FormControl,
  Input,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  NumberInput,
  NumberInputField,
  FormLabel,
  Center,
  Button,
  Select,
  Checkbox,
} from '@chakra-ui/react';
import { FilePond, registerPlugin } from 'react-filepond';
import { FormikProvider, getIn, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import BenefitPreviewer from '../../../../../components/BenefitPreviewer';
import {
  stateOptions as statusOptions,
  typeOptions,
} from '../../../../../utils/constants';
import { colors } from '../../../../../utils/colors';
import { useBikletaPointsService } from '../../../../../services/bikletaPoints/bikletaPointsService';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'filepond/dist/filepond.min.css';
import format from 'date-fns/format';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

export const EditView: React.FC<EditProps> = ({
  useController = useEditController,
}) => {
  const controller = useController();

  const bikletaPointService = useBikletaPointsService();

  const parse = val => {
    const percetange = val.replace('%', '');
    const finalpercetange = Math.max(
      0,
      Math.min(100, Number(percetange))
    ).toString();
    return finalpercetange + '%';
  };

  const navigate = useNavigate();

  return (
    <FormikProvider value={controller.formikInstance}>
      <Stack direction={'row'} alignItems={'center'} width={'100%'} px={2}>
        <IconButton
          icon={<ArrowBackIcon boxSize={'1.5rem'} />}
          aria-label="backIconButton"
          color={colors.gray[0]}
          onClick={() => navigate('/admin/benefits')}
          variant={'ghost'}
        />
        <Stack direction={'column'} w={'100%'}>
          <Box>
            <Text fontSize={24} fontWeight={'500'} color={colors.gray[0]}>
              Editar beneficio
            </Text>
          </Box>
        </Stack>
      </Stack>
      <Box px={3}>
        <Divider backgroundColor={'white'} mt={2} mb={6} w={'100%'} />
      </Box>
      <Stack
        direction={{
          lg: 'row',
          sm: 'column',
        }}
        spacing={'10'}
        h={['67vh', 'auto', 'auto']}
        w={'100%'}
        px={6}>
        <Stack
          direction={'column'}
          px={[28, 0, 0]}
          w={['auto', '50%', '100%']}
          spacing={5}>
          <FormControl
            isInvalid={getIn(controller.formikInstance.errors, 'title')}
            isRequired
            variant={'floating'}>
            <Field name="title">
              {({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Título"
                  size={'md'}
                  value={controller.capitalizeFirstLetter(field?.value)}
                />
              )}
            </Field>
            <FormLabel>Titulo</FormLabel>

            {getIn(controller.formikInstance.errors, 'title') ? (
              <FormErrorMessage>
                {getIn(controller.formikInstance.errors, 'title')}
              </FormErrorMessage>
            ) : (
              <FormHelperText userSelect={'none'} color={'transparent'}>
                Por favor, ingresa un titulo
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            isInvalid={getIn(controller.formikInstance.errors, 'description')}
            isRequired
            variant={'floating'}>
            <Field name="description">
              {({ field }) => (
                <Textarea
                  {...field}
                  resize={'none'}
                  type="text"
                  placeholder="Descripción del beneficio"
                  height={'20px'}
                  value={controller.capitalizeFirstLetter(field?.value)}
                />
              )}
            </Field>
            <FormLabel>Descripción del beneficio</FormLabel>
            {getIn(controller.formikInstance.errors, 'description') ? (
              <FormErrorMessage>
                {getIn(controller.formikInstance.errors, 'description')}
              </FormErrorMessage>
            ) : (
              <FormHelperText userSelect={'none'} color={'transparent'}>
                Por favor, ingresa una descripción
              </FormHelperText>
            )}
          </FormControl>
          <Stack
            direction={{
              lg: 'row',
              md: 'column',
              sm: 'column',
            }}
            spacing={4}>
            <FormControl
              isInvalid={getIn(controller.formikInstance.errors, 'status')}
              isRequired
              variant={'floating'}>
              <Field name="status">
                {({ field }) => (
                  <Select {...field} placeholder="Estado" size={'md'}>
                    {statusOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
              <FormLabel>Estado</FormLabel>
              {getIn(controller.formikInstance.errors, 'status') ? (
                <FormErrorMessage noOfLines={1}>
                  {getIn(controller.formikInstance.errors, 'status')}
                </FormErrorMessage>
              ) : (
                <FormHelperText userSelect={'none'} color={'transparent'}>
                  Elige un estado
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              isInvalid={getIn(controller.formikInstance.errors, 'startDate')}
              isRequired>
              <Field name="startDate">
                {({ field }) => (
                  <Input
                    {...field}
                    type="date"
                    placeholder="Fecha inicio"
                    size={'md'}
                    min={format(new Date(), 'yyyy-MM-dd')}
                  />
                )}
              </Field>
              {getIn(controller.formikInstance.errors, 'startDate') ? (
                <FormErrorMessage>
                  {getIn(controller.formikInstance.errors, 'startDate')}
                </FormErrorMessage>
              ) : (
                <FormHelperText userSelect={'none'} color={'transparent'}>
                  Ingrese Fecha de inicio
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              isInvalid={getIn(controller.formikInstance.errors, 'endDate')}
              isRequired>
              <Field name="endDate">
                {({ field }) => (
                  <Input
                    {...field}
                    type="date"
                    placeholder="Fecha fin"
                    size={'md'}
                    min={controller.formikInstance.values?.startDate}
                  />
                )}
              </Field>
              {getIn(controller.formikInstance.errors, 'endDate') ? (
                <FormErrorMessage>
                  {getIn(controller.formikInstance.errors, 'endDate')}
                </FormErrorMessage>
              ) : (
                <FormHelperText userSelect={'none'} color={'transparent'}>
                  Ingrese Fecha de fin
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Stack
            direction={{
              lg: 'row',
              md: 'column',
              sm: 'column',
            }}
            spacing={4}>
            <FormControl
              isInvalid={getIn(controller.formikInstance.errors, 'type')}
              isRequired
              variant={'floating'}>
              <Field name="type">
                {({ field }) => (
                  <Select size={'md'} {...field} placeholder="Tipo">
                    {typeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
              <FormLabel>Tipo</FormLabel>
              {getIn(controller.formikInstance.errors, 'type') ? (
                <FormErrorMessage>
                  {getIn(controller.formikInstance.errors, 'type')}
                </FormErrorMessage>
              ) : (
                <FormHelperText userSelect={'none'} color={'transparent'}>
                  Por favor, selecciona un tipo
                </FormHelperText>
              )}
            </FormControl>

            {controller.formikInstance.values.type === 'discount' && (
              <FormControl
                isInvalid={getIn(
                  controller.formikInstance.errors,
                  'benefitTag'
                )}
                isRequired
                variant={'floating'}>
                <Field name="benefitTag">
                  {({ field }) => (
                    <NumberInput
                      {...field}
                      onChange={value => {
                        controller.formikInstance.setFieldValue(
                          'benefitTag',
                          parse(value)
                        );
                      }}>
                      <NumberInputField {...field} size={'md'} />
                    </NumberInput>
                  )}
                </Field>
                <FormLabel>Cantidad</FormLabel>
                {getIn(controller.formikInstance.errors, 'benefitTag') ? (
                  <FormErrorMessage>
                    {getIn(controller.formikInstance.errors, 'benefitTag')}
                  </FormErrorMessage>
                ) : (
                  <FormHelperText userSelect={'none'} color={'transparent'}>
                    Por favor, indique un descuento
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {controller.formikInstance.values.type === 'promo' && (
              <FormControl
                isInvalid={getIn(
                  controller.formikInstance.errors,
                  'benefitTag'
                )}
                isRequired
                variant={'floating'}>
                <Field name="benefitTag">
                  {({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="Promocion: ej. 2x1"
                      size={'md'}
                    />
                  )}
                </Field>
                <FormLabel>Cantidad</FormLabel>

                {getIn(controller.formikInstance.errors, 'benefitTag') ? (
                  <FormErrorMessage>
                    {getIn(controller.formikInstance.errors, 'benefitTag')}
                  </FormErrorMessage>
                ) : (
                  <FormHelperText userSelect={'none'} color={'transparent'}>
                    Por favor, indique una promoción
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Stack>

          <Stack spacing={0.5}>
            <FormControl
              isInvalid={getIn(
                controller.formikInstance.errors,
                'unlimitedQuantity'
              )}
              isRequired>
              <Field name="unlimitedQuantity">
                {({ field }) => (
                  <Checkbox colorScheme={'brand'} {...field} mb={'1.2rem'}>
                    Ilimitado
                  </Checkbox>
                )}
              </Field>
              <FormErrorMessage>
                {getIn(controller.formikInstance.errors, 'unlimitedQuantity')}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={getIn(
                controller.formikInstance.errors,
                'availableQuantity'
              )}
              variant={'floating'}>
              <Field name="availableQuantity">
                {({ field, form }) => (
                  <Input
                    {...field}
                    onChange={event => {
                      const parsedValueNumber = Number(event.target.value);

                      form.setFieldValue(
                        'availableQuantity',
                        isNaN(parsedValueNumber) ? 1 : parsedValueNumber
                      );
                    }}
                    placeholder=""
                    disabled={form.values.unlimitedQuantity === true}
                  />
                )}
              </Field>
              <FormLabel>Cantidad en stock</FormLabel>
              {getIn(controller.formikInstance.errors, 'availableQuantity') ? (
                <FormErrorMessage>
                  {getIn(controller.formikInstance.errors, 'availableQuantity')}
                </FormErrorMessage>
              ) : (
                <FormHelperText userSelect={'none'} color={'transparent'}>
                  Por favor, indique una cantidad
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          <FormControl
            isInvalid={getIn(
              controller.formikInstance.errors,
              'image.cover.url'
            )}
            isRequired>
            <FormLabel fontSize={16} fontWeight={'400'}>
              Añadir foto cuadrada <b>800x800</b>
            </FormLabel>
            <Field name="image.cover">
              {({ field, form }) => (
                <FilePond
                  id="storeImages"
                  name="store.images"
                  key="images"
                  instantUpload
                  //@ts-ignore
                  allowImagePreview={false}
                  labelMaxFileSizeExceeded="Archivo demasiado grande"
                  labelMaxFileSize="Tamaño máximo: 50MB"
                  maxFileSize={'50MB'}
                  acceptedFileTypes={['image/*']}
                  onupdatefiles={images => {}}
                  server={{
                    process: async (fieldName, file, metadata, load, error) => {
                      try {
                        const image = await bikletaPointService.postImage(file);

                        controller.formikInstance.setFieldValue('image.cover', {
                          ...image,
                        });
                        load(image.url);
                      } catch (errorObj) {
                        console.log('ERROR: ', errorObj);

                        error('Ocurrió un error');
                      }
                      /* store file somewhere and call `load` when done */
                    },
                  }}
                  labelIdle='Arrastre la imagen o <span class="filepond--label-action">elijala desde archivo</span>'
                  labelFileLoading="Cargando"
                  labelFileProcessing="Procesando"
                  labelFileProcessingComplete="Listo!"
                  labelFileProcessingError="Error"
                  labelTapToCancel="Cancelar"
                  labelTapToRetry="Reintentar"
                  labelTapToUndo="Deshacer"
                  labelButtonRemoveItem="Eliminar"
                  labelFileLoadError={'Error al cargar el archivo'}
                  allowFileTypeValidation={true}
                />
              )}
            </Field>
            {getIn(controller.formikInstance.errors, 'image.cover.url') ? (
              <FormErrorMessage>
                {getIn(controller.formikInstance.errors, 'image.cover.url')}
              </FormErrorMessage>
            ) : (
              <FormHelperText userSelect={'none'} color={'transparent'}>
                Por favor, indique una cantidad
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            isInvalid={getIn(
              controller.formikInstance.errors,
              'image.banner.url'
            )}
            isRequired>
            <FormLabel fontSize={16} fontWeight={'400'}>
              Añadir banner <b>364x142</b>
            </FormLabel>
            <Field name="image.banner">
              {({ field, form }) => (
                <FilePond
                  allowImagePreview={false}
                  allowMultiple={false}
                  labelMaxFileSizeExceeded="Archivo demasiado grande"
                  labelMaxFileSize="Tamaño máximo: 10MB"
                  maxFileSize={'10MB'}
                  maxFiles={1}
                  acceptedFileTypes={['image/*']}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort,
                      transfer,
                      options
                    ) => {
                      try {
                        const image = await bikletaPointService.postImage(file);

                        controller.formikInstance.setFieldValue(
                          'image.banner',
                          {
                            ...image,
                            file,
                          }
                        );
                        load(image.url);
                      } catch {
                        error('Ocurrió un error');
                      }
                      /* store file somewhere and call `load` when done */
                    },
                  }}
                  name="banner"
                  labelIdle='Arrastre la imagen o <span class="filepond--label-action">selecciona desde archivos</span>'
                  labelFileLoading="Cargando"
                  labelFileProcessing="Procesando"
                  labelFileProcessingComplete="Listo!"
                  labelFileProcessingError="Error"
                  labelTapToCancel="Cancelar"
                  labelTapToRetry="Reintentar"
                  labelTapToUndo="Deshacer"
                  labelButtonRemoveItem="Eliminar"
                  labelFileLoadError={'Error al cargar el archivo'}
                  allowFileTypeValidation={true}
                />
              )}
            </Field>
            {getIn(controller.formikInstance.errors, 'image.banner.url') ? (
              <FormErrorMessage>
                {getIn(controller.formikInstance.errors, 'image.banner.url')}
              </FormErrorMessage>
            ) : (
              <FormHelperText userSelect={'none'} color={'transparent'}>
                Por favor, indique una cantidad
              </FormHelperText>
            )}
          </FormControl>
        </Stack>
        <Center
          w={['auto', '50%', '100%']}
          h={'inherit'}
          p={6}
          display={{
            lg: 'flex',
            sm: 'none',
          }}>
          <Stack direction={'column'} w={['60%', '60%', '70%']} h={'full'}>
            <Text
              borderWidth={1}
              px={3}
              py={1}
              borderRadius={8}
              maxWidth={'fit-content'}
              fontSize={14}
              fontWeight={'400'}
              lineHeight={5}>
              Previsualización
            </Text>
            <BenefitPreviewer
              benefitObject={controller.formikInstance?.values}
              formikContext={controller.formikInstance}
            />
          </Stack>
        </Center>
      </Stack>
      <Box w={'100%'} display={'flex'} justifyContent={'center'} mt={2}>
        <Button
          type="submit"
          onClick={controller.formikInstance.submitForm}
          bgColor={colors.accent.main}
          textColor={colors.purple[750]}
          isLoading={controller.formikInstance.isSubmitting}>
          Editar beneficio
        </Button>
      </Box>
    </FormikProvider>
  );
};
