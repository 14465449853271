import React, { useState, useEffect } from "react";
import { Box, HStack, Text, Image, Button } from "@chakra-ui/react"
import { BackgroundHearder, BikeIcon } from "../../assets"
import { DarkArrowIcon, YellowArrowIcon } from "../Icons/Icons"
import { motion } from 'framer-motion';


export const Hero = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [currentPhrase, setCurrentPhrase] = React.useState(0);

    const phrases = ["MENOR HUELLA DE CARBONO", "MENOR CONTAMINACIÓN", "MÁS SALUD"];
    const title = ['CADA', 'KILÓMETRO', 'CUENTA'];
    const variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    const transition = { duration: 1, ease: 'easeInOut' };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPhrase((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);
    const handleClick = () => {
        const { top } = document
            .getElementById('MediaCard')
            .getBoundingClientRect();
        window.scrollTo({ top: top + 110, behavior: 'smooth' });

    };

    return (
        <HStack
            style={{
                width: "100%",
                height: "148vh",
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
                zIndex: 2,
            }}
        >
            <Image style={{ position: "absolute", zIndex: 99, top: 0, left: 0, right: 0, bottom: 0, width: "100%", height: "100%", objectFit: "cover" }} src={BackgroundHearder} />
            <Box zIndex={103} >
                <Text textAlign={'center'} textColor={'brand.50'} fontSize={{ base: '15px', md: '24px' }} fontWeight={'600'}>
                    <motion.h1
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        transition={transition}
                        style={{ textDecorationLine: phrases[currentPhrase] === 'MÁS SALUD' ? '' : 'line-through' }}
                        onAnimationComplete={() => setCurrentPhrase((prevIndex) => (prevIndex + 1) % phrases.length)} // Update state after animation
                    >
                        {phrases[currentPhrase]}
                    </motion.h1>
                </Text>
                <HStack flexDirection={{ base: 'column', md: 'row', lg: 'row' }} style={{ flex: 1, alignItems: 'center' }} >
                    {title.map((item, index) => (
                        <Text key={index} textColor={item === 'KILÓMETRO' ? 'brand.50' : 'white'} fontSize={{ base: '48px', md: '63px' }} fontWeight={'800'} >{item}</Text>
                    ))}
                </HStack>
            </Box>
            <Box zIndex={104} style={{ marginTop: "100px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Image style={{ height: "50px", width: "80px" }} src={BikeIcon} />
                <Text paddingInline={{ base: '10px', md: '0px', lg: '0px' }} fontSize={{ base: '15px', md: '24px', lg: '24px' }} style={{ color: "white", textAlign: "center", marginTop: '20px', }}>
                    Pedalea hacia un mundo más sano <br />
                    y obtiene grandes beneficios por ayudar a nuestro planeta.
                </Text>
                <Button
                    _hover={{ background: '#FFC700', }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    style={{ marginTop: '25px', border: '1px solid #FFC700', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '56px', width: '88px', borderRadius: '100px' }}
                    bg={isClicked ? '#E3B40D' : 'transparent'}
                    onClick={() => handleClick()}>
                    {isHover ? <DarkArrowIcon /> : <YellowArrowIcon />}
                </Button>
            </Box>
        </HStack>
    )

}