import { Box, Text, Image, Stack } from "@chakra-ui/react"
import { KmExchange, BikeRegistration } from "../../assets"

export const Layout = () => {
    const useIsMobile = () => {
        const isMobileDevice = typeof navigator === "undefined" ? false : (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i));
        return isMobileDevice ? true : false;
    }
    return (
        <Box
            zIndex={109}
            overflow={"hidden"}
            paddingInline={{ base: '30px', lg: '100px' }}
            marginBottom={{ base: '50px', lg: '10px' }}
            marginTop={{ base: '50px', lg: '150px' }}
            style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative', zIndex: 1 }}>
            <Box flexDir={{ base: 'column', lg: 'row' }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                {useIsMobile() === false ?
                    <>
                        <FirstTextLayout />
                        <FirstImageLayout />
                    </>
                    :
                    <>
                        <FirstImageLayout />
                        <FirstTextLayout />
                    </>
                }
            </Box>
            <Box
                marginTop={{ base: '50px', md: '100px' }}
                flexDirection={{ base: 'column', md: 'row' }}
                style={{ display: 'flex', alignContent: 'revert' }}>
                {useIsMobile() === false ?
                    <>
                        <SecondTextLayout />
                        <SecondImageLayout />
                    </>
                    :
                    <>
                        <SecondImageLayout />
                        <SecondTextLayout />
                    </>
                }
            </Box>
        </Box>
    );
};

const FirstTextLayout = () => {
    return (
        <Stack paddingInline={{ base: 0, md: '100px', lg: '100px' }}>
            <Text
                textAlign={{ base: 'center', lg: 'justify' }}
                fontSize={{ base: '24px', lg: '40px' }}
                style={{
                    color: '#000000',
                    fontWeight: '600',
                }}>
                Canjea tus km <br />
                por promos increibles
            </Text>
            <Text textAlign={{ base: 'center', md: 'justify' }} fontSize={{ base: '16px', lg: '20px' }} style={{ color: '#000000' }}>
                Descuentos en bicicletas, promociones en accesorios y complementos.
            </Text>
            <Text textAlign={{ base: 'center', md: 'justify' }} fontSize={{ base: '16px', lg: '20px' }} style={{ color: '#000000' }}>
                Equipa tus viajes y tu aventura con las mejores ofertas disponibles en nuestros Bikleta Points
            </Text>
        </Stack>
    )
}

const FirstImageLayout = () => {
    return (
        <Stack marginBottom={{ base: '30px', md: '0px' }} alignSelf={'flex-start'} marginTop={'20px'}>
            <Image src={KmExchange} />
        </Stack>
    )
}


const SecondTextLayout = () => {
    return (
        <Stack paddingInline={{ base: 0, md: '100px', lg: '100px' }}>
            <Text
                textAlign={{ base: 'center', lg: 'justify' }}
                fontSize={{ base: '24px', lg: '40px' }}
                style={{
                    color: '#000000',
                    fontWeight: '600',
                }}>
                Y protege tus bikletas <br />
                mientras acumulas km
            </Text>
            <Text
                textAlign={{ base: 'center', md: 'justify' }}
                fontSize={{ base: '16px', lg: '20px' }}
                style={{ color: '#000000' }}>
                También puedes registrar tus bikletas, <br />
                reportarlas cuando estén perdidas o sean robadas y
                conectarte con la comunidad Bikletera para ubicarlas
            </Text>
        </Stack>
    )
}

const SecondImageLayout = () => {
    return (
        <Stack marginBottom={{ base: '30px', md: '0px' }} alignSelf={'flex-end'} marginTop={'20px'}>
            <Image src={BikeRegistration} />
        </Stack>
    )
}