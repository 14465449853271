import {
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Text,
  Stack,
  chakra,
  FormControl,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Textarea,
  FormHelperText,
  Icon,
  Avatar,
  VisuallyHidden,
  useToast,
  Tag,
  Wrap,
  WrapItem,
  IconButton,
  Tooltip,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { FaEnvelope, FaFacebookSquare, FaInstagram, FaPhoneAlt, FaTiktok, FaUser, FaWhatsapp } from 'react-icons/fa';
import { useStore } from '../../../utils/useStore';

import { Field, FormikProvider, useFormik } from 'formik';
import { daysArray, PointTypes } from '../../../utils/constants';
import { COUNTRIES, getCountryCode } from '../../../utils/countries';
import { ConfigViewController } from './interfaces';
import { useCommerceProfileViewController } from './commerce-profile.controller';
import * as Yup from 'yup';
import MapComponent from '../../../extra/MapComponent';
import { Marker } from '@react-google-maps/api';
import { BikeIcon, EditIcon, FlagLocationIcon, GoogleIconLight, LinkIcon, LocationIcon, QuestionIcon, RunnerIcon, SupportIcon, ToolIcon } from '../../../components/Icons/Icons';
import { useNavigate } from 'react-router-dom';
import { Carousel } from '../../../components/Carousel';
import { RiEarthFill, RiTodoFill, RiToolsLine } from 'react-icons/ri';
import { FiCoffee, FiTool } from 'react-icons/fi';
import Flag from 'react-world-flags';
import { Value } from '../../../providers/storageContext';
import { InfoIcon, InfoOutlineIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { colors } from '../../../utils/colors';
import { IoIosBeer } from 'react-icons/io';
import { MdRestaurant } from 'react-icons/md';

const EditFormSchema = Yup.object().shape({
  //photos: Yup.array().required('Debe subir al menos una foto'),
  name: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Ingrese el nombre del Negocio'),
  email: Yup.string()
    .email('Correo invalido!')
    .required('Debe colocar un correo'),
  phone: Yup.string(),
  type: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Seleccione el tipo de Negocio'),
  point: Yup.object().shape({
    lat: Yup.number().required(
      'Complete los datos de Dirección - Punto en el mapa'
    ),
    lng: Yup.number().required(
      'Complete los datos de Dirección - Punto en el mapa'
    ),
  }),
  address: Yup.string().required('Complete los datos de Dirección'),
});

const BikletaPointData = ({ formik, controller }) => {

  const countryOptions = COUNTRIES.map(({ name, iso, prefix }) => ({
    label: name,
    value: iso,
    prefix: prefix,
  }));

  const defaultCountry = countryOptions.find(
    country => country.prefix === formik.values.phoneRegion
  );

  const pointsCategories = Array.isArray(formik.values?.type)
  ? formik.values.type.map((tag, index) => {
      return PointTypes.find(item => item.value === tag);
    })
  : []; // Retorna un array vacío si no es un array

  let debounceTimer;

  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FormikProvider value={formik}>
      <Box>
        <Title onOpen={onOpen} />
        <BusinessName />
        <hr style={{ margin: '16px 0 20px', backgroundColor: colors.purple[725] }} />
        <BusinessCategories pointsCategories={pointsCategories} />
        <hr style={{ margin: '24px 0 20px', backgroundColor: "#4D4B62" }} />
        <ContactInformation formik={formik} />
        <hr style={{ margin: '24px 0 20px', backgroundColor: "#4D4B62" }} />
        <OpeningHours formik={formik} />
        <BusinessPictures formik={formik} />
        <IconButton
          position="fixed"
          bottom="25px"
          right="25px"
          aria-label="Support"
          icon={<QuestionIcon boxSize={5} />}
          variant="solid"
          backgroundColor={'#1F1F2E'}
          _hover={{ backgroundColor: '#1F1F2E', opacity: 0.7 }}
          style={{ cursor: 'pointer' }}
          size="lg"
          onClick={onOpen}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent style={{backgroundColor: '#2B2930', borderRadius: 28}}>
            <ModalHeader fontWeight="md" >Perfil comercial</ModalHeader>
            <ModalBody paddingTop={0} paddingBottom={0}>
              <Text fontSize={14}>
                Este es tu perfil comercial con los datos que brindaste de tu negocio al momento de registrarte.
              </Text>
              <Text fontSize={14} mt={2}>
                Los usuarios de Bikleta ven esta información en la app que les permite identificar tu negocio como parte de nuestros Points.
              </Text>
              <Text fontSize={14} mt={2}>
                Puedes editar la información cuando lo necesites. Por ejemplo, si cambias tus horarios de cierre o apertura, tus redes sociales o si quieres subir fotos nuevas de tu negocio.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="brand" onClick={onClose} rounded={'full'} fontWeight="md" fontSize={14}>
                Aceptar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>


    </FormikProvider>
  );
};
/*
const NotificationsPreference = () => {
  return (
    <Box mt={[10, 0]}>
      <SimpleGrid
        display={{
          base: 'initial',
          md: 'grid',
        }}
        columns={{
          md: 3,
        }}
        spacing={{
          md: 6,
        }}>
        <GridItem
          colSpan={{
            md: 1,
          }}>
          <Box px={[4, 0]}>
            <Text
              mt={1}
              fontSize="sm"
              color="gray.600"
              _dark={{
                color: 'gray.400',
              }}>
              Decide que notificaciones quieres recibir y de que forma.
            </Text>
          </Box>
        </GridItem>
        <GridItem
          mt={[5, null, 0]}
          colSpan={{
            md: 2,
          }}>
          <chakra.form
            method="POST"
            shadow="base"
            rounded={'lg'}
            overflow={{
              sm: 'hidden',
            }}>
            <Stack
              px={4}
              py={5}
              p={[null, 6]}
              bg="white"
              _dark={{
                bg: '#141517',
              }}
              spacing={6}>
              <chakra.fieldset>
                <Box
                  as="legend"
                  fontSize="md"
                  color="gray.900"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  Notificaciones por Email
                </Box>
                <Stack mt={4} spacing={4}>
                  <Flex alignItems="start">
                    <Flex alignItems="center" h={5}>
                      <Checkbox
                        colorScheme="brand"
                        id="comments"
                        rounded="md"
                      />
                    </Flex>
                    <Box ml={3} fontSize="sm">
                      <chakra.label
                        htmlFor="comments"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Resumen de Calificaciónes
                      </chakra.label>
                      <Text
                        color="gray.500"
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Reciba un estado semanal de su Bikleta Point, que opinan
                        sus clientes, y consejos para aumentar su exposición.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="start">
                    <Flex alignItems="center" h={5}>
                      <Checkbox
                        colorScheme="brand"
                        id="candidates"
                        rounded="md"
                      />
                    </Flex>
                    <Box ml={3} fontSize="sm">
                      <chakra.label
                        htmlFor="candidates"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Estado de Solicitudes
                      </chakra.label>
                      <Text
                        color="gray.500"
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Reciba notificaciones cuando haya cambios en las
                        solicitudes que haya emitído.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="start">
                    <Flex alignItems="center" h={5}>
                      <Checkbox colorScheme="brand" id="offers" rounded="md" />
                    </Flex>
                    <Box ml={3} fontSize="sm">
                      <chakra.label
                        htmlFor="offers"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Novedades
                      </chakra.label>
                      <Text
                        color="gray.500"
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Enterese de las novedades que trae Bikleta para su
                        negocio.
                      </Text>
                    </Box>
                  </Flex>
                </Stack>
              </chakra.fieldset>
              <chakra.fieldset>
                <Box
                  as="legend"
                  fontSize="md"
                  color="gray.900"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  Push Notifications
                  <Text
                    fontSize="sm"
                    color="gray.500"
                    _dark={{
                      color: 'gray.400',
                    }}>
                    These are delivered via SMS to your mobile phone.
                  </Text>
                </Box>
                <RadioGroup
                  fontSize="sm"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}
                  colorScheme="brand"
                  mt={4}
                  defaultValue="1">
                  <Stack spacing={4}>
                    <Radio spacing={3} value="1">
                      Everything
                    </Radio>
                    <Radio spacing={3} value="2">
                      Same as email
                    </Radio>
                    <Radio spacing={3} value="3">
                      No push notifications
                    </Radio>
                  </Stack>
                </RadioGroup>
              </chakra.fieldset>
            </Stack>
            <Box
              px={{
                base: 4,
                sm: 6,
              }}
              py={3}
              bg="gray.50"
              _dark={{
                bg: '#121212',
              }}
              textAlign="right">
              <Button
                type="submit"
                colorScheme="brand"
                _focus={{
                  shadow: '',
                }}
                fontWeight="md">
                Guardar
              </Button>
            </Box>
          </chakra.form>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
*/

const Title = ({onOpen}) => {
  const navigate = useNavigate();
  return (
    <SimpleGrid
      display={{ base: 'initial', md: 'grid' }}
      columns={{ md: 2 }}
      spacing={{ md: 6 }}>
      <GridItem colSpan={{ md: 1 }}>
        <Tooltip 
          label='Esta información se publica en la app de Bikleta para que los usuarios identifiquen tu negocio como parte de nuestros Bikleta Points' 
          borderRadius={4}
          backgroundColor="#322F35"
          color="white"
          paddingX={4}
          paddingY={2}
          placement='right-end'>
          <Heading fontSize="lg" fontWeight="medium" lineHeight="6" maxW={40}>
            Perfil comercial
            <InfoOutlineIcon ml={2} mb={1} boxSize={4} color={'white'} />
          </Heading>
        </Tooltip>
      </GridItem>
      <GridItem
        mt={[7, null, 0]}
        colSpan={{ md: 1 }}
        textAlign="right">
        <Button
          type="button"
          colorScheme="brand"
          rounded={'full'}
          px={14}
          leftIcon={<Icon as={EditIcon} boxSize={6} mt={1} mr={-1} />}
          _focus={{ shadow: '' }}
          fontWeight="md"
          onClick={() => {
            if (localStorage.getItem('editProfile') === 'true') {
              navigate('/admin/profile-edit');
              return;
            }
            onOpen();
            localStorage.setItem('editProfile', 'true');
          }}
        >
          Editar perfil
        </Button>
      </GridItem>
    </SimpleGrid>
  )
};

const BusinessName = () => {
  return (
    <FormControl as={GridItem} colSpan={[6, 3]}>
      <FormLabel
        htmlFor="name"
        fontSize="sm"
        fontWeight="normal"
        color="gray.700"
        _dark={{
          color: 'gray.50',
        }}>
        Nombre de tu negocio
      </FormLabel>
      <Field
        as={Input}
        type="text"
        name="name"
        id="name"
        autoComplete="name"
        focusBorderColor="transparent"
        readOnly
        border="none"
        padding="0"
        size="md"
        w="full"
        h={'20px'}
      />
    </FormControl>
  )
};

const BusinessCategories = ({pointsCategories}) => {
  return (
    <FormControl style={{ margin: 0 }} as={GridItem} colSpan={[6, 3]}>
      <FormLabel
        htmlFor="name"
        fontSize="sm"
        fontWeight="bold"
        color="gray.700"
        mb={'16px'}
        _dark={{
          color: 'gray.50',
        }}>
        Categorías del negocio
      </FormLabel>
      <Wrap pr={2} spacing={4}>
        {pointsCategories && pointsCategories.map((tag, index) => (
          <WrapItem key={index}>
            <Tag size="md" rounded="lg" variant="outline" paddingY={2} paddingX={4}>
              {tag.value === 'BikeWorkshop' && <ToolIcon fill={'white'} boxSize={4} mr={2}  mb={1}/>}
              {tag.value === 'clothesShop' && <RunnerIcon fill={'white'} boxSize={5} mr={2} />}
              {tag.value === 'tourismPoint' && <FlagLocationIcon fill={'white'} boxSize={5} mr={2} />}
              {tag.value === 'BikeStore' && <BikeIcon fill={'white'} boxSize={5} mr={2} />}
              {tag.value === 'coffieBar' && <FiCoffee fill={'white'} style={{marginRight: 6, width: 20, height: 15, marginBlock: 2.5}}/>}
              {tag.value === 'bar' && <IoIosBeer fill={'white'} style={{marginRight: 6, width: 20, height: 15, marginBlock: 2.5}}/>}
              {tag.value === 'restaurant' && <MdRestaurant fill={'white'} style={{marginRight: 6, width: 20, height: 15, marginBlock: 2.5}}/>}
              {tag.label}
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    </FormControl>
  )
};

const ContactInformation = ({ formik }) => {
  const countryName = getCountryCode(formik.values?.phoneRegion);
  const fullNumber = `${formik.values?.phoneRegion} ${formik.values?.phone}`;
  return (
    <>
      <FormLabel
        htmlFor="name"
        fontSize="sm"
        fontWeight="bold"
        color="gray.700"
        mb={'16px'}
        _dark={{
          color: 'gray.50',
        }}>
        Información de contacto
      </FormLabel>

      <SimpleGrid
        display={{ base: 'initial', md: 'grid' }}
        columns={{ md: 5 }}
        spacing={{ md: 6 }}>
        <GridItem colSpan={{ md: 3 }}>
          {/* Sitio web y Google Business */}
          <SimpleGrid
            display={{ base: 'initial', md: 'grid' }}
            columns={{ md: 2 }}
            spacing={{ md: 6 }}
          >
            <GridItem colSpan={{ md: 1 }}>
              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="email_address"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <LinkIcon boxSize={5} mr={'5px'} ml={-1} />
                  Sitio web
                </FormLabel>
                {formik.values.web && formik.values.web !== '' ? (
                <Field
                  as={Input}
                  type="text"
                  name="web"
                  id="web"
                  autoComplete="web"
                  focusBorderColor="transparent"
                  readOnly
                  border="none"
                  padding="0"
                  size="md"
                  w="full"
                  h={'20px'}
                />
                ) : (
                  <Text>-</Text>
                )}
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ md: 1 }}>
              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="type"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <GoogleIconLight boxSize={5} mr={'1px'} mb={'-4px'} />
                  Google Business
                </FormLabel>
                {formik.values.googleBusiness
                  ? (
                    <Field
                      as={Input}
                      type="text"
                      name="googleBusiness"
                      id="googleBusiness"
                      autoComplete="googleBusiness"
                      focusBorderColor="transparent"
                      readOnly
                      border="none"
                      padding="0"
                      size="md"
                      w="full"
                      h={'20px'}
                    />)
                  : (
                    <Text>-</Text>
                  )
                }
              </FormControl>
            </GridItem>
          </SimpleGrid>
          {/* Email y Teléfono comercial */}
          <SimpleGrid
            display={{ base: 'initial', md: 'grid' }}
            columns={{ md: 2 }}
            spacing={{ md: 6 }}
            mt={8}>
            <GridItem colSpan={{ md: 1 }}>
              <FormControl style={{ margin: 0 }} as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="email_address"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <Icon as={FaEnvelope} boxSize={3.5} mr={2} mb={'-2px'} />
                  Email comercial
                </FormLabel>
                <Field
                  as={Input}
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  focusBorderColor="transparent"
                  readOnly
                  border="none"
                  padding="0"
                  size="md"
                  w="full"
                  h={'20px'}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ md: 1 }}>
              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="phone"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <Icon as={FaPhoneAlt} boxSize={3.5} mr={2} mb={'-2px'} />
                  Teléfono comercial
                </FormLabel>
                <Stack flexDir={'row'}>
                  <Flex mr={2} width="2rem" alignItems="center">
                    {countryName ? (
                      <Box flex={1}>
                        <Flag height="1rem" code={countryName || ''} />
                      </Box>
                    ) : (
                      <Icon as={RiEarthFill} />
                    )}
                  </Flex>
                  <Field
                    style={{ margin: 0 }}
                    as={Input}
                    type="fullNumber"
                    name="fullNumber"
                    id="fullNumber"
                    value={fullNumber}
                    autoComplete="fullNumber"
                    focusBorderColor="transparent"
                    readOnly
                    border="none"
                    padding="0"
                    size="md"
                    w="full"
                    h={'20px'}
                  />
                </Stack>
              </FormControl>
            </GridItem>
          </SimpleGrid>
          {/* WhatsApp e Instagram  */}
          <SimpleGrid
            display={{ base: 'initial', md: 'grid' }}
            columns={{ md: 2 }}
            spacing={{ md: 6 }}
            mt={8}>
            <GridItem colSpan={{ md: 1 }}>
              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="phone"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <Icon as={FaWhatsapp} boxSize={4} mr={2} mb={'-2.5px'} />
                  WhatsApp
                </FormLabel>
                <Stack flexDir={'row'}>
                  <Flex mr={2} width="2rem" alignItems="center">
                    {countryName ? (
                      <Box flex={1}>
                        <Flag height="1rem" code={countryName || ''} />
                      </Box>
                    ) : (
                      <Icon as={RiEarthFill} />
                    )}
                  </Flex>
                  <Field
                    style={{ margin: 0 }}
                    as={Input}
                    type="fullNumber"
                    name="fullNumber"
                    id="fullNumber"
                    value={fullNumber}
                    autoComplete="fullNumber"
                    focusBorderColor="transparent"
                    readOnly
                    border="none"
                    padding="0"
                    size="md"
                    w="full"
                    h={'20px'}
                  />
                </Stack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ md: 1 }}>
              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="instagram"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <Icon as={FaInstagram} boxSize={4} mr={2} mb={'-2.5px'} />
                  Instagram
                </FormLabel>
                {formik.values.instagram
                  ? (
                    <Field
                      as={Input}
                      type="text"
                      name="instagram"
                      id="instagram"
                      autoComplete="instagram"
                      focusBorderColor="transparent"
                      readOnly
                      border="none"
                      padding="0"
                      size="md"
                      w="full"
                      h={'20px'}
                    />)
                  : (
                    <Text>-</Text>
                  )
                }
              </FormControl>
            </GridItem>
          </SimpleGrid>
          {/* Facebook y TikTok */}
          <SimpleGrid
            display={{ base: 'initial', md: 'grid' }}
            columns={{ md: 2 }}
            spacing={{ md: 6 }}
            mt={8}>
            <GridItem colSpan={{ md: 1 }}>
              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="facebook"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <Icon as={FaFacebookSquare} boxSize={3.5} mr={2} mb={'-2px'} />
                  Facebook
                </FormLabel>
                {formik.values.facebook
                  ? (
                    <Field
                      as={Input}
                      type="text"
                      name="facebook"
                      id="facebook"
                      autoComplete="facebook"
                      focusBorderColor="transparent"
                      readOnly
                      border="none"
                      padding="0"
                      size="md"
                      w="full"
                      h={'20px'}
                    />)
                  : (
                    <Text>-</Text>
                  )
                }
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ md: 1 }}>
              <FormControl as={GridItem} colSpan={[6, 4]}>
                <FormLabel
                  htmlFor="tiktok"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  <Icon as={FaTiktok} boxSize={3.5} mr={2} mb={'-2px'} />
                  Tik Tok
                </FormLabel>
                {formik.values.tiktok
                  ? (
                    <Field
                      as={Input}
                      type="text"
                      name="tiktok"
                      id="tiktok"
                      autoComplete="tiktok"
                      focusBorderColor="transparent"
                      readOnly
                      border="none"
                      padding="0"
                      size="md"
                      w="full"
                      h={'20px'}
                    />)
                  : (
                    <Text>-</Text>
                  )
                }
              </FormControl>
            </GridItem>

          </SimpleGrid>
        </GridItem>

        <GridItem
          mt={[5, null, 0]}
          colSpan={{ md: 2 }}
          textAlign="right">
            {/* Ubicación y Mapa */}
          <FormControl as={GridItem} colSpan={[6, 4]}>
            <FormLabel
              htmlFor="address"
              fontSize="sm"
              fontWeight="md"
              color="gray.700"
              _dark={{
                color: 'gray.50',
              }}>
              <LocationIcon boxSize={4} mr={'5px'} mt={-1} />
              Ubicación
            </FormLabel>
            <Field
              as={Input}
              type="text"
              name="address"
              id="address"
              autoComplete="address"
              focusBorderColor="transparent"
              readOnly
              border="none"
              padding="0"
              size="md"
              w="full"
              h={'20px'}
            />
            <Box
              borderRadius={20}
              overflow="hidden"
              borderWidth={1.5}
              borderColor="gray.500"
              mt={2}>
              <MapComponent
                height={'25vh'}
                center={
                  formik.values.point.lat && formik.values.point.lng
                    ? {
                      lat: formik.values.point.lat,
                      lng: formik.values.point.lng,
                    }
                    : null
                }
                zoom={
                  formik.values.point.lat && formik.values.point.lng
                    ? 15
                    : null
                }
                options={{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  styles: [
                    {
                      featureType: 'poi',
                      stylers: [{ visibility: 'off' }],
                    },
                    {
                      featureType: 'poi.business',
                      stylers: [{ visibility: 'off' }],
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels',
                      stylers: [{ visibility: 'off' }],
                    },
                    {
                      featureType: 'poi.business',
                      elementType: 'labels',
                      stylers: [{ visibility: 'off' }],
                    },
                  ],
                }}>
                {formik.values.point.lat && formik.values.point.lng && (
                  <Marker
                    key={'New-BP'}
                    position={{
                      lat: formik.values.point.lat,
                      lng: formik.values.point.lng,
                    }}
                  />
                )}
              </MapComponent>
            </Box>
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </>
  )
};

const OpeningHours = ({ formik }) => {
  const getDayLabel = (dayNumber: number) => {
    return daysArray[dayNumber - 1];
  };
  return (
    <>
      <FormLabel
        htmlFor="name"
        fontSize="sm"
        fontWeight="md"
        color="gray.700"
        mb={'16px'}
        _dark={{
          color: 'gray.50',
        }}>
        Horarios comerciales
      </FormLabel>
      <SimpleGrid
        display={{ base: 'initial', md: 'grid' }}
        columns={{ md: 7 }}
        spacing={{ md: 6 }}
        mt={4}>
        {Object.keys(formik.values?.workTime).map((key, index) => (
          <GridItem key={index} colSpan={{ md: 1 }}>
            <FormControl as={GridItem} colSpan={[6, 4]}>
              <FormLabel
                htmlFor="email_address"
                fontSize="sm"
                fontWeight="md"
                color="gray.700"
                _dark={{
                  color: 'gray.50',
                }}>
                {getDayLabel(formik.values.workTime[key].dayNumber)}
              </FormLabel>
              {!formik.values.workTime[key].opens
                ? <Text>Cerrado</Text>
                : (
                  <>
                    <SimpleGrid
                      display={{ base: 'initial', md: 'grid' }}
                      columns={{ md: 8 }}
                      spacing={{ md: 0 }}>
                      <GridItem colSpan={{ md: 3 }}>
                        <Field
                          as={Input}
                          type="text"
                          name={`workTime.${key}.openTime[0]`}
                          id={`workTime.${key}.openTime[0]`}
                          autoComplete={`workTime.${key}.openTime[0]`}
                          focusBorderColor="transparent"
                          readOnly
                          border="none"
                          padding="0"
                          size="md"
                          w="full"
                          h={'20px'}
                        />
                      </GridItem>
                      <GridItem colSpan={{ md: 1 }}>
                        <Text>a</Text>
                      </GridItem>
                      <GridItem colSpan={{ md: 3 }}>
                        <Field
                          as={Input}
                          type="text"
                          name={`workTime.${key}.closeTime[0]`}
                          id={`workTime.${key}.closeTime[0]`}
                          autoComplete={`workTime.${key}.closeTime[0]`}
                          focusBorderColor="transparent"
                          readOnly
                          border="none"
                          padding="0"
                          size="md"
                          w="full"
                          h={'20px'}
                        />
                      </GridItem>
                    </SimpleGrid>
                    {formik.values.workTime[key].openTime[1] && (
                      <SimpleGrid
                        display={{ base: 'initial', md: 'grid' }}
                        columns={{ md: 8 }}
                        spacing={{ md: 0 }}>
                        <GridItem colSpan={{ md: 3 }}>
                          <Field
                            as={Input}
                            type="text"
                            name={`workTime.${key}.openTime[1]`}
                            id={`workTime.${key}.openTime[1]`}
                            autoComplete={`workTime.${key}.openTime[1]`}
                            focusBorderColor="transparent"
                            readOnly
                            border="none"
                            padding="0"
                            size="md"
                            w="full"
                            h={'20px'}
                          />
                        </GridItem>
                        <GridItem colSpan={{ md: 1 }}>
                          <Text>a</Text>
                        </GridItem>
                        <GridItem colSpan={{ md: 3 }}>
                          <Field
                            as={Input}
                            type="text"
                            name={`workTime.${key}.closeTime[1]`}
                            id={`workTime.${key}.closeTime[1]`}
                            autoComplete={`workTime.${key}.closeTime[1]`}
                            focusBorderColor="transparent"
                            readOnly
                            border="none"
                            padding="0"
                            size="md"
                            w="full"
                            h={'20px'}
                          />
                        </GridItem>
                      </SimpleGrid>
                    )}
                  </>
                )
              }
            </FormControl>
          </GridItem>
        ))}
      </SimpleGrid>
    </>
  )
};

const BusinessPictures = ({ formik }) => {
  return (
    <>
      <Tooltip 
        label="Estas imágenes las visualizan los usuarios de Bikleta, al consultar los detalles de este Bikleta Point."
        borderRadius={4}
        backgroundColor="#322F35"
        color="white"
        paddingX={4}
        paddingY={2}
        placement='right-start'>
        <FormLabel
          mt={'24px'}
          mb={'16px'}
          htmlFor="name"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          maxW={36}
          _dark={{
            color: 'gray.50',
          }}>
          Fotos de tu negocio
          <InfoOutlineIcon ml={2} alignSelf="center" boxSize={3} color={'white'} />
        </FormLabel>
      </Tooltip>
      <Carousel items={formik.values.images} />
    </>
  )
};

const CommerceProfileView: React.FC<ConfigViewController> = ({
  useController = useCommerceProfileViewController,
}) => {
  const { activePoint } = useStore();

  const controller = useController();

  const toast = useToast();

  const handleSubmit = async values => {
    try {
      await controller.submitEditedData(activePoint._id, values);

      toast({
        title: `Comercio Editado!`,
        description: `${values.name} se editó exitosamente.`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Ocurrió un error`,
        description: `${values.name} no se pudo editar.`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: activePoint && {
      name: activePoint.name,
      type: activePoint.type,
      email: activePoint.email,
      phone: activePoint?.phone || '',
      phoneRegion: activePoint?.phoneRegion || '',
      address: activePoint.address,
      point: activePoint.point,
      web: activePoint.web,
      workTime: activePoint.workTime,
      images: activePoint.images,
      instagram: activePoint.instagram,
      facebook: activePoint.facebook,
      tiktok: activePoint.tiktok,
      location: activePoint.location,
      googleBusiness: activePoint.googleBusiness,
    },
    validationSchema: EditFormSchema,
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  return (
    activePoint && (
      <Stack
        rounded="lg"
        mt={{ base: '120px', md: '5px' }}
        px={8}
        position={'relative'}
        top={'-10px'}
        bgColor="transparent">
        <Box hidden>
          <SimpleGrid
            display={{
              base: 'initial',
              md: 'grid',
            }}
            columns={{
              md: 3,
            }}
            spacing={{
              md: 6,
            }}>
            <GridItem
              colSpan={{
                md: 1,
              }}>
              <Box px={[4, 0]}>
                <Heading fontSize="lg" fontWeight="md" lineHeight="6">
                  Datos de Contacto
                </Heading>
                <Text
                  mt={1}
                  fontSize="sm"
                  color="gray.600"
                  _dark={{
                    color: 'gray.400',
                  }}>
                  Información de contacto de la persona responsable del bikleta
                  point.
                </Text>
              </Box>
            </GridItem>
            <GridItem
              mt={[5, null, 0]}
              colSpan={{
                md: 2,
              }}>
              <chakra.form
                method="POST"
                shadow="base"
                rounded={'lg'}
                overflow={{
                  sm: 'hidden',
                }}>
                <Stack
                  px={4}
                  py={5}
                  bg="white"
                  _dark={{
                    bg: '#141517',
                  }}
                  spacing={6}
                  p={{
                    sm: 6,
                  }}>
                  <SimpleGrid columns={3} spacing={6}>
                    <FormControl as={GridItem} colSpan={[3, 2]}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Website
                      </FormLabel>
                      <InputGroup size="sm">
                        <InputLeftAddon
                          bg="gray.50"
                          _dark={{
                            bg: 'gray.800',
                          }}
                          color="gray.500"
                          rounded="md">
                          http://
                        </InputLeftAddon>
                        <Input
                          type="tel"
                          placeholder="www.example.com"
                          focusBorderColor="brand.400"
                          rounded="md"
                        />
                      </InputGroup>
                    </FormControl>
                  </SimpleGrid>
                  <div>
                    <FormControl id="email" mt={1}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        About
                      </FormLabel>
                      <Textarea
                        placeholder="you@example.com"
                        mt={1}
                        rows={3}
                        shadow="sm"
                        focusBorderColor="brand.400"
                        fontSize={{
                          sm: 'sm',
                        }}
                      />
                      <FormHelperText>
                        Brief description for your profile. URLs are
                        hyperlinked.
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <FormControl>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: 'gray.50',
                      }}>
                      Photo
                    </FormLabel>
                    <Flex alignItems="center" mt={1}>
                      <Avatar
                        boxSize={12}
                        bg="gray.100"
                        _dark={{
                          bg: 'gray.800',
                        }}
                        icon={
                          <Icon
                            as={FaUser}
                            boxSize={9}
                            mt={3}
                            rounded="full"
                            color="gray.300"
                            _dark={{
                              color: 'gray.700',
                            }}
                          />
                        }
                      />
                      <Button
                        type="button"
                        ml={5}
                        variant="outline"
                        size="sm"
                        fontWeight="medium"
                        _focus={{
                          shadow: 'none',
                        }}>
                        Change
                      </Button>
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: 'gray.50',
                      }}>
                      Cover photo
                    </FormLabel>
                    <Flex
                      mt={1}
                      justify="center"
                      px={6}
                      pt={5}
                      pb={6}
                      borderWidth={2}
                      _dark={{
                        color: 'gray.500',
                      }}
                      borderStyle="dashed"
                      rounded="md">
                      <Stack spacing={1} textAlign="center">
                        <Icon
                          mx="auto"
                          boxSize={12}
                          color="gray.400"
                          _dark={{
                            color: 'gray.500',
                          }}
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true">
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </Icon>
                        <Flex
                          fontSize="sm"
                          color="gray.600"
                          _dark={{
                            color: 'gray.400',
                          }}
                          alignItems="baseline">
                          <chakra.label
                            htmlFor="file-upload"
                            cursor="pointer"
                            rounded="md"
                            fontSize="md"
                            color="brand.600"
                            _dark={{
                              color: 'brand.200',
                            }}
                            pos="relative"
                            _hover={{
                              color: 'brand.400',
                              _dark: {
                                color: 'brand.300',
                              },
                            }}>
                            <span>Upload a file</span>
                            <VisuallyHidden>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                              />
                            </VisuallyHidden>
                          </chakra.label>
                          <Text pl={1}>or drag and drop</Text>
                        </Flex>
                        <Text
                          fontSize="xs"
                          color="gray.500"
                          _dark={{
                            color: 'gray.50',
                          }}>
                          PNG, JPG, GIF up to 10MB
                        </Text>
                      </Stack>
                    </Flex>
                  </FormControl>
                </Stack>
                <Box
                  px={{
                    base: 4,
                    sm: 6,
                  }}
                  py={3}
                  bg="gray.50"
                  _dark={{
                    bg: '#121212',
                  }}
                  textAlign="right">
                  <Button
                    type="submit"
                    colorScheme="brand"
                    _focus={{
                      shadow: '',
                    }}
                    fontWeight="md">
                    Guardar
                  </Button>
                </Box>
              </chakra.form>
            </GridItem>
          </SimpleGrid>
        </Box>
        <BikletaPointData formik={formik} controller={controller} />
      </Stack>
    )
  );
};

export default CommerceProfileView;
