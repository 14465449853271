import axios from 'axios';
import { appUrl } from '../conf.ts';

export const getSharedRouteData = async id => {
  const res = await axios.get(appUrl + '/web/routes/' + id);
  return res.data;
};

export const getSharedPoint = async id =>
  axios.get(appUrl + '/web/routes/' + id + '?point=true');

export const getSharedAddress = (lat, lng) =>
  axios.get(appUrl + '/web/routes/query?' + lat + ',' + lng);
