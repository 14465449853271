import { Box, chakra, useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import AppBar from '../components/common/Landing/AppBar';

function LandingLayout() {
  const { setColorMode } = useColorMode();
  setColorMode('light');

  useEffect(() => {
    return () => {
      setColorMode('light');
    };
  }, []);

  return (
    <Box position="relative">
      <Box>
        <Outlet />
      </Box>
      {/* <chakra.footer>
        <AppBar />
      </chakra.footer> */}
    </Box>
  );
}

export default LandingLayout;
