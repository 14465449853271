/* global google */
import React, { useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import {} from '@react-google-maps/marker-clusterer';
import { Box, Spinner } from '@chakra-ui/react';
import MapDirectionsRenderer from './DirectionsRederer';
import { isDev, mapsApi } from '../../conf';
import CustomPointMarker from '../../components/CustomMarker';
import { ClusterMarkers } from '../../assets';
import { centerDef } from '../../utils/constants';

const clusterStyles = [
  {
    height: 50,
    textColor: '#ffffff',
    width: 50,
    fill: '#D69E2E',
    url: './stolenCluster.svg',
  },
  {
    height: 50,
    textColor: '#ffffff',
    width: 50,
    fill: '#D69E2E',
    url: ClusterMarkers.Stolenbikes,
  },
];

function MapComponent({ height, center, ...props }) {
  const [isMarkerShown, setisMarkerShown] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [centerPoint, setCenterPoint] = useState(null);
  const [centerCluster, setCenterCluster] = useState(null);
  const mapRef = useRef(null);
  const clusterRef = useRef(null);

  useEffect(() => {
    if (!centerPoint) {
      setCenterPoint(center);
    }
  }, [center]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: isDev ? '' : mapsApi, // ,
    // ...otherOptions
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  const onMapClick = e => {
    setMarkerPosition(e.latLng);
    setisMarkerShown(true);
    props.setAddress({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  return isLoaded ? (
    <Box h={height || { sm: '60vh', md: '25vh' }} style={props.style}>
      <GoogleMap
        clickableIcons={false}
        ref={mapRef}
        mapContainerStyle={{
          width: props.width || '100%',
          height: props.height || '100%',
        }}
        center={centerCluster || center || centerDef}
        zoom={props.zoom || 10}
        onClick={props.setAddress && onMapClick}
        onLoad={props.onLoad}
        onDragEnd={() =>
          false &&
          console.log('Drag end. ActualCenter: ', {
            lat: mapRef.current.state.map.center.lat(),
            lng: mapRef.current.state.map.center.lng(),
          })
        }
        options={props.options}>
        {isMarkerShown && <Marker position={markerPosition} />}
        {!isDev && props.places && (
          <MapDirectionsRenderer
            places={props.places}
            travelMode={google.maps.TravelMode.BICYCLING}
          />
        )}
        {props.points && (
          <MarkerClusterer
            ref={clusterRef}
            averageCenter
            enableRetinaIcons
            gridSize={20}
            zoomOnClick={true}>
            {clusterer =>
              props.points.map((item, index) => (
                <CustomPointMarker
                  clusterer={clusterer}
                  key={'marker-' + index}
                  item={item}
                  type={props.pointType}
                  setFocused={props.pointFocuser}
                />
              ))
            }
          </MarkerClusterer>
        )}
        {props.extra}
        {props.children}
      </GoogleMap>
    </Box>
  ) : (
    <Spinner />
  );
}

export default React.memo(MapComponent);
