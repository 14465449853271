import React from 'react';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  Link,
  Heading,
  Input,
  Text,
  useColorModeValue,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Stack,
  Divider,
  HStack,
  Center,
} from '@chakra-ui/react';
//@ts-ignore
import { Field, FieldProps, Formik, getIn } from 'formik';
import { useRegisterController } from './signup.controller';
import * as Yup from 'yup';
import { RegisterViewProps } from './interfaces';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link as ReachLink } from 'react-router-dom';
import { OAuthButtonGroup } from '../../../components/OAuthButtonGroup';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import { COUNTRIES } from '../../../utils/countries';
import { Select } from 'chakra-react-select';
import { FcGoogle } from 'react-icons/fc';
import { Option } from 'chakra-multiselect';

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required('Ingrese un nombre y apellido'),
  email: Yup.string()
    .email('Correo invalido')
    .required('Debe ingresar un correo'),
  password: Yup.string()
    .required('Debe ingresar una contraseña')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      'Debe contener 8 caracteres. Minimo una mayuscula, una minuscula y un caracter especial'
    )
    .test('len', 'Debe contener 8 caracteres minimo.', val => val?.length >= 8),
  idNumber: Yup.number(),
  country: Yup.object(),
  phone: Yup.string(),
  // .matches(/(?=.*?[a-z])/, 'El campo es incorrecto'),
});

const RegisterView: React.FC<RegisterViewProps> = props => {
  const { useController = useRegisterController } = props;
  const controller = useController();

  const titleColor = useColorModeValue('yellow.400', 'yellow.500');
  const textColor = useColorModeValue('gray.400', 'white');

  const [show, setShow] = React.useState(false);

  const handleClick = () => setShow(!show);

  const countryOptions = COUNTRIES.map(({ name, iso, prefix }) => ({
    label: name,
    value: iso,
    prefix: prefix,
  }));

  return (
    <Formik
      validationSchema={SignUpSchema}
      validateOnBlur={false}
      validateOnChange={false}
      isInitialValid={false}
      initialValues={{
        name: '',
        country: '',
        idNumber: '',
        email: '',
        phoneRegion: '',
        phone: '',
        password: '',
      }}
      onSubmit={controller.onSubmit}>
      {props => (
        <Center w="100%" h="full" overflowY={'auto'} mx="auto">
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: 'none' }}>
            <Stack
              direction="column"
              w="100%"
              h="100vh"
              justifyContent={'center'}
              p="30px"
              spacing={3}>
              <Heading color={titleColor} fontSize="32px" fontFamily={'Nunito'}>
                Crear una Nueva Cuenta
              </Heading>
              <Text
                mb="30px"
                ms="4px"
                color={textColor}
                fontWeight="bold"
                fontSize="13px">
                Complete el formulario y cree su cuenta Bikleta
              </Text>
              <FormControl
                isInvalid={getIn(props.errors, 'name')}
                isRequired
                mb={5}>
                <Input
                  maxLength={60}
                  borderRadius="4px"
                  fontSize="sm"
                  value={props.values.name}
                  onChange={e => {
                    props.setFieldValue('name', e.target.value);

                    setTimeout(() => {
                      props.validateField('name');
                    }, 20);
                  }}
                  onBlur={props.handleBlur}
                  type="text"
                  placeholder="Nombre y Apellido"
                  size="md"
                  name="name"
                />
                {getIn(props.errors, 'name') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(props.errors, 'name')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={getIn(props.errors, 'idNumber')}
                isRequired
                mb={5}>
                <Input
                  maxLength={15}
                  borderRadius="4px"
                  fontSize="sm"
                  value={props.values.idNumber}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  placeholder="DNI / ID / RUT"
                  size="md"
                  name="idNumber"
                />
                {getIn(props.errors, 'idNumber') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(props.errors, 'idNumber')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={getIn(props.errors, 'email')}
                isRequired
                mb={5}>
                <Input
                  maxLength={300}
                  borderRadius="4px"
                  fontSize="sm"
                  value={props.values.email}
                  onChange={e => {
                    props.setFieldValue('email', e.target.value);

                    setTimeout(() => {
                      props.validateField('email');
                    }, 20);
                  }}
                  onBlur={props.handleBlur}
                  type="email"
                  placeholder="Email"
                  size="md"
                  name="email"
                />
                {getIn(props.errors, 'email') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(props.errors, 'email')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <Field name="country">
                {({ field, form: { setFieldValue } }: FieldProps) => (
                  <FormControl
                    mb={5}
                    style={{ zIndex: 1000 }}
                    isInvalid={getIn(props.errors, 'country')}
                    isRequired>
                    <Select
                      {...field}
                      id="country"
                      options={countryOptions}
                      placeholder="Elije un país"
                      closeMenuOnSelect={true}
                      size="md"
                      onChange={(option: Option) => {
                        setFieldValue(field.name, option);
                      }}
                    />
                    {getIn(props.errors, 'country') ? (
                      <FormErrorMessage fontSize={'x-small'}>
                        {getIn(props.errors, 'country')}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                )}
              </Field>

              <FormControl mb={5}>
                <PhoneNumberInput
                  inputProps={{
                    borderRadius: 4,
                    fontSize: 'sm',
                    placeholder: 'Número de Telefono',
                    name: 'phoneNumber',
                    autoComplete: 'new-phone',
                  }}
                  options={countryOptions}
                  onChange={(option: Option) => {
                    props.setFieldValue(
                      'phone',
                      controller.getInfoFromPhoneNumber(option).phoneNumber
                    );
                    props.setFieldValue(
                      'phoneRegion',
                      controller.getInfoFromPhoneNumber(option).region
                    );

                    setTimeout(() => {
                      props.validateField('phone');
                    }, 10);
                  }}
                  country={''}
                />
              </FormControl>
              <FormControl
                isInvalid={getIn(props.errors, 'password')}
                isRequired
                mb={5}>
                <InputGroup size="md">
                  <Input
                    borderRadius="4px"
                    fontSize="sm"
                    type={show ? 'text' : 'password'}
                    name="password"
                    onChange={e => {
                      props.setFieldValue('password', e.target.value);

                      setTimeout(() => {
                        props.validateField('password');
                      }, 20);
                    }}
                    autoComplete={'new-password'}
                    placeholder="Contraseña"
                    size="md"
                  />
                  <InputRightElement
                    _hover={{
                      cursor: 'pointer',
                    }}
                    children={show ? <FiEye /> : <FiEyeOff />}
                    onClick={handleClick}
                  />
                </InputGroup>
                {getIn(props.errors, 'password') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(props.errors, 'password')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <Stack spacing={3}>
                <Button
                  isLoading={controller.isLoading}
                  type="submit"
                  variant={'outline'}
                  w="50%"
                  mx="auto"
                  borderRadius={12}
                  h="45"
                  borderColor={'brand.50'}
                  color="white"
                  onClick={() => {
                    props.validateForm().then(res => {
                      Object.keys(res).length === 0 && props.submitForm();
                    });
                  }}>
                  Registrarse
                </Button>
                <Box hidden>
                  <OAuthButtonGroup />
                </Box>
              </Stack>
              <HStack my="4">
                <Divider />
              </HStack>
              <Stack my="4" spacing="4">
                <Button
                  variant="outline"
                  onClick={() => controller.signIn()}
                  leftIcon={<Box as={FcGoogle} color="red.500" />}>
                  Registrarse con Google
                </Button>
              </Stack>
              <Text alignSelf="center">
                Ya tienes cuenta?{' '}
                <Link
                  as={ReachLink}
                  fontWeight="bold"
                  to="/auth/signin"
                  _hover={{
                    textDecoration: 'underline',
                  }}>
                  Ingresar
                </Link>
              </Text>
            </Stack>
          </Flex>
        </Center>
      )}
    </Formik>
  );
};

export default RegisterView;
