import React, { ComponentElement } from 'react';
// Chakra imports
import {
  Flex,
  IconButton,
  TableContainer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  Select,
  Tooltip,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  chakra,
  Checkbox,
  Center,
} from '@chakra-ui/react';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from 'react-table';
import { TableActions } from './TableActions';
import { HTMLChakraProps } from '@chakra-ui/system';
interface CustomTableTypes extends HTMLChakraProps<'div'> {
  columns: any;
  data: any[];
  initialHiddenColumns?: string[];
  openAdd?: () => void | null;
  openEdit?: Function | null;
  deleteAction?: {
    warningLabel: string;
    method: Function;
    isDeleting: boolean;
  };
}

const CustomTable: React.FC<CustomTableTypes> = ({
  columns,
  data,
  openAdd = null,
  openEdit = null,
  initialHiddenColumns = [],
  deleteAction = null,
}) => {
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        hiddenColumns: initialHiddenColumns,
      },
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Checkbox
              {...getToggleAllPageRowsSelectedProps()}
              colorScheme={'yellow'}
              isChecked={getToggleAllPageRowsSelectedProps().checked}
            />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <Checkbox
              isChecked={row.getToggleRowSelectedProps().checked}
              onChange={row.getToggleRowSelectedProps().onChange}
              colorScheme={'yellow'}
              {...row.getToggleRowSelectedProps()}
            />
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    setGlobalFilter,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
    selectedFlatRows,
  } = table;

  const { globalFilter } = state;

  // Render the UI for your table
  return (
    <Stack spacing={5} maxH="90vh" h="90vh">
      <TableActions
        filter={globalFilter}
        setFilter={setGlobalFilter}
        openAdd={openAdd}
        openEdit={openEdit}
        deleteAction={deleteAction}
        tableInstance={table}
      />
      <TableContainer overflowY="auto">
        <Table {...getTableProps()}>
          <Thead
            textAlign="center"
            position="sticky"
            top={0}
            bgColor={'#27273A'}
            zIndex={20}>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          textAlign={
                            typeof cell.value === 'number' ? 'center' : 'left'
                          }>
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            ) : (
              <Center w="100%">
                <Text fontSize={'1rem'} my={5} textAlign="center" w="100%">
                  No hay información para mostrar.
                </Text>
              </Center>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="Primera Página">
            <IconButton
              aria-label="button-first"
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Página anterior">
            <IconButton
              aria-label="button-pag"
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text mr={8}>
            Página{' '}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{' '}
            de{' '}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
          <Text>Ir a página:</Text>{' '}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageOptions.length}
            onChange={value => {
              const num = parseInt(value);
              const page = num ? num - 1 : 0;
              gotoPage(page);
            }}
            defaultValue={pageIndex + 1}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}>
            {[5, 10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Ver {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Página siguiente">
            <IconButton
              aria-label="button-next"
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Última Página">
            <IconButton
              aria-label="button-last"
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default CustomTable;
