// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Skeleton,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import IconBox from '../../../components/Icons/IconBox';
// Custom icons
import React from 'react';
// react icons
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import { MdRefresh, MdStoreMallDirectory, MdWarning } from 'react-icons/md';
import { RiShoppingBagFill } from 'react-icons/ri';
import { DashboardViewProps } from './interfaces';
import { useDashboardViewController } from './dasboard.controller';
import ReactApexChart from 'react-apexcharts';
import { lineChartOptions } from '../../../utils/constants';

const Dashboard: React.FC<DashboardViewProps> = ({
  useController = useDashboardViewController,
}) => {
  const controller = useController();
  const iconTeal = 'yellow.500';
  const iconBoxInside = 'black';
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Flex flexDirection="column">
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing="24px">
        <Card minH="83px" bgColor={'backgroundColor.100'} variant={'panel'}>
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="white"
                  fontWeight="bold"
                  pb=".1rem">
                  Poten. Clientes
                </StatLabel>
                <Flex>
                  <Skeleton
                    minW={'60%'}
                    isLoaded={
                      !controller.statsLoading && !controller.isRefreshing
                    }>
                    <StatNumber fontSize="lg" color={textColor}>
                      {controller.statsData?.potentialCustomers}
                    </StatNumber>
                  </Skeleton>
                </Flex>
              </Stat>
              <Tooltip
                hasArrow
                placement="bottom-end"
                color={'white'}
                bgColor={'gray.600'}
                label="Número de personas que usan Bikleta y pasaron muy cerca de tu negocio">
                <span>
                  <IconBox h={'40px'} w={'40px'} bg={iconTeal}>
                    <RiShoppingBagFill size={'1.8rem'} color={iconBoxInside} />
                  </IconBox>
                </span>
              </Tooltip>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px" bgColor={'backgroundColor.100'}>
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="white"
                  fontWeight="bold"
                  pb=".1rem">
                  Clientes Nuevos
                </StatLabel>
                <Flex>
                  <Skeleton
                    minW={'60%'}
                    isLoaded={
                      !controller.statsLoading && !controller.isRefreshing
                    }>
                    <StatNumber fontSize="lg" color={textColor}>
                      {controller.statsData?.customers}
                    </StatNumber>
                  </Skeleton>
                </Flex>
              </Stat>
              <Tooltip
                hasArrow
                placement="bottom-end"
                color={'white'}
                bgColor={'gray.600'}
                label="Número de personas que ingresaron al local y usaron beneficios a travez de Bikleta">
                <span>
                  <IconBox h={'40px'} w={'40px'} bg={'green.500'}>
                    <MdStoreMallDirectory
                      size={'1.8rem'}
                      color={iconBoxInside}
                    />
                  </IconBox>
                </span>
              </Tooltip>
            </Flex>
          </CardBody>
        </Card>
        <Card minH="83px" bgColor="backgroundColor.100">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="white"
                  fontWeight="bold"
                  pb=".1rem">
                  Bikletas Robadas
                </StatLabel>
                <Flex>
                  <Skeleton
                    minW={'60%'}
                    isLoaded={
                      !controller.statsLoading && !controller.isRefreshing
                    }>
                    <StatNumber
                      fontSize="lg"
                      color={textColor}
                      fontWeight="bold">
                      {controller.statsData?.thefts}
                    </StatNumber>
                  </Skeleton>
                </Flex>
              </Stat>
              <Tooltip
                hasArrow
                placement="bottom-end"
                color={'white'}
                bgColor={'gray.600'}
                label="Número de Bikletas robadas cerca de tu negocio">
                <span>
                  <IconBox h={'40px'} w={'40px'} bg={'red.600'}>
                    <MdWarning size={'1.8rem'} color={iconBoxInside} />
                  </IconBox>
                </span>
              </Tooltip>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>

      <Card bgColor={'backgroundColor.100'} mt={5}>
        <CardHeader>
          <Flex
            direction="row"
            w={'100%'}
            alignSelf="flex-start"
            justifyContent={'space-between'}>
            <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
              Resumen de visitas
            </Text>
            <Button
              leftIcon={<MdRefresh />}
              onClick={() => controller?.statsData && controller.statsRefresh()}
              isLoading={controller.isRefreshing}>
              Actualizar
            </Button>
          </Flex>
        </CardHeader>
        <Box w="100%" h={{ sm: '300px' }} ps="8px">
          {controller.statsData?.lineChart &&
            controller.statsData?.lineChart?.data.length > 0 && (
              <ReactApexChart
                options={{
                  ...lineChartOptions,
                  xaxis: {
                    ...lineChartOptions.xaxis,
                    categories: controller.statsData?.lineChart?.categories,
                  },
                }}
                series={controller.statsData?.lineChart?.data}
                width="100%"
                height="100%"
              />
            )}
        </Box>
      </Card>
    </Flex>
  );
};

export default Dashboard;
